import React from 'react'
import NewProjectModal from './new-project-modal'
import { selectModal } from '../../redux/application-slice'
import { useSelector } from 'react-redux'
import DeleteProjectModal from './delete-project-modal'
import SharingModal from './sharing/sharing-modal'
import RenameDocumentModal from './rename-document-modal'
import AIChatFeedbackModal from './ai-chat-feedback-modal'
import RenameChatModal from './rename-chat-modal'
import AccountLocked from './account-locked-modal'
import RenameProjectModal from './rename-project-modal'
import CommandBarPopup from '../commandbar/command-bar-popup'
import UserFirstNameModal from './user-first-name-modal'
import { useGetUserProfileQuery } from '../../redux/api-slice'
import ExportOptionsModal from './export-options-modal'
import CreateFolderModal from './create-folder-modal'
import RenameFolderModal from './rename-folder-modal'
import DeleteRiskListModal from './delete-risk-list-modal'
import EditRiskListModal from './edit-risk-list-modal'
import EditCustomRiskModal from './edit-custom-risk-modal'
import DeleteCustomRiskModal from './delete-custom-risk-modal'
import ProjectSettingsModal from './project-settings-modal'
import WatchLoomModal from './watch-loom-modal'
import ExportRiskReviewModal from './export-risk-review-modal'
import EditProjectRiskListTemplatesModal from './edit-project-risk-list-templates-modal'

export const MODAL_TYPES = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  COMMAND_BAR: 'COMMAND_BAR',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  RENAME_DOCUMENT: 'RENAME_DOCUMENT',
  DELETE_CHECKLIST_QUESTION: 'DELETE_CHECKLIST_QUESTION',
  DELETE_CHECKLIST_VIEW: 'DELETE_CHECKLIST_VIEW',
  AI_CHAT_FEEDBACK: 'AI_CHAT_FEEDBACK',
  RENAME_CHAT: 'RENAME_CHAT',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  RENAME_PROJECT: 'RENAME_PROJECT',
  USER_SETTINGS_OPEN: 'USER_SETTINGS_OPEN',
  EXPORT_OPTIONS: 'EXPORT_OPTIONS',
  CREATE_FOLDER: 'CREATE_FOLDER',
  RENAME_FOLDER: 'RENAME_FOLDER',
  DELETE_RISK_LIST: 'DELETE_RISK_LIST',
  EDIT_RISK_LIST: 'EDIT_RISK_LIST',
  EDIT_CUSTOM_RISK: 'EDIT_CUSTOM_RISK',
  DELETE_CUSTOM_RISK: 'DELETE_CUSTOM_RISK',
  PROJECT_SETTINGS: 'PROJECT_SETTINGS',
  SC_TUTORIAL: 'SC_TUTORIAL',
  CHAT_TUTORIAL: 'CHAT_TUTORIAL',
  EXPORT_RISK_REVIEW: 'EXPORT_RISK_REVIEW',
  EDIT_PROJECT_RISK_LIST_TEMPLATES: 'EDIT_PROJECT_RISK_LIST_TEMPLATES',
}

function ModalController() {
  const modalOpen = useSelector(selectModal)
  const { data: profile } = useGetUserProfileQuery(undefined)

  return (
    <>
      <NewProjectModal open={modalOpen?.modal === MODAL_TYPES.CREATE_PROJECT} />
      <DeleteProjectModal
        open={modalOpen?.modal === MODAL_TYPES.DELETE_PROJECT}
      />
      <CommandBarPopup open={modalOpen?.modal === MODAL_TYPES.COMMAND_BAR} />
      <SharingModal open={modalOpen?.modal === MODAL_TYPES.SHARE_PROJECT} />
      <RenameDocumentModal
        open={modalOpen?.modal === MODAL_TYPES.RENAME_DOCUMENT}
      />
      <RenameChatModal open={modalOpen?.modal === MODAL_TYPES.RENAME_CHAT} />
      <AIChatFeedbackModal
        open={modalOpen?.modal === MODAL_TYPES.AI_CHAT_FEEDBACK}
      />
      {modalOpen?.modal === MODAL_TYPES.ACCOUNT_LOCKED ? (
        <AccountLocked open={modalOpen?.modal === MODAL_TYPES.ACCOUNT_LOCKED} />
      ) : null}
      <RenameProjectModal
        open={modalOpen?.modal === MODAL_TYPES.RENAME_PROJECT}
      />
      <UserFirstNameModal
        open={Boolean(
          (!profile?.first_name || !profile?.last_name) &&
            profile?.agreed_tandcs
        )}
      />
      <ExportOptionsModal
        open={modalOpen?.modal === MODAL_TYPES.EXPORT_OPTIONS}
      />
      {modalOpen?.modal === MODAL_TYPES.CREATE_FOLDER ? (
        <CreateFolderModal
          open={modalOpen?.modal === MODAL_TYPES.CREATE_FOLDER}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.RENAME_FOLDER ? (
        <RenameFolderModal
          open={modalOpen?.modal === MODAL_TYPES.RENAME_FOLDER}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.DELETE_RISK_LIST ? (
        <DeleteRiskListModal
          open={modalOpen?.modal === MODAL_TYPES.DELETE_RISK_LIST}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.EDIT_RISK_LIST ? (
        <EditRiskListModal
          open={modalOpen?.modal === MODAL_TYPES.EDIT_RISK_LIST}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.EDIT_CUSTOM_RISK ? (
        <EditCustomRiskModal
          open={modalOpen?.modal === MODAL_TYPES.EDIT_CUSTOM_RISK}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.DELETE_CUSTOM_RISK ? (
        <DeleteCustomRiskModal
          open={modalOpen?.modal === MODAL_TYPES.DELETE_CUSTOM_RISK}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.PROJECT_SETTINGS ? (
        <ProjectSettingsModal
          open={modalOpen?.modal === MODAL_TYPES.PROJECT_SETTINGS}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.SC_TUTORIAL ? (
        <WatchLoomModal
          open={modalOpen?.modal === MODAL_TYPES.SC_TUTORIAL}
          modalTitle="How to Use Supplementary Conditions"
          loomLink="https://www.loom.com/embed/edade637c8b24f4989da1a19aba43c29?sid=644c2565-8aa5-4d79-965a-3317acd4bfb9"
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.CHAT_TUTORIAL ? (
        <WatchLoomModal
          open={modalOpen?.modal === MODAL_TYPES.CHAT_TUTORIAL}
          modalTitle="How to Ask Questions with Provision"
          loomLink="https://www.loom.com/embed/4a8ff1f3663949248abe74a66d5d5dc6?sid=1cf1eb8a-fddb-4c94-8510-3eecfdfc96d0"
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.EXPORT_RISK_REVIEW ? (
        <ExportRiskReviewModal
          open={modalOpen?.modal === MODAL_TYPES.EXPORT_RISK_REVIEW}
        />
      ) : null}
      {modalOpen?.modal === MODAL_TYPES.EDIT_PROJECT_RISK_LIST_TEMPLATES ? (
        <EditProjectRiskListTemplatesModal
          open={
            modalOpen?.modal === MODAL_TYPES.EDIT_PROJECT_RISK_LIST_TEMPLATES
          }
        />
      ) : null}
    </>
  )
}

export default ModalController
