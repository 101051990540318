import React, { useState } from 'react'
import {
  Square2StackIcon,
  FunnelIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  ClipboardDocumentCheckIcon,
  EyeIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'
import {
  Menu,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core'
import {
  useGetDocumentStatusByProjectQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { useNavigateWorkflow } from '../../hooks/use-navigate-workflow'
import { workflows } from '../workflows/workflow-popover/workflow.data'
import { ReactElement } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../redux/application-slice'

type AppMenuPopoverProps = {
  width: number
  menuTarget: ReactElement
}

export const AppMenuPopover = ({ width, menuTarget }: AppMenuPopoverProps) => {
  const [opened, setOpened] = useState(false)

  const { data: user } = useGetUserProfileQuery(undefined)

  // TODO add a test to check if users with FF off can see QA tile
  const optionsData = [
    {
      title: 'Documents',
      icon: DocumentIcon,
      color: 'violet',
      link: 'documents',
      index: '1',
      workflowObject: workflows[0],
    },
    {
      title: 'Risk Review',
      icon: ClipboardDocumentCheckIcon,
      color: 'indigo',
      link: 'riskreview',
      index: '2',
      workflowObject: workflows[1],
    },
    {
      title: 'Chat',
      icon: ChatBubbleLeftEllipsisIcon,
      color: 'blue',
      link: 'chat',
      index: '3',
      workflowObject: workflows[2],
    },
    {
      title: 'Comments',
      icon: ChatBubbleLeftRightIconSolid,
      color: 'green',
      link: 'comments',
      index: '4',
      workflowObject: workflows[3],
    },
    {
      title: 'Clause Filtering',
      icon: FunnelIcon,
      color: 'teal',
      link: 'clause-filters',
      index: '5',
      workflowObject: workflows[4],
    },
    {
      title: 'Supplementary Conditions',
      icon: Square2StackIcon,
      color: 'cyan',
      link: 'supplementary-conditions',
      index: '6',
      workflowObject: workflows[5],
    },
    ...(user?.feature_flags?.editor_mode
      ? [
        {
          title: 'Quality Assurance',
          icon: EyeIcon,
          color: 'red',
          link: 'quality-assurance',
          index: '*',
          workflowObject: workflows[6],
        },
      ]
      : []),
  ]

  const ActionsGrid = () => {
    const currentProject = useSelector(selectCurrentProject)
    const {
      currentData: documentResponse,
      // isLoading: documentsAndFoldersLoading, TODO USE THIS VALUE
    } = useGetDocumentStatusByProjectQuery(
      currentProject?.id ? currentProject?.id : skipToken,
      {
        pollingInterval: 10000,
      }
    )

    const documentCount = documentResponse?.length || 0

    const theme = useMantineTheme()
    const { navigateWorkflow } = useNavigateWorkflow()
    const items = optionsData.map((item) => (
      <Tooltip
        label={`${item.title} ${item.title === 'Supplementary Conditions' || item.title === 'Comments' ? 'are' : 'is'} disabled until Documents are loaded`}
        disabled={
          documentCount > 0 ||
          item.title === 'Documents' ||
          item.title === 'Quality Assurance'
        }
        key={item.title}
      >
        <UnstyledButton
          onClick={() => {
            if (
              documentCount > 0 ||
              item.title === 'Documents' ||
              item.title === 'Quality Assurance'
            ) {
              navigateWorkflow(item.workflowObject)
              setOpened(false)
            }
          }}
          style={{
            padding: '10px',
            background:
              documentCount === 0 &&
                item.title !== 'Documents' &&
                item.title !== 'Quality Assurance'
                ? '#dadada'
                : '',
          }}
          disabled={
            documentCount === 0 &&
            item.title !== 'Documents' &&
            item.title !== 'Quality Assurance'
          }
          className="flex hover:bg-gray-50 rounded items-center justify-start w-full gap-2"
        >
          <item.icon
            color={
              documentCount === 0 &&
                item.title !== 'Documents' &&
                item.title !== 'Quality Assurance'
                ? '#adadad'
                : theme.colors[item.color][6]
            }
            className="h-7 w-7"
          />
          <Text
            size="1rem"
            c={
              documentCount === 0 &&
                item.title !== 'Documents' &&
                item.title !== 'Quality Assurance'
                ? 'dimmed'
                : ''
            }
          >
            {item.title}
          </Text>
        </UnstyledButton>
      </Tooltip>
    ))

    return (
      <div className="flex flex-col gap-2 w-full p-2">
        {items}
      </div>
    )
  }

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      aria-label={`All Apps`}
      shadow="md"
      radius={'md'}
      withArrow
      arrowSize={16}
    >
      <Menu.Target>{menuTarget}</Menu.Target>
      <Menu.Dropdown>
        <ActionsGrid />
      </Menu.Dropdown>
    </Menu>
  )
}
