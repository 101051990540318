import React, { useMemo, useCallback } from 'react'
import { FolderIcon, DocumentIcon } from '@heroicons/react/24/outline'
import { ProcessedFolder } from './document-listbox-folders'
import {
  ProjectDocument,
  ProjectDocumentMetadata,
} from '../../shared/interfaces/project/document/document.interface'

interface FolderContentsProps {
  folder: ProcessedFolder
  level: number
  selectedDocuments: ProjectDocumentMetadata[]
  getAllDocumentsInFolder: (folder: ProcessedFolder) => ProjectDocument[]
  handleDocumentClick: (doc: ProjectDocument) => void
  handleFolderClick: (folder: ProcessedFolder) => void
  getIndentClass: (level: number) => string
}

const FolderContents = ({
  folder,
  level,
  selectedDocuments,
  getAllDocumentsInFolder,
  handleDocumentClick,
  handleFolderClick,
  getIndentClass,
}: FolderContentsProps) => {
  const allDocsInFolder = useMemo(
    () => getAllDocumentsInFolder(folder),
    [folder, getAllDocumentsInFolder]
  )

  const isFolderSelected = useMemo(
    () =>
      allDocsInFolder.length > 0 &&
      allDocsInFolder.every((doc) =>
        selectedDocuments.find((d) => d.id === doc.id)
      ),
    [allDocsInFolder, selectedDocuments]
  )

  const isDocumentSelected = useCallback(
    (docId: number) => selectedDocuments.find((d) => d.id === docId),
    [selectedDocuments]
  )

  return (
    <div key={folder.id} className="relative">
      <div
        onClick={() => handleFolderClick(folder)}
        className={`flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100 ${getIndentClass(level)}`}
      >
        <div
          className={`absolute inset-0 ${isFolderSelected ? 'bg-indigo-600' : ''}`}
        />
        <div className="relative z-10 flex items-center gap-2">
          <FolderIcon
            className={`h-4 w-4 ${isFolderSelected ? 'text-white' : 'text-gray-400'}`}
          />
          <span className={isFolderSelected ? 'text-white' : ''}>
            {folder.name}
          </span>
        </div>
      </div>

      {folder.documents.map((doc) => (
        <div key={doc.id} className="relative">
          <div
            onClick={() => handleDocumentClick(doc)}
            className={`flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100 ${getIndentClass(level + 1)}`}
          >
            <div
              className={`absolute inset-0 ${isDocumentSelected(doc.id) ? 'bg-indigo-600' : ''}`}
            />
            <div className="relative z-10 flex items-center gap-2">
              <DocumentIcon
                className={`h-4 w-4 flex-shrink-0 ${isDocumentSelected(doc.id) ? 'text-white' : 'text-gray-400'}`}
              />
              <span className={isDocumentSelected(doc.id) ? 'text-white' : ''}>
                {doc.title}
              </span>
            </div>
          </div>
        </div>
      ))}

      {Object.values(folder.subFolders).map((subfolder) => (
        <FolderContents
          key={subfolder.id}
          folder={subfolder}
          level={level + 1}
          selectedDocuments={selectedDocuments}
          getAllDocumentsInFolder={getAllDocumentsInFolder}
          handleDocumentClick={handleDocumentClick}
          handleFolderClick={handleFolderClick}
          getIndentClass={getIndentClass}
        />
      ))}
    </div>
  )
}

export default FolderContents
