import React from 'react'
import ReactDOM from 'react-dom/client'

import '@mantine/core/styles.css'
import './index.css'

import App from './App'

import LogRocket from 'logrocket'

import { Auth0Provider } from '@auth0/auth0-react'
import AuthWrapper from './components/auth/auth-wrapper'

import { Provider } from 'react-redux'
import { store } from './redux/store'

import * as Sentry from '@sentry/react'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import ChatwootWidget from './components/chatwoot/chatwoot'
import ProductFruitsComponent from './components/product-fruits/product-fruits'
import { WebsocketProvider } from './contexts/websocket-context'

import { createTheme, MantineProvider } from '@mantine/core'

const theme = createTheme({
  /** One day we will override theme styles. Until then this is dormant. */
  /** See: https://mantine.dev/theming/colors/ for details/examples */
})

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: 'https://rick.useprovision.com',
})

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_ENVIRONMENT,
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/useprovision\.com\/api/],
  profilesSampleRate: 0.1,
})

if (import.meta.env.VITE_LOGROCKET_ID) {
  LogRocket.init(import.meta.env.VITE_LOGROCKET_ID, {
    network: {
      requestSanitizer: (request) => {
        request.headers['authorization'] = undefined
        return request
      },
    },
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider
    domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
    clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
    }}
  >
    <Provider store={store}>
      <AuthWrapper>
        <PostHogProvider client={posthog}>
          <WebsocketProvider>
            <MantineProvider theme={theme}>
              <App />
              <ProductFruitsComponent />
              <ChatwootWidget />
            </MantineProvider>
          </WebsocketProvider>
        </PostHogProvider>
      </AuthWrapper>
    </Provider>
  </Auth0Provider>
)

// Health-check function migrated from your inline script
;(function () {
  let failCount = 0
  const maxFailures = 2
  const checkInterval = 10000

  async function checkHealth() {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_PROVISION_API_BASE_URL}healthcheck/`
      )
      if (!response.ok) {
        throw new Error('Healthcheck failed')
      }
      failCount = 0
    } catch (e) {
      failCount++
      if (failCount >= maxFailures) {
        const dialog = document.createElement('div')
        dialog.style.cssText = `
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 32px;
          border-radius: 12px;
          box-shadow: 0 4px 24px rgba(0,0,0,0.15);
          z-index: 1000;
          max-width: 450px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
        `
        dialog.innerHTML = `
          <h3 style="margin: 0 0 16px; color: #DC2626; font-size: 20px; font-weight: 600;">Connection Lost</h3>
          <p style="margin: 0 0 24px; color: #374151; line-height: 1.5;">
            We're having trouble connecting to our servers. This could be due to your internet connection or temporary server maintenance.
            Please try again later or contact <a href="mailto:support@useprovision.com" style="color: #2563EB; text-decoration: none;">support@useprovision.com</a>
            if the issue persists. You can also visit our <a href="https://help.useprovision.com" style="color: #2563EB; text-decoration: none;">help center</a> where you can live chat with us.
          </p>
          <div style="display: flex; gap: 12px;">
            <button id="reloadBtn" style="
              background: #2563EB;
              color: white;
              border: none;
              padding: 10px 20px;
              border-radius: 6px;
              cursor: pointer;
              font-weight: 500;
              transition: background 0.2s;
            ">Reload Page</button>
            <button id="dismissBtn" style="
              background: #E5E7EB;
              color: #374151;
              border: none;
              padding: 10px 20px;
              border-radius: 6px;
              cursor: pointer;
              font-weight: 500;
              transition: background 0.2s;
            ">Dismiss</button>
          </div>
        `
        document.body.appendChild(dialog)

        const reloadBtn = dialog.querySelector('#reloadBtn')
        if (reloadBtn) {
          reloadBtn.addEventListener('click', () => window.location.reload())
        }
        const dismissBtn = dialog.querySelector('#dismissBtn')
        if (dismissBtn) {
          dismissBtn.addEventListener('click', () => dialog.remove())
        }
        return // stop checking after showing the dialog
      }
    }
    setTimeout(checkHealth, checkInterval)
  }
  checkHealth()
})()
