import React, { useCallback, useMemo } from 'react'
import { Button, Loader, Text } from '@mantine/core'
import { v4 as uuidv4 } from 'uuid'
import { useCreateRevisionMutation } from '../../../../redux/api/api-revisions-slice'
import {
  setSelectedRevision,
  setSelectedRevisionEdit,
  setSelectedSources,
  setRevisionSource,
} from '../../../../redux/viewer-slice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../../redux/application-slice'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { SuggestedRevisionResponse } from '../../../../shared/interfaces/project/document/revision/revision.interface'

interface SuggestedRevision {
  id: string
  page: number
  quads: any[]
  original_text: string
  suggested_text: string
  document_uuid: string
  document_id: number
  document_title?: string
}

interface SuggestedRevisionsDropdownProps {
  projectRiskId: string | undefined
  onClose: () => void
  suggestedRevisionsResponse: SuggestedRevisionResponse | undefined
}

const SuggestedRevisionsDropdown: React.FC<SuggestedRevisionsDropdownProps> = ({
  onClose,
  suggestedRevisionsResponse,
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const posthog = usePostHog()

  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const documentId = useMemo(() => currentDocument?.id, [currentDocument])
  const projectUuid = useMemo(() => currentProject?.uuid, [currentProject])

  const [createRevision] = useCreateRevisionMutation()

  const updatePageParam = useCallback(
    (page: number) => {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('page', page.toString())
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams]
  )

  const onSelectRevision = useCallback(
    async (revision: SuggestedRevision) => {
      if (!documentId) return

      // Navigate to the document
      navigate(`/${projectUuid}/riskreview/${revision.document_uuid}`)
      updatePageParam(revision.page)

      // Capture posthog event
      posthog.capture(POSTHOG.suggested_revision_selected, {
        revision_id: revision.id,
        document_uuid: revision.document_uuid,
        project_uuid: projectUuid,
      })

      // Use selectedsources to highlight the revision
      dispatch(
        setSelectedSources([
          {
            id: uuidv4(),
            page: revision.page,
            quads: revision.quads || [],
            isPrimary: true,
            documentUUID: revision.document_uuid,
          },
        ])
      )

      try {
        const response = await createRevision({
          page: revision.page,
          quads: revision.quads,
          original_text: revision.original_text,
          revised_text: revision.suggested_text,
          document: revision.document_id,
          suggested_revision_id: revision.id,
          segments: [
            {
              page: revision.page,
              quads: revision.quads,
              document: documentId,
              text: revision.original_text,
            },
          ],
        }).unwrap()

        dispatch(setRevisionSource('suggested'))
        dispatch(setSelectedRevision(response))
        dispatch(setSelectedRevisionEdit(true))

        onClose()
      } catch (error) {
        toast.error('Something went wrong. Please try again.')
      }
    },
    [
      documentId,
      navigate,
      projectUuid,
      updatePageParam,
      dispatch,
      createRevision,
      onClose,
      posthog,
    ]
  )
  const hasNoRevisions = useMemo(
    () =>
      suggestedRevisionsResponse?.suggested_revisions_count &&
      !suggestedRevisionsResponse?.results?.length,
    [suggestedRevisionsResponse]
  )

  if (!suggestedRevisionsResponse) {
    return <Loader size="sm" />
  }

  if (hasNoRevisions) {
    return (
      <Text size="sm" c="dimmed">
        All suggested revisions have been applied
      </Text>
    )
  }

  return (
    <div className="flex max-h-[200px] flex-col gap-2 overflow-y-auto">
      {suggestedRevisionsResponse?.results.map((revision, index) => {
        const truncatedText = revision.suggested_text.slice(0, 20) + '...'
        const documentName = revision.document_title || 'Document'
        const truncatedDocName =
          documentName.length > 15
            ? `${documentName.slice(0, 15)}...`
            : documentName

        return (
          <Button
            key={revision.id}
            variant="light"
            size="sm"
            onClick={() => onSelectRevision(revision)}
            className="justify-start text-left"
            fullWidth
            styles={{
              inner: {
                justifyContent: 'flex-start',
              },
              label: {
                justifyContent: 'flex-start',
                width: '100%',
                textAlign: 'left',
              },
              root: {
                color: 'inherit',
              },
            }}
          >
            <div className="flex w-full items-center justify-between">
              <div className="min-w-0 flex-shrink overflow-hidden text-ellipsis text-gray-900">
                {index + 1}. {truncatedText}
              </div>
              <div className="ml-2 flex flex-shrink-0 items-center gap-1 whitespace-nowrap text-xs text-gray-500">
                <span>
                  {truncatedDocName}, pg {revision.page}
                </span>
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 text-indigo-600"
                  strokeWidth={2}
                />
              </div>
            </div>
          </Button>
        )
      })}
    </div>
  )
}

export default SuggestedRevisionsDropdown
