import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useCreateDocumentChangesExportMutation,
  useGetDocumentChangesQuery,
  useGetDocumentsListByProjectQuery,
} from '../../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  selectCurrentDocument,
  selectCurrentProject,
  selectSupplementaryConditionsAreProcessing,
  setSupplementaryConditionsAreProcessing,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { Project } from '../../../shared/interfaces/project/project.interface'
import {
  DocumentChange,
  DocumentChangeTaskStatus,
} from '../../../shared/interfaces/project/document/changes/document-change.interface'
import {
  selectHighlightedDocumentChange,
  setTemporaryHighlight,
} from '../../../redux/viewer-slice'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import DocumentListbox from '../../document-listbox/document-listbox'
import { useHotkeys } from 'react-hotkeys-hook'
import DocumentChangeCard from './document-change-card'
import ExportPopover from '../workflow-components/export-popover'
import CommentFilterPopover from '../comment-table/comment-filter-popover'
import { ProvisionUser } from '../../../shared/interfaces/user/user.inteface'
import FilterDisplay, {
  WorkflowFilter,
} from '../workflow-components/filter-display'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { VariableSizeList } from 'react-window'
import useWindowDimensions from '../../../hooks/use-window-dimensions'
import debounce from 'lodash/debounce'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import {
  DocumentChangeBanner,
  SupplementaryConditionsBanner,
} from '../../banners/specific-banners'
import clsx from 'clsx'
import { Loader } from '@mantine/core'

interface ChangesContextProps {
  setSize?: (index: number, size: number) => void
  windowDimensions?: { width: number; height: number }
  selectedDocument?: ProjectDocumentMetadata | null
}

export const ChangesContext = createContext<ChangesContextProps>({})

const DocumentChangePage: React.FC = () => {
  const currentDocument = useSelector(selectCurrentDocument)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const windowDimensions = useWindowDimensions()
  const currentProject = useSelector(selectCurrentProject) as Project
  const [selectedDocument, setSelectedDocument] =
    useState<ProjectDocumentMetadata | null>(null)
  const [exportDocumentChanges] = useCreateDocumentChangesExportMutation()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [filter, setFilter] = useState<WorkflowFilter>({})

  const { data: documents, isFetching: documentsLoading } =
    useGetDocumentsListByProjectQuery(
      currentProject ? { projectId: currentProject?.id } : skipToken
    )
  const [selectedDocumentChange, setSelectedDocumentChange] =
    useState<DocumentChange | null>(null)
  const [searchParams] = useSearchParams()
  const commentUUID = searchParams.get('comment')
  const variableListRef = useRef<VariableSizeList | null>(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const highlightedDocumentChange = useSelector(selectHighlightedDocumentChange)
  const virtuoso = useRef<VirtuosoHandle | null>(null)

  const { currentData: documentChanges, isLoading: changesLoading } =
    useGetDocumentChangesQuery(
      currentProject
        ? {
          projectId: currentProject?.uuid,
          query: debouncedSearchQuery,
        }
        : skipToken
    )

  const reset = () => {
    dispatch(setTemporaryHighlight(null))
    setSelectedDocumentChange(null)
  }

  useEffect(() => {
    if (!commentUUID || !documentChanges) {
      return
    }
    setFilter((f) => {
      return { ...f, comment_uuid: commentUUID }
    })
    const foundChange = documentChanges?.find((r) =>
      r.comments?.some((c) => c?.id === commentUUID)
    )
    if (!foundChange) {
      return
    }
    setSelectedDocumentChange(foundChange)
  }, [commentUUID, documentChanges])

  useEffect(() => {
    if (!highlightedDocumentChange) {
      return
    }
    const foundDocumentChangeIndex = documentChanges?.findIndex(
      (r) => r.id === highlightedDocumentChange
    )
    if (
      foundDocumentChangeIndex === -1 ||
      foundDocumentChangeIndex === undefined
    ) {
      return
    }
    setSelectedDocumentChange(
      documentChanges?.[foundDocumentChangeIndex] ?? null
    )
    virtuoso.current?.scrollToIndex({
      index: foundDocumentChangeIndex,
      align: 'center',
      behavior: 'smooth',
    })
  }, [documentChanges, highlightedDocumentChange])

  const onEscapeKey = () => {
    reset()
  }
  useHotkeys('esc', onEscapeKey)

  const exportToFile = useCallback(
    async (fileType: 'xls' | 'pdf') => {
      if (!documentChanges) {
        return
      }
      const response = await exportDocumentChanges(
        selectedDocument
          ? {
            documentId: selectedDocument?.uuid,
            file_type: fileType,
          }
          : currentProject
            ? {
              projectId: currentProject?.uuid,
              file_type: fileType,
            }
            : skipToken
      ).unwrap()
      window.open(response.export_url, '_blank')
    },
    [documentChanges, exportDocumentChanges, selectedDocument, currentProject]
  )

  const sizeMap = useRef({})
  const setSize = useCallback((index, size) => {
    variableListRef.current?.resetAfterIndex(index - 1, true)
    sizeMap.current = { ...sizeMap.current, [index]: size }
  }, [])

  const documentChangesToDisplay = useMemo(() => {
    return documentChanges?.filter((r) => {
      const result = true
      let resultFilter = true
      const resultQuery = true
      if (filter?.comment_uuid) {
        return r.comments?.some((c) => c?.id === filter.comment_uuid)
      }
      if (filter?.status) {
        resultFilter = filter.status === r.status
      }
      if (filter?.comment_author) {
        resultFilter =
          resultFilter &&
          Boolean(
            r.comments?.some((c) => {
              return c?.user?.id === filter.comment_author?.id
            })
          )
      }
      return resultFilter && resultQuery && result
    })
  }, [
    filter?.status,
    documentChanges,
    filter?.comment_author,
    filter?.comment_uuid,
  ])

  const documentChangesTotals = useMemo(() => {
    const totals = {
      approved: 0,
      notApproved: 0,
      inReview: 0,
    }
    if (!documentChanges) {
      return totals
    }
    totals.approved = documentChanges?.filter(
      (dc) => dc.status === 'APPROVED'
    ).length
    totals.notApproved = documentChanges?.filter(
      (dc) => dc.status === 'NOT_APPROVED'
    ).length
    totals.inReview = documentChanges?.filter(
      (dc) => dc.status === 'IN_REVIEW'
    ).length
    return totals
  }, [documentChanges])

  const onClickNotApprovedFilter = useCallback(() => {
    const status: DocumentChangeTaskStatus | null =
      filter?.status === 'NOT_APPROVED' ? null : 'NOT_APPROVED'
    setFilter((filter) => {
      if (!status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      if (filter?.status === status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status,
      }
    })
  }, [setFilter, filter?.status])

  const onClickApprovedFilter = useCallback(() => {
    const status: DocumentChangeTaskStatus | null =
      filter?.status === 'APPROVED' ? null : 'APPROVED'
    setFilter((filter) => {
      if (!status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      if (filter?.status === status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status,
      }
    })
  }, [setFilter, filter?.status])

  const onClickBookmarkedFilter = useCallback(() => {
    const status: DocumentChangeTaskStatus | null =
      filter?.status === 'IN_REVIEW' ? null : 'IN_REVIEW'
    setFilter((filter) => {
      if (!status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      if (filter?.status === status) {
        return {
          ...filter,
          status: undefined,
        }
      }
      return {
        ...filter,
        status,
      }
    })
  }, [setFilter, filter?.status])

  const onClickExportToExcel = useCallback(() => {
    exportToFile('xls')
  }, [exportToFile])

  const onClickExportToPDF = useCallback(() => {
    exportToFile('pdf')
  }, [exportToFile])

  const onSelectUser = useCallback((user: ProvisionUser) => {
    setFilter((filter) => {
      if (filter?.comment_author?.id === user.id) {
        return {
          ...filter,
          comment_author: undefined,
        }
      }
      return {
        ...filter,
        comment_author: user,
      }
    })
  }, [])

  const onSetSearchQuery = useCallback((e) => {
    setSearchQuery(e.target.value)
    setIsSearching(true)
    sizeMap.current = {}
    debounce(() => {
      setIsSearching(false)
      setDebouncedSearchQuery(e.target.value)
    }, 1000)()
  }, [])

  const onClickNavigateDocumentViewer = useCallback(() => {
    navigate(
      `/${currentProject?.uuid}/documents${currentDocument ? `/${currentDocument?.uuid}` : ''
      }`
    )
  }, [currentDocument, currentProject?.uuid, navigate])

  // once documents are loaded, check if any are in review, and post to redux
  useEffect(() => {
    if (!documents) return

    const isProcessing = documents.some(
      (document) => document.job_status === 'IN_REVIEW'
    )

    dispatch(setSupplementaryConditionsAreProcessing(isProcessing))
  }, [documents, dispatch])
  const isInReview = useSelector(selectSupplementaryConditionsAreProcessing)

  const getAllCommentAuthors = () => {
    return (
      documentChanges?.reduce<ProvisionUser[]>((acc, r) => {
        if (!r?.comments) {
          return acc
        }
        for (const comment of r.comments) {
          if (!comment?.user) {
            continue
          }
          if (
            acc.find((user) => user?.id === comment?.user?.id) === undefined
          ) {
            acc.push(comment?.user)
          }
        }
        return acc
      }, []) ?? []
    )
  }

  const commentAuthors = useMemo(
    () => getAllCommentAuthors(),
    // eslint-disable-next-line
    [documentChanges]
  )

  return (
    <div className="flex h-full w-full flex-col space-y-2">
      <div className="flex w-full flex-grow-0 flex-col py-0">
        {isInReview ? (
          <SupplementaryConditionsBanner />
        ) : (
          <DocumentChangeBanner
            isLoading={documentsLoading || changesLoading || !currentProject}
            isInReview={isInReview ?? false}
            hasChanges={(documentChanges ?? [])?.length > 0}
            onUploadClick={onClickNavigateDocumentViewer}
          />
        )}

        {documents ? (
          <div className={'flex flex-grow flex-wrap gap-1 p-2'}>
            <input
              className={
                'w-96 resize-none rounded border-gray-200 p-1 px-3 text-sm text-gray-800 placeholder-gray-400'
              }
              placeholder="Search"
              type={'text'}
              onChange={onSetSearchQuery}
              value={searchQuery}
            />
            <div>
              <ExportPopover
                exportTypes={['excel', 'pdf']}
                isDisabled={!documentChanges || documentChanges.length === 0}
                onClickExportToExcel={onClickExportToExcel}
                onClickExportToPDF={onClickExportToPDF}
                compact={true}
              />
            </div>
            <DocumentListbox
              documents={documents}
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
            />
            <CommentFilterPopover
              commentAuthors={commentAuthors}
              onSelectUser={onSelectUser}
            />
            <button
              onClick={onClickApprovedFilter}
              className={clsx(
                'space-x-1 rounded border px-3 py-1 text-xs',
                filter?.status === 'APPROVED'
                  ? 'bg-gray-100 hover:bg-white'
                  : 'bg-white hover:bg-gray-100'
              )}
            >
              <span>Addressed</span>
              <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-sm text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {documentChangesTotals.approved}
              </span>
            </button>
            <button
              onClick={onClickNotApprovedFilter}
              className={clsx(
                'space-x-1 rounded border px-3 py-1 text-xs',
                filter?.status === 'NOT_APPROVED'
                  ? 'bg-gray-100 hover:bg-white'
                  : 'bg-white hover:bg-gray-100'
              )}
            >
              <span>Not Addressed</span>
              <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {documentChangesTotals.notApproved}
              </span>
            </button>
            <button
              onClick={onClickBookmarkedFilter}
              className={clsx(
                'space-x-1 rounded border px-3 py-1 text-xs',
                filter?.status === 'IN_REVIEW'
                  ? 'bg-gray-100 hover:bg-white'
                  : 'bg-white hover:bg-gray-100'
              )}
            >
              <span>Review</span>
              <span className="inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {documentChangesTotals.inReview}
              </span>
            </button>
          </div>
        ) : (
          <div className="flex min-h-[90vh] w-full items-center justify-center">
            <Loader size="xl" color="yellow" />
          </div>
        )}
      </div>
      {(filter.comment_author || filter.status || filter.comment_uuid) && (
        <div className="ml-4 flex w-fit rounded bg-gray-100 p-2 text-xs shadow-sm">
          <span className="pr-1">Filtering by</span>{' '}
          {<FilterDisplay setFilter={setFilter} filter={filter} />}
        </div>
      )}

      {isSearching || changesLoading
        ? null
        : documentChangesToDisplay && (
          <ChangesContext.Provider
            value={{
              setSize,
              windowDimensions,
              selectedDocument,
            }}
          >
            <div className="flex-1 overflow-auto px-2">
              <Virtuoso
                ref={virtuoso}
                style={{ height: '100%', zIndex: '0' }}
                data={documentChangesToDisplay}
                itemContent={(index, documentChange) => (
                  <DocumentChangeCard
                    index={index}
                    key={`document_change_card_${documentChange?.id}`}
                    setSelectedDocumentChange={setSelectedDocumentChange}
                    documentChange={documentChange}
                    selectedDocument={selectedDocument}
                    selectedDocumentChange={selectedDocumentChange}
                    query={debouncedSearchQuery}
                  />
                )}
              />
            </div>
          </ChangesContext.Provider>
        )}
    </div>
  )
}

export default DocumentChangePage
