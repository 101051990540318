import React, { useCallback, useMemo, useState } from 'react'
import {
  RiskRanking,
  RiskStatus,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { FunnelIcon } from '@heroicons/react/24/outline'
import { Button, Popover, Select, Text, CloseButton } from '@mantine/core'
import { useGetRiskCategoriesQuery } from '../../../../redux/api/qa-api-slice'
import { selectCurrentProject } from '../../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetRiskListFiltersQuery } from '../../../../redux/api/project-risk-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  selectSelectedDocuments,
  selectSelectedRankingForRiskList,
  selectSelectedRiskList,
  selectSelectedCategoryForRiskList,
  selectSelectedDefaultPositionForRiskList,
  selectSelectedStatusFilter,
  setRiskListSearchQuery,
  setSelectedCategoryFilter,
  setSelectedDocuments,
  setSelectedRankingFilter,
  setSelectedRiskList,
  setSelectedDefaultPositionFilter,
  setSelectedStatusFilter,
  selectSelectedSort,
  setSelectedSort,
  selectSelectedConflictsFilter,
  setSelectedConflictsFilter,
} from '../../../../redux/risks-search-slice'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import DocumentListboxMulti from '../../../document-listbox/document-listbox-multi'
import { useGetDocumentsListByProjectQuery } from '../../../../redux/api-slice'
import { ActiveDot } from '../../../workflow-sidebar/risk-review-header'

export const RiskListHeader = () => {
  const currentProject = useSelector(selectCurrentProject)
  const selectedDocuments = useSelector(selectSelectedDocuments)
  const selectedRiskList = useSelector(selectSelectedRiskList)
  const selectedDefaultPositionFilter = useSelector(
    selectSelectedDefaultPositionForRiskList
  )
  const selectedRanking = useSelector(selectSelectedRankingForRiskList)
  const selectedStatusFilter = useSelector(selectSelectedStatusFilter)
  const selectedSort = useSelector(selectSelectedSort)
  const selectedConflictsFilter = useSelector(selectSelectedConflictsFilter)
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const { data: riskLists, isLoading: riskListsLoading } =
    useGetRiskListFiltersQuery(currentProject?.id ?? skipToken)
  const dispatch = useDispatch()
  const [opened, setOpened] = useState(false)

  const onApprovalStatusChange = useCallback(
    (value: string | null) => {
      dispatch(setSelectedStatusFilter(value ?? null))
    },
    [dispatch]
  )

  const onDefaultPositionChange = useCallback(
    (value: string | null) => {
      dispatch(setSelectedDefaultPositionFilter(value?.toUpperCase() ?? null))
    },
    [dispatch]
  )

  const onRiskListChange = useCallback(
    (value: string | null) => {
      if (!value) {
        dispatch(setSelectedRiskList(null))
        return
      }
      dispatch(
        setSelectedRiskList(
          riskLists?.find((list) => list.id.toString() === value)
        )
      )
    },
    [dispatch, riskLists]
  )

  const onRankingChange = useCallback(
    (value: string | null) => {
      dispatch(setSelectedRankingFilter(value ?? null))
    },
    [dispatch]
  )

  const onCategoryChange = useCallback(
    (value: string | null) => {
      dispatch(setSelectedCategoryFilter(value))
    },
    [dispatch]
  )

  const selectedCategory = useSelector(selectSelectedCategoryForRiskList)

  const handleSetSelectedDocuments = (
    docs: ProjectDocumentMetadata[] | null
  ) => {
    dispatch(setSelectedDocuments(docs))
  }

  const isFilterActive = useMemo(() => {
    return (
      !!selectedDocuments?.length ||
      !!selectedStatusFilter ||
      !!selectedDefaultPositionFilter ||
      !!selectedRanking ||
      !!selectedCategory ||
      !!selectedConflictsFilter
    )
  }, [
    selectedDocuments,
    selectedStatusFilter,
    selectedDefaultPositionFilter,
    selectedRanking,
    selectedCategory,
    selectedConflictsFilter,
  ])

  const onClearFilter = useCallback(() => {
    dispatch(setSelectedDocuments(null))
    dispatch(setSelectedRiskList(null))
    dispatch(setRiskListSearchQuery(''))
    dispatch(setSelectedCategoryFilter(null))
    dispatch(setSelectedRankingFilter(null))
    dispatch(setSelectedStatusFilter(null))
    dispatch(setSelectedDefaultPositionFilter(null))
    dispatch(setSelectedConflictsFilter(null))
  }, [dispatch])

  const onSortChange = useCallback(
    (value: string | null) => {
      dispatch(
        setSelectedSort(value as 'AZ' | 'ZA' | 'HIGH_TO_LOW' | 'LOW_TO_HIGH')
      )
    },
    [dispatch]
  )

  const onConflictsChange = useCallback(
    (value: string | null) => {
      dispatch(setSelectedConflictsFilter(value ?? null))
    },
    [dispatch]
  )

  const dedupedRiskLists = useMemo(
    () =>
      riskLists
        ? [...new Map(riskLists.map((list) => [list.id, list])).values()]
        : [],
    [riskLists]
  )

  return (
    <div>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center justify-start gap-2">
          <div className="flex items-center">
            <Select
              data={[
                { value: '', label: 'All Checklists' },
                ...(dedupedRiskLists.map((list) => ({
                  value: list.id.toString(),
                  label: list.name,
                })) ?? []),
              ]}
              placeholder="List"
              onChange={onRiskListChange}
              value={selectedRiskList?.id?.toString() ?? ''}
              disabled={riskListsLoading}
              data-testid="risk-review-filter-risk-list-select"
            />
            {riskListsLoading && (
              <div className="ml-2 h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-500" />
            )}
          </div>
          <Popover
            width={300}
            position="bottom"
            withArrow
            shadow="md"
            opened={opened}
            onChange={setOpened}
          >
            <Popover.Target>
              <Button
                className="flex-shrink-0"
                variant="subtle"
                size="xs"
                color="gray"
                leftSection={<FunnelIcon className="h-4 w-4" />}
                data-testid="risk-review-filter-button"
                onClick={() => setOpened((o) => !o)}
              >
                Filter
                <ActiveDot active={isFilterActive} />
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <div className="flex flex-col gap-2 p-2">
                <div className="mb-2 flex items-center justify-between">
                  <Text size="sm" fw="500">
                    Filters
                  </Text>
                  <CloseButton size="md" onClick={() => setOpened(false)} />
                </div>
                <div className="space-y-1">
                  <Text size="sm" fw="500">
                    Documents
                  </Text>
                  <DocumentListboxMulti
                    selectedDocuments={selectedDocuments ?? []}
                    documents={documents}
                    setSelectedDocuments={handleSetSelectedDocuments}
                  />
                </div>
                <Select
                  data={[
                    { value: '', label: 'All Rankings' },
                    { value: RiskRanking.High.toString(), label: 'High' },
                    { value: RiskRanking.Medium.toString(), label: 'Medium' },
                    { value: RiskRanking.Low.toString(), label: 'Low' },
                    {
                      value: RiskRanking.NoRanking.toString(),
                      label: 'Unranked',
                    },
                  ]}
                  value={selectedRanking ?? ''}
                  placeholder="Ranking"
                  onChange={onRankingChange}
                  label="Ranking"
                  data-testid="risk-review-filter-ranking-select"
                  comboboxProps={{ withinPortal: false }}
                />
                <Select
                  data={[
                    { value: '', label: 'All Statuses' },
                    {
                      value: RiskStatus.APPROVED.toString(),
                      label: 'Approved',
                    },
                    {
                      value: RiskStatus.NOT_APPROVED.toString(),
                      label: 'Not Approved',
                    },
                    {
                      value: RiskStatus.NEEDS_REVIEW.toString(),
                      label: 'Needs Review',
                    },
                  ]}
                  value={selectedStatusFilter ?? ''}
                  placeholder="STATUS"
                  className="w-full"
                  onChange={onApprovalStatusChange}
                  label="Status"
                  data-testid="risk-review-filter-status-select"
                  comboboxProps={{ withinPortal: false }}
                />
                <Select
                  data={[
                    { value: '', label: 'All Positions' },
                    { value: 'MET', label: 'Met' },
                    { value: 'NOT_MET', label: 'Not Met' },
                    { value: 'INDETERMINATE', label: 'Indeterminate' },
                  ]}
                  value={selectedDefaultPositionFilter ?? ''}
                  placeholder="DEFAULT POSITION"
                  onChange={onDefaultPositionChange}
                  label="Default Position"
                  comboboxProps={{ withinPortal: false }}
                />
                <Select
                  data={[
                    { value: '', label: 'All Sections' },
                    { value: 'null', label: 'No Section' },
                    ...(riskCategories?.map((category) => ({
                      value: category.id?.toString() ?? '',
                      label: category.name,
                    })) ?? []),
                  ]}
                  value={selectedCategory?.toString() ?? ''}
                  placeholder="Section"
                  onChange={onCategoryChange}
                  label="Sections"
                  comboboxProps={{ withinPortal: false }}
                />
                <Select
                  data={[
                    { value: '', label: 'All Conflicts' },
                    { value: 'true', label: 'Has Conflicts' },
                    { value: 'false', label: 'No Conflicts' },
                  ]}
                  value={selectedConflictsFilter ?? ''}
                  placeholder="Conflicts"
                  onChange={onConflictsChange}
                  label="Conflicts"
                  comboboxProps={{ withinPortal: false }}
                />
              </div>
            </Popover.Dropdown>
          </Popover>
          <Button
            className="flex-shrink-0"
            variant="subtle"
            size="xs"
            color="gray"
            onClick={onClearFilter}
          >
            Clear Filter
          </Button>
        </div>
        <div className="flex items-center justify-end gap-2">
          <Select
            data={[
              { value: 'AZ', label: 'A-Z' },
              { value: 'ZA', label: 'Z-A' },
              { value: 'HIGH_TO_LOW', label: 'High to Low' },
              { value: 'LOW_TO_HIGH', label: 'Low to High' },
            ]}
            value={selectedSort ?? ''}
            placeholder="Sort"
            onChange={onSortChange}
          />
        </div>
      </div>
    </div>
  )
}
