import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { Project } from '../../shared/interfaces/project/project.interface'
import { useGetDocumentsByProjectQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { ProjectDocument } from '../../shared/interfaces/project/document/document.interface'
import FolderContents from './folder-contents'

interface DocumentListboxFoldersProps {
  currentProject: Project | null
  selectedDocuments: ProjectDocumentMetadata[]
  setSelectedDocuments: (docs: ProjectDocumentMetadata[]) => void
}

export interface ProcessedFolder {
  id: string
  name: string
  documents: ProjectDocument[]
  subFolders: {
    [folderId: string]: ProcessedFolder
  }
}

export interface RootStructure {
  rootDocuments: ProjectDocument[]
  folders: {
    [folderId: string]: ProcessedFolder
  }
}

const DocumentListboxFolders = ({
  currentProject,
  selectedDocuments,
  setSelectedDocuments,
}: DocumentListboxFoldersProps) => {
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { data } = useGetDocumentsByProjectQuery(
    currentProject
      ? {
          projectId: currentProject.id,
          latest_version_only: true,
        }
      : skipToken
  )

  const structure = useMemo((): RootStructure | null => {
    if (!data) return null

    const structure: RootStructure = {
      rootDocuments: [],
      folders: {},
    }

    // First: Filter all documents based on query and status
    const filteredDocs = data.documents.filter(
      (doc) =>
        (doc.job_status === 'COMPLETE' || doc.job_status === 'IN_REVIEW') &&
        (!query || doc.title.toLowerCase().includes(query.toLowerCase()))
    )

    // Separate root documents and folder documents
    structure.rootDocuments = filteredDocs.filter((doc) => !doc.folder)
    const folderDocs = filteredDocs.filter((doc) => doc.folder)

    // Create folder structure
    data.folders.forEach((folder) => {
      if (!folder.folder) {
        structure.folders[folder.id] = {
          id: folder.id,
          name: folder.name || '',
          documents: [],
          subFolders: {},
        }
      }
    })

    // Build folder hierarchy
    data.folders.forEach((folder) => {
      if (folder.folder) {
        const parentId =
          typeof folder.folder === 'string' ? folder.folder : folder.folder.id
        if (structure.folders[parentId]) {
          structure.folders[parentId].subFolders[folder.id] = {
            id: folder.id,
            name: folder.name || '',
            documents: [],
            subFolders: {},
          }
        }
      }
    })

    // Place documents in folders
    folderDocs.forEach((doc) => {
      if (!doc.folder || typeof doc.folder === 'string') return
      const folderId = doc.folder.id
      if (structure.folders[folderId]) {
        structure.folders[folderId].documents.push(doc)
      } else {
        Object.values(structure.folders).forEach((rootFolder) => {
          if (rootFolder.subFolders[folderId]) {
            rootFolder.subFolders[folderId].documents.push(doc)
          }
        })
      }
    })

    // Remove empty folders
    const removeEmptyFolders = (folder: ProcessedFolder): boolean => {
      Object.keys(folder.subFolders).forEach((subFolderId) => {
        if (!removeEmptyFolders(folder.subFolders[subFolderId])) {
          delete folder.subFolders[subFolderId]
        }
      })
      return (
        folder.documents.length > 0 || Object.keys(folder.subFolders).length > 0
      )
    }

    Object.keys(structure.folders).forEach((folderId) => {
      if (!removeEmptyFolders(structure.folders[folderId])) {
        delete structure.folders[folderId]
      }
    })

    return structure
  }, [data, query])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setQuery('')
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setQuery('')
    }
  }, [isOpen])

  const getIndentClass = useCallback((level: number) => {
    switch (level) {
      case 1:
        return 'ml-2'
      case 2:
        return 'ml-4'
      case 3:
        return 'ml-6'
      case 4:
        return 'ml-8'
      case 5:
        return 'ml-10'
      case 6:
        return 'ml-12'
      case 7:
        return 'ml-14'
      case 8:
        return 'ml-16'
      case 9:
        return 'ml-18'
      case 10:
        return 'ml-20'
      default:
        return ''
    }
  }, [])

  const getAllDocumentsInFolder = useCallback(
    (folder: ProcessedFolder): ProjectDocument[] => {
      let docs = [...folder.documents]
      Object.values(folder.subFolders).forEach((subFolder) => {
        docs = [...docs, ...getAllDocumentsInFolder(subFolder)]
      })
      return docs
    },
    []
  )

  const handleDocumentClick = useCallback(
    (doc: ProjectDocument) => {
      const internalDoc = {
        id: doc.id,
        uuid: doc.uuid,
        title: doc.title,
        total_pages: doc.total_pages,
        job_status: doc.job_status,
        v1_document: doc.v1_document,
        document_version: doc.document_version,
        date_created: doc.date_created,
        folder: doc.folder?.id ? Number(doc.folder.id) : undefined,
      }
      if (selectedDocuments.find((d) => d.id === doc.id)) {
        const newSelection = selectedDocuments.filter((d) => d.id !== doc.id)
        setSelectedDocuments(newSelection)
      } else {
        const newSelection = [...selectedDocuments, internalDoc]
        setSelectedDocuments(newSelection)
      }
    },
    [selectedDocuments, setSelectedDocuments]
  )

  const handleFolderClick = useCallback(
    (folder: ProcessedFolder) => {
      const allDocs = getAllDocumentsInFolder(folder)
      const allSelected = allDocs.every((doc) =>
        selectedDocuments.find((d) => d.id === doc.id)
      )

      if (allSelected) {
        const newSelection = selectedDocuments.filter(
          (d) => !allDocs.find((doc) => doc.id === d.id)
        )
        setSelectedDocuments(newSelection)
      } else {
        const newSelection = [
          ...selectedDocuments,
          ...allDocs
            .filter((doc) => !selectedDocuments.find((d) => d.id === doc.id))
            .map((doc) => ({
              id: doc.id,
              uuid: doc.uuid,
              title: doc.title,
              total_pages: doc.total_pages,
              job_status: doc.job_status,
              v1_document: doc.v1_document,
              document_version: doc.document_version,
              date_created: doc.date_created,
              folder: doc.folder?.id ? Number(doc.folder.id) : undefined,
            })),
        ]
        setSelectedDocuments(newSelection)
      }
    },
    [selectedDocuments, getAllDocumentsInFolder, setSelectedDocuments]
  )

  const placeholderText = useMemo(
    () =>
      selectedDocuments.length > 0
        ? `${selectedDocuments.length} documents`
        : 'All documents',
    [selectedDocuments.length]
  )

  return (
    <Combobox
      as="div"
      value={selectedDocuments.map((d) => d.id)}
      onChange={() => {}}
      multiple
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onFocus={() => setIsOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              handleClose()
            }
          }}
          placeholder={placeholderText}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="fixed z-10 mt-1 max-h-96 w-full max-w-[22rem] overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {structure ? (
            <div>
              {/* Root Documents */}
              {structure.rootDocuments.map((doc) => (
                <div key={doc.id} className="relative">
                  <div
                    onClick={() => handleDocumentClick(doc)}
                    className="flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100"
                  >
                    <div
                      className={`absolute inset-0 ${
                        selectedDocuments.find((d) => d.id === doc.id)
                          ? 'bg-indigo-600'
                          : ''
                      }`}
                    />
                    <div className="relative z-10 flex items-center gap-2">
                      <DocumentIcon
                        className={`h-4 w-4 flex-shrink-0 ${
                          selectedDocuments.find((d) => d.id === doc.id)
                            ? 'text-white'
                            : 'text-gray-400'
                        }`}
                      />
                      <span
                        className={
                          selectedDocuments.find((d) => d.id === doc.id)
                            ? 'text-white'
                            : ''
                        }
                      >
                        {doc.title}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {/* Folders and their contents */}
              {Object.values(structure.folders).map((folder) => (
                <FolderContents
                  key={folder.id}
                  folder={folder}
                  level={0}
                  selectedDocuments={selectedDocuments}
                  getAllDocumentsInFolder={getAllDocumentsInFolder}
                  handleDocumentClick={handleDocumentClick}
                  handleFolderClick={handleFolderClick}
                  getIndentClass={getIndentClass}
                />
              ))}
            </div>
          ) : (
            <div className="p-2 text-center text-gray-500">No items found</div>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default DocumentListboxFolders
