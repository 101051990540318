import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject, setModal } from '../../redux/application-slice'
import { Button, Text } from '@mantine/core'
import {
  useGetProjectRiskListTemplateSettingsQuery,
  useUpdateEnabledStatusProjectRiskListTemplateSettingsMutation,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  RiskListTemplateSettings,
  RiskListTemplateSettingsPatch,
} from '../../shared/interfaces/risk-list-template-settings/risk-list-template-settings'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'
import { usePostHog } from 'posthog-js/react'
interface EditProjectRiskListTemplatesModalProps {
  open: boolean
}

const EditProjectRiskListTemplatesModalCheckbox = ({
  setting,
  onToggle,
}: {
  setting: RiskListTemplateSettings
  onToggle: (id: number, newValue: boolean) => void
}) => {
  const [enabled, setEnabled] = useState(setting.enabled)
  const onChange = useCallback(async () => {
    if (!setting.risk_list_template.id) {
      return
    }
    const newValue = !enabled
    setEnabled(newValue)
    onToggle(setting.risk_list_template.id, newValue)
  }, [onToggle, setting.risk_list_template.id, enabled])

  return (
    <label
      key={setting.risk_list_template.id}
      className={`flex items-center justify-between gap-2 ${
        setting.enabled ? 'opacity-50' : 'cursor-pointer'
      }`}
    >
      <input
        disabled={setting.enabled}
        onChange={onChange}
        type="checkbox"
        checked={enabled}
        className="flex-shrink-0 rounded border-gray-300 focus:ring-0 focus:ring-offset-0"
      />
      <span
        className="flex-1 truncate"
        title={setting.risk_list_template?.name}
      >
        {setting.risk_list_template?.name}
      </span>
    </label>
  )
}

function EditProjectRiskListTemplatesModal({
  open,
}: EditProjectRiskListTemplatesModalProps) {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const currentProject = useSelector(selectCurrentProject)
  const [checkboxValues, setCheckboxValues] = useState<Record<number, boolean>>(
    {}
  )
  const posthog = usePostHog()

  const { data: riskListTemplateSettings } =
    useGetProjectRiskListTemplateSettingsQuery(
      currentProject?.id ? currentProject.id : skipToken
    )

  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])

  const [updateEnabledStatusProjectRiskListTemplateSettings] =
    useUpdateEnabledStatusProjectRiskListTemplateSettingsMutation()

  const onSubmit = useCallback(async () => {
    if (!currentProject?.id) {
      return
    }
    const risk_list_template_settings: RiskListTemplateSettingsPatch[] =
      Object.keys(checkboxValues).map((id) => ({
        risk_list_template: parseInt(id),
        enabled: checkboxValues[id],
      }))
    setIsSaving(true)
    try {
      await updateEnabledStatusProjectRiskListTemplateSettings({
        projectId: currentProject.id,
        risk_list_template_settings,
      })
      posthog.capture('project_risk_list_templates_updated', {
        projectId: currentProject?.id,
        risk_list_template_settings,
      })
      onClose()
    } catch {
      toast.error('Failed to update risk list template settings')
    } finally {
      setIsSaving(false)
    }
  }, [
    checkboxValues,
    currentProject?.id,
    onClose,
    posthog,
    updateEnabledStatusProjectRiskListTemplateSettings,
  ])

  const handleToggle = useCallback((id: number, newValue: boolean) => {
    setCheckboxValues((prev) => ({ ...prev, [id]: newValue }))
  }, [])

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Close</span>
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="space-y-6">
          <div>
            <div className="mb-2 flex items-center gap-2">
              <Text size="lg">Configure Risk Review Checklists</Text>
              <QuestionMarkCircleIcon
                className="h-4 w-4 text-gray-500"
                data-tooltip-id="risk-list-templates-tooltip"
                data-tooltip-content="Enable or disable risk review checklists for this project. When enabled, changes to the project will automatically trigger risk reviews."
              />
            </div>
            <Text size="sm" className="text-gray-500">
              Select which checklists should run automatically when project
              changes occur.
            </Text>
          </div>
          <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto rounded-md border border-gray-200 p-3 text-sm">
            {riskListTemplateSettings?.map((setting) => (
              <EditProjectRiskListTemplatesModalCheckbox
                key={setting.risk_list_template.id}
                setting={setting}
                onToggle={handleToggle}
              />
            ))}
          </div>
          <div className="flex justify-end gap-3">
            <Button variant="light" color="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="filled"
              color="blue"
              onClick={onSubmit}
              type="submit"
              disabled={
                isSaving ||
                Object.keys(checkboxValues).length === 0 ||
                Object.values(checkboxValues).every((value) => !value)
              }
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [
    checkboxValues,
    handleToggle,
    isSaving,
    onClose,
    onSubmit,
    riskListTemplateSettings,
  ])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
        <Tooltip className="z-50" id="risk-list-templates-tooltip" />
      </Dialog>
    </Transition.Root>
  )
}

export default EditProjectRiskListTemplatesModal
