import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject, setModal } from '../../redux/application-slice'
import { useCreateRiskExportMutation } from '../../redux/api-slice'
import { useGetRiskListFiltersQuery } from '../../redux/api/project-risk-api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { toast } from 'react-toastify'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import {
  Modal,
  Stack,
  Button,
  MultiSelect,
  Select,
  Box,
  Group,
  ThemeIcon,
  Loader,
} from '@mantine/core'

type ExportFormat = 'pdf' | 'xlsx' | 'docx'
type SortOption = 'A-Z' | 'Z-A' | 'Risk Ranking'
type StatusOption = '' | '1' | '2' | '3'

export default function ExportRiskReviewModal({ open }: { open: boolean }) {
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const [format, setFormat] = useState<ExportFormat>('pdf')
  const [sort, setSort] = useState<SortOption>('A-Z')
  const [status, setStatus] = useState<StatusOption>('')
  const [includeComments, setIncludeComments] = useState(false)
  const [selectedRiskLists, setSelectedRiskLists] = useState<number[]>([])
  const [createRiskExport] = useCreateRiskExportMutation()

  useEffect(() => {
    if (open) {
      window.setTimeout(() => {
        const modalContent = document.querySelector('.overflow-y-auto')
        if (modalContent) {
          modalContent.scrollTop = 0
        }
      }, 0)
    }
  }, [open])

  const { data: riskLists, isLoading: riskListsLoading } =
    useGetRiskListFiltersQuery(currentProject?.id ?? skipToken)

  const onClose = () => {
    dispatch(setModal(null))
  }

  const handleExport = () => {
    if (!currentProject?.uuid) return

    if (format === 'pdf') {
      posthog?.capture(POSTHOG.export_risk_pdf, {
        project_uuid: currentProject.uuid,
      })
    } else if (format === 'xlsx') {
      posthog?.capture(POSTHOG.export_risk_excel, {
        project_uuid: currentProject.uuid,
      })
    } else if (format === 'docx') {
      posthog?.capture(POSTHOG.export_risk_word, {
        project_uuid: currentProject.uuid,
      })
    }

    createRiskExport({
      projectId: currentProject.uuid,
      type: format,
      riskSort: sort,
      status: status,
      include_comments: includeComments,
      riskList: selectedRiskLists,
    })
      .unwrap()
      .then((response) => {
        if (response.export_url) {
          window.open(response.export_url, '_blank')
        }
      })

    toast.success('Risk Review export started')
    onClose()
  }

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={
        <Group>
          <ThemeIcon size="lg" radius="xl" color="blue.1">
            <ArrowDownTrayIcon className="h-6 w-6 text-blue-600" />
          </ThemeIcon>
          <Box>Export Risk Review</Box>
        </Group>
      }
      size="lg"
    >
      <Stack gap="md">
        <Select
          label="Format"
          value={format}
          onChange={(value) => setFormat(value as ExportFormat)}
          data={[
            { value: 'pdf', label: 'PDF' },
            { value: 'xlsx', label: 'Excel' },
            { value: 'docx', label: 'Word' },
          ]}
        />

        <Select
          label="Sort"
          value={sort}
          onChange={(value) => setSort(value as SortOption)}
          data={[
            { value: 'A-Z', label: 'A-Z' },
            { value: 'Z-A', label: 'Z-A' },
            { value: 'Risk Ranking', label: 'Ranking (High-Low)' },
          ]}
        />

        <Select
          label="Status"
          value={status}
          onChange={(value) => setStatus(value as StatusOption)}
          data={[
            { value: '', label: 'All' },
            { value: '1', label: 'Approved' },
            { value: '2', label: 'Not Approved' },
            { value: '3', label: 'In Review' },
          ]}
        />

        <Select
          label="Comments"
          value={includeComments.toString()}
          onChange={(value) => setIncludeComments(value === 'true')}
          data={[
            { value: 'false', label: 'Exclude' },
            { value: 'true', label: 'Include' },
          ]}
        />

        <MultiSelect
          label="Lists"
          placeholder="All risks"
          value={selectedRiskLists.map(String)}
          onChange={(values) => {
            if (values.includes('-1')) {
              setSelectedRiskLists([])
            } else {
              setSelectedRiskLists(values.map(Number))
            }
          }}
          data={[
            { value: '-1', label: 'All Risks' },
            ...(riskLists?.map((list) => ({
              value: String(list.id),
              label: list.name,
            })) || []),
          ]}
          maxDropdownHeight={200}
          rightSection={riskListsLoading ? <Loader size="xs" /> : null}
          disabled={riskListsLoading}
        />

        <Group justify="flex-end" mt="md">
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button color="blue" onClick={handleExport}>
            Export
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
