import { Listbox } from '@headlessui/react'
import React, { useCallback, useMemo } from 'react'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import DocumentListboxOption from './document-listbox-option'

interface DocumentListboxProps {
  documents?: ProjectDocumentMetadata[]
  selectedDocument: ProjectDocumentMetadata | null
  setSelectedDocument: (d: ProjectDocumentMetadata) => void
  noDefault?: boolean
  customClass?: string
}

export class DocumentNode {
  id: string
  document: ProjectDocumentMetadata
  children: DocumentNode[]
  version: number
  shouldShowVersion: boolean = false
  constructor(id: string, document: ProjectDocumentMetadata, version: number) {
    this.id = id
    this.children = []
    this.document = document
    this.version = version
  }
}

const DocumentListbox: React.FC<DocumentListboxProps> = ({
  documents,
  selectedDocument,
  setSelectedDocument,
  noDefault,
  customClass,
}) => {
  const getDocumentVersion = useCallback(
    (document: ProjectDocumentMetadata) => {
      if (!document.v1_document) {
        return 1
      }
      let version = 2
      let documentPointer = document.v1_document

      while (documentPointer) {
        const currentDocumentID = documentPointer
        const foundDocument = documents?.find(
          (r: ProjectDocumentMetadata) =>
            r?.id?.toString() === currentDocumentID?.toString()
        )
        if (!foundDocument || !foundDocument.v1_document) {
          break
        }
        documentPointer = foundDocument.v1_document
        version++
      }
      return version
    },
    [documents]
  )

  const getPreviousDocuments = useCallback(
    (document: ProjectDocumentMetadata) => {
      if (!document.v1_document) {
        return null
      }
      let documentPointer = document.v1_document
      const previousDocuments: ProjectDocumentMetadata[] = []

      while (documentPointer) {
        const currentDocumentID = documentPointer
        const foundDocument = documents?.find(
          (r: ProjectDocumentMetadata) =>
            r?.id?.toString() === currentDocumentID?.toString()
        )
        if (!foundDocument) {
          break
        }
        previousDocuments.push({
          ...foundDocument,
          document_version: getDocumentVersion(foundDocument),
        })
        if (!foundDocument.v1_document) {
          break
        }
        documentPointer = foundDocument.v1_document
      }
      return previousDocuments
    },
    [documents, getDocumentVersion]
  )
  const documentLinkedList = useMemo(() => {
    const idToNode: {
      [key: string]: DocumentNode
    } = {}
    const childMap = {}
    const parentMap = {}

    // Create a mapping from id to Node
    documents?.forEach((item) => {
      const node = new DocumentNode(item.id.toString(), item, getDocumentVersion(item))
      idToNode[item.id] = node
    })

    // Connect nodes based on v1_document
    documents?.forEach((item) => {
      if (item.v1_document) {
        childMap[item.v1_document] = item.v1_document
      }
    })

    for (const document of documents ?? []) {
      if (!childMap[document.id]) {
        continue
      }
      parentMap[document.id] = document
    }

    // Find the root nodes
    const rootNodes: DocumentNode[] | undefined = documents
      ?.filter((item) => !childMap[item.id])
      .map((item) => idToNode[item.id])

    for (const rootNode of rootNodes ?? []) {
      const previousDocuments = getPreviousDocuments(rootNode.document)
      rootNode.children =
        previousDocuments?.map((item) => {
          return { ...idToNode[item.id], shouldShowVersion: true }
        }) || []
    }

    return rootNodes
  }, [documents, getDocumentVersion, getPreviousDocuments])

  return (
    <Listbox value={selectedDocument} onChange={setSelectedDocument}>
      <div className={'relative flex w-full self-stretch'}>
        <Listbox.Button
          aria-label="Document Listbox Button"
          className={
            customClass
              ? customClass
              : 'w-full flex-shrink-0 truncate rounded border bg-white px-2.5 py-1 text-left text-sm text-gray-900 hover:bg-gray-50'
          }
        >
          {selectedDocument?.title
            ? selectedDocument?.title
            : noDefault
              ? 'Select a Document'
              : 'All Documents'}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute top-full z-40 mt-1 max-h-96 w-full min-w-fit overflow-auto rounded-md bg-white text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {!noDefault && (
            <Listbox.Option
              aria-label={'Select All Documents'}
              className={
                'flex cursor-pointer justify-between truncate px-2 py-2 hover:bg-gray-200'
              }
              value={null}
              key={'null'}
            >
              All Documents
            </Listbox.Option>
          )}
          <DocumentListboxOption
            isExpanded={true}
            documentNodes={documentLinkedList ?? []}
          />
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default DocumentListbox
