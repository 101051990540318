import React, { useEffect } from 'react'
import DocumentList from '../document-list'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCurrentProject } from '../../../redux/application-slice'

function ProjectHome() {
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)
  const { documentId } = useParams<{ documentId?: string }>()

  useEffect(() => {
    if (currentProject !== null && !documentId) {
      navigate(`/${currentProject.uuid}/documents`)
    }
  }, [currentProject, navigate, documentId])

  return (
    <div
      id="documents-section"
      className="relative flex grow flex-col overflow-hidden"
    >
      <DocumentList />
    </div>
  )
}

export default ProjectHome
