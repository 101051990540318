// viewer slice

import { createSelector, createSlice } from '@reduxjs/toolkit'
import { CustomLabel } from '../shared/interfaces/project/document/custom-label/custom-label.interface'
import { ProjectDocument } from '../shared/interfaces/project/document/document.interface'
import { ProvisionUser } from '../shared/interfaces/user/user.inteface'
import { RevisionStatus } from '../shared/interfaces/project/document/revision/revision.interface'
import { GetRevision } from './api/api-revisions-slice'
import { RiskStatus } from '../shared/interfaces/project/risk/risk-inteface'

interface CommentWorkflowFilterState {
  documentsFilter: ProjectDocument[]
  customLabelsFilter: CustomLabel[]
  searchQuery: string
  authorsFilter: ProvisionUser[]
  statusFilter: RevisionStatus[]
  startDate: string | null
  endDate: string | null
}

export interface RiskWorkflowFilterState {
  projectUUID?: string
  status?: RiskStatus
  defaultPosition?: string
  ranking?: string
}
interface WorkflowState {
  commentWorkflowFilterState: CommentWorkflowFilterState
  riskWorkflowFilterState: RiskWorkflowFilterState
}

const initialState: WorkflowState = {
  commentWorkflowFilterState: {
    documentsFilter: [],
    customLabelsFilter: [],
    authorsFilter: [],
    searchQuery: '',
    statusFilter: [],
    startDate: null,
    endDate: null,
  },
  riskWorkflowFilterState: {
    projectUUID: undefined,
    status: undefined,
    ranking: undefined,
  },
}

export const workflowSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    setCommentWorkflowFilterState: (
      state,
      action: {
        payload: CommentWorkflowFilterState
        type: string
      }
    ) => {
      state.commentWorkflowFilterState = action.payload
    },
    setRiskWorkflowFilterState: (
      state,
      action: {
        payload: RiskWorkflowFilterState
        type: string
      }
    ) => {
      state.riskWorkflowFilterState = action.payload
    },
  },
})

export const { setCommentWorkflowFilterState, setRiskWorkflowFilterState } =
  workflowSlice.actions

export const selectCommentWorkflowFilterState = (state) =>
  state.workflows.commentWorkflowFilterState

const selectStatusFilter = (state) =>
  state.workflows.commentWorkflowFilterState.statusFilter

const currentProjectId = (state) => state.application.currentProject?.id
const selectDocumentsFilter = (state) =>
  state.workflows.commentWorkflowFilterState.documentsFilter
const selectCustomLabelsFilter = (state) =>
  state.workflows.commentWorkflowFilterState.customLabelsFilter

const selectStartDate = (state) =>
  state.workflows.commentWorkflowFilterState.startDate

const selectEndDate = (state) =>
  state.workflows.commentWorkflowFilterState.endDate

export const selectCommentWorkflowFilterStateQueryParams = createSelector(
  [
    selectStatusFilter,
    currentProjectId,
    selectDocumentsFilter,
    selectCustomLabelsFilter,
    selectStartDate,
    selectEndDate,
  ],
  (
    statusFilter,
    projectId,
    documentsFilter,
    customLabelsFilter,
    startDate,
    endDate
  ): GetRevision => {
    return {
      status: statusFilter,
      projectId: projectId,
      documentIds: documentsFilter.map((document) => document.id),
      customLabelIds: customLabelsFilter.map((label) => label.id),
      start_date: startDate,
      end_date: endDate,
    }
  }
)

export const editorReducer = workflowSlice.reducer
