import React, { useCallback } from 'react'
import { Project } from '../../shared/interfaces/project/project.interface'
import {
  ChevronUpDownIcon,
  Cog6ToothIcon,
  FolderIcon,
} from '@heroicons/react/24/outline'
import { ActionIcon, Text } from '@mantine/core'
import {
  selectCurrentProject,
  setCurrentProject,
  setModal,
  setProjectSidebarOpen,
} from '../../redux/application-slice'
import { setCurrentPage } from '../../redux/viewer-slice'
import UseUrlNavigate from '../../hooks/use-url-navigate'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES } from '../modals/modal-controller'
import { Draggable } from '@hello-pangea/dnd'

interface ProjectButtonProps {
  project: Project
  sortIndex: number
  isDraggingDisabled?: boolean
  dragDisabledMessage?: string
}

export default function ProjectButton({
  project,
  sortIndex,
  isDraggingDisabled,
  dragDisabledMessage,
}: ProjectButtonProps) {
  const { navigateUrl } = UseUrlNavigate()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)

  const onProjectOpened = useCallback(() => {
    if (!project?.uuid) {
      return
    }
    navigateUrl(project?.uuid)
    dispatch(setProjectSidebarOpen(false))
    dispatch(setCurrentPage(1))
  }, [dispatch, project, navigateUrl])

  const onProjectSettingsClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      if (currentProject?.id === project.id) {
        dispatch(setModal({ modal: MODAL_TYPES.PROJECT_SETTINGS, project }))
        return
      }

      if (project?.uuid) {
        navigateUrl(project.uuid)
      }
      dispatch(setCurrentProject(project))
      dispatch(setModal({ modal: MODAL_TYPES.PROJECT_SETTINGS, project }))
    },
    [dispatch, project, currentProject, navigateUrl]
  )

  return (
    <Draggable
      key={project.id}
      index={sortIndex}
      draggableId={project.uuid ?? ''}
      isDragDisabled={isDraggingDisabled}
    >
      {(provided, snapshot) => (
        <button
          type="button"
          aria-label={`Navigate to ${project.title}`}
          data-testid={`project_${project.id}`}
          onClick={onProjectOpened}
          className="flex w-full cursor-pointer select-none items-center justify-between rounded-md bg-white px-1 py-1 hover:bg-gray-100"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex items-center gap-2 overflow-hidden">
            <div
              {...provided.dragHandleProps}
              title={isDraggingDisabled ? dragDisabledMessage : undefined}
            >
              <ChevronUpDownIcon
                className={`h-4 w-4 ${isDraggingDisabled ? 'text-gray-300' : 'text-gray-500'}`}
              />
            </div>
            <FolderIcon className="h-6 w-6 flex-shrink-0 text-gray-500" />
            <Text truncate="end" title={project.title}>
              {project.title}
            </Text>
          </div>
          <div className="flex items-center gap-2">
            <ActionIcon
              variant="subtle"
              onClick={onProjectSettingsClick}
              data-testid={`project_${project.title}_options_menu`}
              aria-label={`Project ${project.title} Options Menu`}
            >
              <Cog6ToothIcon className="h-6 w-6 text-gray-500" />
            </ActionIcon>
          </div>
        </button>
      )}
    </Draggable>
  )
}
