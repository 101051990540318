import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { groupBy } from 'lodash'

// Redux
import {
  selectCurrentProject,
  selectSupplementaryConditionsAreProcessing,
} from '../../../../redux/application-slice'
import { useGetDocumentsListByProjectQuery } from '../../../../redux/api-slice'
import {
  useGetProjectRiskQuery,
  useGetProjectRisksProcessingStatusQuery,
} from '../../../../redux/api/project-risk-api-slice'
import { useGetRiskCategoriesQuery } from '../../../../redux/api/qa-api-slice'
import {
  selectRiskListSearchQuery,
  selectSelectedCategoryForRiskList,
  selectSelectedDefaultPositionForRiskList,
  selectSelectedDocuments,
  selectSelectedRankingForRiskList,
  selectSelectedRiskList,
  selectSelectedSort,
  selectSelectedStatusFilter,
  setSelectedCategoryFilter,
  selectSelectedConflictsFilter,
} from '../../../../redux/risks-search-slice'

// Components
import RiskReviewCard from '../risk-review-card/risk-review-card'
import { WorkflowFilter } from '../../workflow-components/filter-display'

// Mantine components
import { Skeleton, Tooltip as MantineTooltip, Text } from '@mantine/core'
import { ProjectRisk } from '../../../../shared/interfaces/project/risk/risk-inteface'

// Third-party
import { Tooltip } from 'react-tooltip'
import { RiskListHeader } from './risk-list-header'
import {
  RiskAnalysingBecauseSCsBanner,
  RiskListTemplateBanner,
} from '../../../banners/specific-banners'

export const useCategoryIdToNameMapping = ({
  riskCategoryId,
}: {
  riskCategoryId: string
}): string => {
  const currentProject = useSelector(selectCurrentProject)
  // TODO put this payload in Redux, so we dont have to fetch it every time a category is displayed (ouch)
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )

  if (!riskCategories) {
    return ''
  }

  const topLevelMapping = riskCategories.reduce(
    (acc, category) => {
      if (category.id && category.name) {
        acc[category.id] = category.name
      }
      return acc
    },
    {} as Record<string, string>
  )
  return topLevelMapping[riskCategoryId]
}

const RiskReviewPage: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const riskListSearchQuery = useSelector(selectRiskListSearchQuery)
  const selectedDocuments = useSelector(selectSelectedDocuments)
  const dispatch = useDispatch()

  const isCategoryValid = (category: string | null) =>
    category !== '' && category !== null && category !== 'No Section'

  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )

  const [shouldDocumentPoll, setShouldDocumentPoll] = useState(true)

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: shouldDocumentPoll ? 10000 : 0,
      refetchOnMountOrArgChange: true,
    }
  )

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  // isFirstTimeProcessing is true when the document processing count is greater than 0. it models
  // the scenario where a user has just created a project, uploaded a document, and is watching the processing state tick down.
  // Because our document processing & risk processing queues are async, we need this to bridge these two states, when risks haven't started processing yet (but are about to).
  const [isFirstTimeProcessing, setIsFirstTimeProcessing] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [transitionTimer, setTransitionTimer] = useState<NodeJS.Timeout | null>(
    null
  )

  useEffect(() => {
    if (documentProcessingCount > 0) {
      setIsFirstTimeProcessing(true)
    } else if (documentProcessingCount === 0 && isFirstTimeProcessing) {
      // Start a transition period when document processing ends
      setShouldDocumentPoll(false)
      setIsTransitioning(true)
      // Set a timeout to end the transition period if risk processing doesn't start
      const timer = setTimeout(() => {
        setIsTransitioning(false)
        setIsFirstTimeProcessing(false)
      }, 10000)
      setTransitionTimer(timer)

      // Ensure the last timer we make is cleaned up
      return () => {
        if (transitionTimer) clearTimeout(transitionTimer)
      }
    }
  }, [documentProcessingCount, isFirstTimeProcessing, transitionTimer])

  const [filter, setFilter] = useState<WorkflowFilter>({})

  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)

  const { currentData: riskPipelineStats } =
    useGetProjectRisksProcessingStatusQuery(currentProject?.uuid ?? skipToken, {
      pollingInterval: 2000,
    })

  const {
    data: projectRisks,
    refetch,
    isUninitialized,
    isSuccess,
    isLoading,
  } = useGetProjectRiskQuery(
    currentProject?.uuid
      ? {
          projectUUID: currentProject.uuid,
        }
      : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )

  useEffect(() => {
    if (riskPipelineStats?.count && riskPipelineStats.count > 0) {
      // Risk processing has started, maintain the transitioning state
      setIsTransitioning(true)
      // Clear any existing transition timer
      if (transitionTimer) {
        clearTimeout(transitionTimer)
        setTransitionTimer(null)
      }
    } else if (riskPipelineStats?.count === 0 && isTransitioning) {
      // Risk processing has ended, but we'll delay ending the transitioning state
      const timer = setTimeout(() => {
        setIsTransitioning(false)
        setIsFirstTimeProcessing(false)

        // Only attempt to refetch if:
        // 1. We have a valid project UUID
        // 2. The query has been initialized (not in uninitialized state)
        // 3. The query is not currently loading
        // 4. The query has successfully completed at least once
        if (
          currentProject?.uuid &&
          !isUninitialized &&
          !isLoading &&
          isSuccess
        ) {
          refetch()
        }
      }, 2000)
      setTransitionTimer(timer)
    }
  }, [
    riskPipelineStats?.count,
    isTransitioning,
    refetch,
    transitionTimer,
    currentProject?.uuid,
    isUninitialized,
    isSuccess,
    isLoading,
  ])

  // Ensure all timers are cleaned up.
  useEffect(() => {
    return () => {
      if (transitionTimer) clearTimeout(transitionTimer)
    }
  }, [transitionTimer])

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])

  const [searchParams] = useSearchParams()
  const commentUUID = searchParams.get('comment')

  const selectedCategory = useSelector(selectSelectedCategoryForRiskList)
  const selectedRanking = useSelector(selectSelectedRankingForRiskList)
  const selectedRiskList = useSelector(selectSelectedRiskList)
  const selectedDefaultPositionFilter = useSelector(
    selectSelectedDefaultPositionForRiskList
  )
  const selectedStatusFilter = useSelector(selectSelectedStatusFilter)
  const selectedSort = useSelector(selectSelectedSort)
  const selectedConflictsFilter = useSelector(selectSelectedConflictsFilter)
  useEffect(() => {
    if (!commentUUID) {
      return
    }
    setFilter((f) => {
      return { ...f, comment_uuid: commentUUID }
    })
  }, [commentUUID])

  const filteredRisks = useMemo(() => {
    let risks: ProjectRisk[] = []
    if (!projectRisks) {
      return []
    }
    if (!selectedDocuments?.length) {
      risks = projectRisks
    } else {
      risks = projectRisks?.reduce<ProjectRisk[]>((acc, cur) => {
        const doc_uuids = cur.sources?.map((c) => c.document_segment.document)
        const relevantDocUUIDs = selectedDocuments
          ?.filter((d) => doc_uuids?.includes(d.uuid))
          .map((d) => d.uuid)
        if (relevantDocUUIDs?.length) {
          const filteredSources = cur.sources?.filter((s) =>
            relevantDocUUIDs.includes(
              s.document_segment.document?.toString() ?? ''
            )
          )
          const filteredRisk = {
            ...cur,
            sources: filteredSources,
          }
          return [...acc, filteredRisk]
        }
        return acc
      }, [])
    }

    return risks
  }, [projectRisks, selectedDocuments])

  const risksToDisplay = useMemo(() => {
    if (filter?.comment_uuid) {
      return filteredRisks?.filter((r) => {
        return r?.comments?.some((c) => c?.id === filter.comment_uuid)
      })
    }
    let risks = filteredRisks
    if (selectedRiskList) {
      risks = risks?.filter((r) => {
        const riskListIds = r.risk_lists?.map((rl) => rl.risk_list_template)
        return riskListIds?.includes(selectedRiskList?.id)
      })
    }
    if (selectedStatusFilter) {
      risks = risks?.filter((r) => {
        return r?.status === parseInt(selectedStatusFilter)
      })
    }
    if (selectedCategory !== '' && selectedCategory !== null) {
      risks = risks?.filter((r) => {
        if (selectedCategory === 'null') {
          return r?.risk_category === null
        }
        return r?.risk_category?.id === selectedCategory
      })
    }
    if (riskListSearchQuery) {
      risks = risks?.filter((r) => {
        return r?.risk_name
          ?.toLowerCase()
          .includes(riskListSearchQuery.toLowerCase())
      })
    }
    if (selectedRanking) {
      risks = risks?.filter((r) => {
        return r?.risk_ranking === parseInt(selectedRanking)
      })
    }
    if (selectedDefaultPositionFilter) {
      risks = risks?.filter((r) => {
        return (
          (r?.default_position_met === true &&
            selectedDefaultPositionFilter === 'MET') ||
          (r?.default_position_met === false &&
            selectedDefaultPositionFilter === 'NOT_MET') ||
          (r?.default_position_met === null &&
            r.default_position_explanation &&
            selectedDefaultPositionFilter === 'INDETERMINATE')
        )
      })
    }
    if (selectedConflictsFilter) {
      risks = risks?.filter((r) => {
        return r?.has_conflicts === (selectedConflictsFilter === 'true')
      })
    }

    return risks
  }, [
    selectedRiskList,
    riskListSearchQuery,
    filter,
    filteredRisks,
    selectedCategory,
    selectedRanking,
    selectedDefaultPositionFilter,
    selectedStatusFilter,
    selectedConflictsFilter,
  ])

  const groupedRisks = useMemo(() => {
    const grouped = groupBy(
      risksToDisplay,
      (risk) => risk.risk_category?.name || 'No Section'
    )

    const sortedCategories = Object.keys(grouped).sort((a, b) =>
      a.localeCompare(b)
    )

    const sortRisks = (risks: ProjectRisk[], sortType: string) => {
      const risksCopy = [...risks]
      switch (sortType) {
        case 'AZ':
          return risksCopy.sort(
            (a, b) => a.risk_name?.localeCompare(b.risk_name ?? '') ?? 0
          )
        case 'ZA':
          return risksCopy.sort(
            (a, b) => b.risk_name?.localeCompare(a.risk_name ?? '') ?? 0
          )
        case 'HIGH_TO_LOW':
          return risksCopy.sort(
            (a, b) => (b.risk_ranking || 0) - (a.risk_ranking || 0)
          )
        case 'LOW_TO_HIGH':
          return risksCopy.sort(
            (a, b) => (a.risk_ranking || 0) - (b.risk_ranking || 0)
          )
        default:
          return risksCopy
      }
    }

    const sortedGrouped = sortedCategories.reduce((acc, category) => {
      acc[category] = sortRisks(grouped[category], selectedSort)
      return acc
    }, {})

    return sortedGrouped
  }, [risksToDisplay, selectedSort])

  const ProjectRisksLoading = () => (
    <div className="flex h-full w-full flex-col items-center justify-center py-4 pr-6">
      <div className="h-full w-full space-y-3">
        {Array.from({ length: 7 }).map((_, index) => (
          <Skeleton
            key={index}
            h="112.55px"
            w="100%"
            radius="md"
            className="!border !border-gray-300"
          />
        ))}
      </div>
    </div>
  )

  return (
    <div className="flex h-full flex-col bg-[#e5e7eb]">
      <div className="mr-1 flex flex-col overflow-x-hidden border-b border-gray-300">
        <div className="w-full space-y-2 p-4">
          {supplementaryConditionsAreProcessing && (
            <RiskAnalysingBecauseSCsBanner />
          )}
          <RiskListHeader />
        </div>
      </div>
      <div className="w-full">
        <RiskListTemplateBanner />
      </div>
      <div className="scrollbar-default scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500 flex-1 overflow-y-auto overflow-x-hidden">
        <div className="w-full space-y-2 px-4 py-2 pr-5">
          {projectRisks === undefined ? (
            <ProjectRisksLoading />
          ) : (
            <div className="mb-24 flex flex-col space-y-6">
              {Object.keys(groupedRisks).length === 0 ? (
                <div className="flex h-32 items-center justify-center text-gray-500">
                  No risks match these filters
                </div>
              ) : (
                Object.entries(groupedRisks).map(([category, risks]) => (
                  <div key={category} className="space-y-3">
                    <div className="mb-5 mt-2 flex items-center justify-between text-gray-600">
                      <div className="flex flex-grow items-center">
                        <MantineTooltip
                          label={`See all ${category ?? ''} related risks`}
                          position="right"
                          offset={10}
                          disabled={!isCategoryValid(category)}
                        >
                          <Text
                            size="md"
                            fw={500}
                            ml="sm"
                            inherit
                            className={
                              isCategoryValid(category)
                                ? 'cursor-pointer transition-colors hover:text-blue-500 hover:underline'
                                : ''
                            }
                            onClick={() => {
                              if (isCategoryValid(category)) {
                                dispatch(
                                  setSelectedCategoryFilter(
                                    risks?.[0]?.risk_category?.id ?? null
                                  )
                                )
                              }
                            }}
                          >
                            {category}
                          </Text>
                        </MantineTooltip>
                        <div className="mx-4 flex-grow">
                          <hr className="border-t border-gray-300" />
                        </div>
                      </div>
                    </div>
                    {Array.isArray(risks) &&
                      risks.map((risk) => (
                        <RiskReviewCard
                          key={risk.id}
                          documents={documents ?? []}
                          data-testid={risk.id}
                          projectRisk={risk}
                        />
                      ))}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default RiskReviewPage
