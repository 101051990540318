/**
 * Builds query parameters from an object.
 * @param {Object} queryParamsObject - The object containing the query parameters.
 * @returns {URLSearchParams} - The URLSearchParams object containing the built query parameters.
 */
export function buildQueryParams(queryParamsObject) {
  // Create a new URLSearchParams object to store the query parameters
  const queryParams = new URLSearchParams()

  // Iterate over each key in the queryParamsObject
  for (const key in queryParamsObject) {
    // Check if the value is not undefined, thereby dropping any undefined values
    if (queryParamsObject[key] !== undefined) {
      // Check if the value is an array
      if (Array.isArray(queryParamsObject[key])) {
        // If it is an array, iterate over each value and append it to the URLSearchParams object
        queryParamsObject[key].forEach((value) => {
          queryParams.append(key, value.toString())
        })
      } else {
        // If it is not an array, append the value to the URLSearchParams object
        queryParams.append(key, queryParamsObject[key].toString())
      }
    }
  }

  // Return the URLSearchParams object containing the built query parameters
  return queryParams
}
