import React, { useCallback, useMemo, useState } from 'react'
import {
  ProjectRisk,
  ProjectRiskComment,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import { RiskReviewSource } from './risk-review-source'
import RiskReviewAnswerTextCitation from './risk-review-answer-text-citation'
import { InTextCitation } from '../../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'
import RiskReviewCardComment from './risk-review-card-comment'
import {
  BriefcaseIcon,
  ChartPieIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  PresentationChartLineIcon,
  SparklesIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  setModal,
} from '../../../../redux/application-slice'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import {
  Divider,
  HoverCard,
  Popover,
  Skeleton,
  Text,
  Tooltip,
  CopyButton,
  ActionIcon,
  Spoiler,
} from '@mantine/core'
import clsx from 'clsx'
import { useTabWidth } from '../../../../hooks/use-tab-width'
import usePillData, { GeneratedPill } from './risk-review-pill'
import { useGetUserProfileQuery } from '../../../../redux/api-slice'
import SuggestedRevisionsDropdown from './suggested-revisions-dropdown'
import { useGetSuggestedRevisionsQuery } from '../../../../redux/api/api-revisions-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGenerateRiskRFIMutation } from '../../../../redux/api/project-risk-api-slice'

interface RiskReviewCardProps {
  projectRisk: ProjectRisk
  documents: ProjectDocumentMetadata[]
}

interface GroupedEndIndex {
  [index: number]: InTextCitation[]
}

const BoldText: React.FC<{ text: string }> = ({ text }) => (
  <>
    {text.split(/(\*\*.*?\*\*)/).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return (
          <span key={index} className="font-semibold">
            {part.slice(2, -2)}
          </span>
        )
      }
      return part
    })}
  </>
)

interface RiskTitleRowProps {
  projectRisk: ProjectRisk
}

const RiskTitleRow: React.FC<RiskTitleRowProps> = ({ projectRisk }) => {
  const textAboveTheFold = projectRisk?.risk_name
  const preprocessDefinitionText = (text: string | undefined): string[] => {
    if (!text) return []
    return text.split('\\n\\n')
  }
  const processedTextAboveTheFold = preprocessDefinitionText(textAboveTheFold)

  return (
    <div className="flex w-full min-w-0 flex-col items-start">
      <Text size="lg">
        {processedTextAboveTheFold.map((text, index) => (
          <React.Fragment key={index}>
            {text}
            {index < processedTextAboveTheFold.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </React.Fragment>
        ))}
      </Text>
      {projectRisk?.risk_lists && projectRisk.risk_lists.length > 0 && (
        <Text size="xs" c="dimmed" mt={4} className="max-w-full truncate">
          {projectRisk.risk_lists.map((list) => list.name).join(' • ')}
        </Text>
      )}
    </div>
  )
}

interface AttributeRowProps {
  projectRisk: ProjectRisk
  riskImportance: number | null
  riskPriority: number | null
  setRiskPriority: React.Dispatch<React.SetStateAction<number | null>>
  setRiskImportance: React.Dispatch<React.SetStateAction<number | null>>
}

const AttributeRow: React.FC<AttributeRowProps> = ({
  projectRisk,
  riskImportance,
  riskPriority,
  setRiskPriority,
  setRiskImportance,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const pillData = usePillData(
    currentProject,
    projectRisk,
    setRiskPriority,
    setRiskImportance
  )

  const ApprovedPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.approved} clickable={clickable} />
  )
  const NotApprovedPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.notApproved} clickable={clickable} />
  )
  const NeedsReviewPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.needsReview} clickable={clickable} />
  )
  const HighPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.high} clickable={clickable} />
  )
  const MediumPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.medium} clickable={clickable} />
  )
  const LowPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.low} clickable={clickable} />
  )
  const CommentsPill = ({
    number,
    clickable,
  }: {
    number: number
    clickable: boolean
  }) => (
    <GeneratedPill
      {...pillData.comments}
      number={number}
      clickable={clickable}
    />
  )
  const NotSetPill = ({
    type,
    clickable,
  }: {
    type: string
    clickable: boolean
  }) => (
    <GeneratedPill
      {...pillData.notSet}
      title={`No ${type} Set`}
      clickable={clickable}
    />
  )

  const DefaultPositionPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.defaultPosition} clickable={clickable} />
  )

  const ConflictsPill = ({ clickable }: { clickable: boolean }) =>
    projectRisk?.has_conflicts ? (
      <GeneratedPill {...pillData.conflicts} clickable={clickable} />
    ) : null

  const DefinitionsPill = ({ clickable }: { clickable: boolean }) => (
    <GeneratedPill {...pillData.definitions} clickable={clickable} />
  )

  const priorityPillsForTargetDisplay = {
    1: <ApprovedPill clickable={false} />,
    3: <NeedsReviewPill clickable={false} />,
    2: <NotApprovedPill clickable={false} />,
    0: <NotSetPill clickable={false} type="Priority" />,
  }

  const importancePillsForTargetDisplay = {
    3: <HighPill clickable={false} />,
    2: <MediumPill clickable={false} />,
    1: <LowPill clickable={false} />,
    0: <NotSetPill clickable={false} type="Importance" />,
  }

  const preprocessDefinitionText = (text: string | undefined): string[] => {
    if (!text) return []
    return text.split('\\n\\n')
  }

  const preProcessedDefinitionText = preprocessDefinitionText(
    projectRisk?.risk_description
  )

  return projectRisk.processing_status === 1 ? (
    <div className="flex justify-between">
      <div className="flex flex-wrap gap-1">
        <HoverCard
          withArrow
          arrowSize={16}
          width={140}
          shadow="md"
          openDelay={400}
        >
          <HoverCard.Target>
            <div data-testid="importance-selector-pill">
              {importancePillsForTargetDisplay[riskImportance ?? 0]}
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm" ta="center" fw="500">
              Importance
            </Text>
            <Divider my="8px" />
            <div className="flex flex-col items-center space-y-2">
              {riskImportance !== 3 ? <HighPill clickable={true} /> : null}
              {riskImportance !== 2 ? <MediumPill clickable={true} /> : null}
              {riskImportance !== 1 ? <LowPill clickable={true} /> : null}
            </div>
          </HoverCard.Dropdown>
        </HoverCard>

        <HoverCard
          withArrow
          arrowSize={16}
          width={180}
          shadow="md"
          openDelay={400}
        >
          <HoverCard.Target>
            <div data-testid="priority-selector-pill">
              {priorityPillsForTargetDisplay[riskPriority ?? 0]}
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm" ta="center" fw="500">
              Priority
            </Text>
            <Divider my="8px" />
            <div className="flex flex-col items-center space-y-2">
              {riskPriority !== 1 ? <ApprovedPill clickable={true} /> : null}
              {riskPriority !== 3 ? <NeedsReviewPill clickable={true} /> : null}
              {riskPriority !== 2 ? <NotApprovedPill clickable={true} /> : null}
            </div>
          </HoverCard.Dropdown>
        </HoverCard>
        <ConflictsPill clickable={false} />
        {projectRisk?.default_position_explanation ? (
          <HoverCard withArrow arrowSize={16} width={450} shadow="md">
            <HoverCard.Target>
              <div data-testid="default-position-selector-pill">
                <DefaultPositionPill clickable={false} />
              </div>
            </HoverCard.Target>
          </HoverCard>
        ) : null}
      </div>
      <div className="flex flex-wrap justify-start gap-1">
        {projectRisk?.comments?.length ? (
          <HoverCard
            withArrow
            arrowSize={16}
            width={400}
            shadow="md"
            openDelay={400}
          >
            <HoverCard.Target>
              <div data-testid="comments-selector-pill">
                <CommentsPill
                  number={projectRisk?.comments?.length}
                  clickable={false}
                />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm" ta="center" fw="500">
                All Comments
              </Text>
              <Divider my="8px" />
              <div className="max-h-[250px] overflow-y-auto p-2">
                {projectRisk.comments.map((comment, index) => (
                  <div key={index} className="mb-2 last:mb-0">
                    <div className="flex items-center justify-start">
                      <Text size="sm" fw="500" className="mb-1">
                        {`${comment.user?.first_name} ${comment.user?.last_name}`}
                      </Text>
                      <span className="ml-1 text-sm text-gray-400">
                        {'• '}
                        {new Date(comment?.date_created ?? '')
                          .toDateString()
                          .split(' ')
                          .slice(1)
                          .join(' ')}
                      </span>
                    </div>
                    <Text size="sm" className="pl-2">
                      {comment.comment}
                    </Text>

                    {index < (projectRisk?.comments?.length ?? 1) - 1 && (
                      <Divider my="8px" />
                    )}
                  </div>
                ))}
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : null}
        {projectRisk?.risk_description ? (
          <HoverCard withArrow arrowSize={16} width={450} shadow="md">
            <HoverCard.Target>
              <div data-testid="definition-selector-pill">
                <DefinitionsPill clickable={false} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <div className="flex w-full items-center justify-center">
                <Text size="sm" fw="500">
                  Risk Definition
                </Text>
              </div>
              <Divider my="8px" />
              <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                <Text size="md" px="4px">
                  {preProcessedDefinitionText.map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      {index < preProcessedDefinitionText.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </Text>
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="mb-3 flex flex-row items-start space-x-2">
      {[...Array(3)].map((_, i) => (
        <Skeleton
          key={i}
          height={28}
          width={100}
          styles={() => ({ root: { borderRadius: '16px' } })}
        />
      ))}
    </div>
  )
}

interface AnalysisLabel {
  errorLabel: string
  label: string
}
interface AnalysisTitleTextProps {
  label: AnalysisLabel
  isError?: boolean
  loading?: boolean
  onPositiveFeedback: () => void
  onNegativeFeedback: () => void
  icon?: React.ReactNode
}

const AnalysisTitleText: React.FC<AnalysisTitleTextProps> = ({
  label,
  loading,
  isError,
  onPositiveFeedback,
  onNegativeFeedback,
  icon,
}) => {
  return (
    <div data-testid="analysis-title-text" className="flex items-center">
      <div
        className={clsx('flex items-center gap-2', {
          'text-yellow-600': loading,
          'text-gray-800': !loading,
        })}
      >
        {icon ? <div className="mt-1 text-gray-400">{icon}</div> : null}
        <div className="font-semibold">
          {isError ? label.errorLabel : label.label}
        </div>
      </div>

      <div className="flex flex-wrap gap-2 text-sm">
        <button
          onClick={onPositiveFeedback}
          className="ml-4 flex h-6 w-6 flex-1 items-center justify-center rounded-lg bg-gray-50 hover:bg-green-200"
        >
          <svg
            className="h-4 w-4 text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 11c.889-.086 1.416-.543 2.156-1.057a22.323 22.323 0 0 0 3.958-5.084 1.6 1.6 0 0 1 .582-.628 1.549 1.549 0 0 1 1.466-.087c.205.095.388.233.537.406a1.64 1.64 0 0 1 .384 1.279l-1.388 4.114M7 11H4v6.5A1.5 1.5 0 0 0 5.5 19v0A1.5 1.5 0 0 0 7 17.5V11Zm6.5-1h4.915c.286 0 .372.014.626.15.254.135.472.332.637.572a1.874 1.874 0 0 1 .215 1.673l-2.098 6.4C17.538 19.52 17.368 20 16.12 20c-2.303 0-4.79-.943-6.67-1.475"
            />
          </svg>
        </button>
        <button
          onClick={onNegativeFeedback}
          className="flex h-6 w-6 flex-1 items-center justify-center rounded-lg bg-gray-50 hover:bg-red-200"
        >
          <svg
            className="h-4 w-4 text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 13c-.889.086-1.416.543-2.156 1.057a22.322 22.322 0 0 0-3.958 5.084 1.6 1.6 0 0 1-.582.628 1.549 1.549 0 0 1-1.466.087 1.587 1.587 0 0 1-.537-.406 1.666 1.666 0 0 1-.384-1.279l1.389-4.114M17 13h3V6.5A1.5 1.5 0 0 0 18.5 5v0A1.5 1.5 0 0 0 17 6.5V13Zm-6.5 1H5.585c-.286 0-.372-.014-.626-.15a1.797 1.797 0 0 1-.637-.572 1.873 1.873 0 0 1-.215-1.673l2.098-6.4C6.462 4.48 6.632 4 7.88 4c2.302 0 4.79.943 6.67 1.475"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

interface SourcesDropdownProps {
  projectRiskSources: InTextCitation[]
  documents: ProjectDocumentMetadata[]
  sourcesExpanded: boolean
  onClickSources: (e: React.MouseEvent<HTMLDivElement>) => void
}

const SourcesDropdown: React.FC<SourcesDropdownProps> = ({
  projectRiskSources,
  documents,
  sourcesExpanded,
  onClickSources,
}) => {
  const { tabWidth } = useTabWidth()

  const cardHoverClasses = clsx('flex items-center justify-start', {
    'cursor-pointer': projectRiskSources && projectRiskSources.length > 0,
    '!cursor-default': !projectRiskSources || projectRiskSources.length <= 0,
  })

  return (
    <Popover position="bottom-end" offset={12} shadow="lg">
      <Popover.Target>
        <div
          onClick={
            projectRiskSources && projectRiskSources.length > 0
              ? onClickSources
              : undefined
          }
          className={cardHoverClasses}
        >
          <DocumentCheckIcon className="mr-1 h-5 w-5 text-gray-400" />
          <span
            className={clsx('text-md', {
              'text-gray-400':
                projectRiskSources && projectRiskSources.length <= 0,
            })}
          >
            {projectRiskSources && projectRiskSources.length > 0
              ? 'Sources'
              : 'No Sources'}
          </span>
          {projectRiskSources &&
            projectRiskSources.length > 0 &&
            (sourcesExpanded ? (
              <ChevronUpIcon className="ml-1 h-4 w-4" />
            ) : (
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            ))}
        </div>
      </Popover.Target>
      <Popover.Dropdown
        className={`z-50 mt-3 rounded-xl border border-gray-200 bg-white p-2 hover:border-yellow-500`}
      >
        <div style={{ width: tabWidth - 280 }}>
          <div className="max-h-[450px] overflow-y-auto">
            {projectRiskSources?.map((citation, index) => (
              <div key={`source_${index}`} className="mb-2 last:mb-0">
                <RiskReviewSource
                  isExpanded={true}
                  sourceIndex={index}
                  documents={documents}
                  citation={citation}
                  citations={projectRiskSources ?? []}
                />
              </div>
            ))}
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}

const CardExpandChevron = ({
  isProcessing,
  isExpanded,
}: {
  isProcessing: boolean
  isExpanded: boolean
}) => {
  return isProcessing ? (
    <Tooltip
      label={
        <>
          This risk is currently being analyzed. <br />
          Please check back soon.
        </>
      }
      multiline
      w="260px"
      position="left"
    >
      <ClockIcon className="h-8 w-8 text-gray-600" />
    </Tooltip>
  ) : (
    <Tooltip
      label={isExpanded ? 'Hide Details' : 'View Risk Analysis Details'}
      position="right"
      openDelay={400}
    >
      <div className="relative cursor-pointer p-1">
        {isExpanded ? (
          <ChevronUpIcon className="relative h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-black" />
        ) : (
          <ChevronDownIcon className="relative h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-black" />
        )}
      </div>
    </Tooltip>
  )
}

const RiskReviewCard: React.FC<RiskReviewCardProps> = ({
  projectRisk,
  documents,
}) => {
  const [commentSelected, setCommentSelected] =
    useState<ProjectRiskComment | null>(null)
  const [sourcesExpanded, setSourcesExpanded] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const currentProject = useSelector(selectCurrentProject)
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const isProcessing = useMemo(
    () => projectRisk.processing_status === 0,
    [projectRisk.processing_status]
  )
  const { data: user } = useGetUserProfileQuery(undefined)
  const { data: suggestedRevisionsResponse } = useGetSuggestedRevisionsQuery(
    projectRisk?.id ?? skipToken
  )

  // 0 = No, 1=Low, 2=Medium, 3=High
  const [riskImportance, setRiskImportance] = useState<number | null>(
    projectRisk?.risk_ranking ?? 0
  )
  // 0 = No, 1=Approved, 2=Not Approved, 3=Needs Review
  const [riskPriority, setRiskPriority] = useState<number | null>(
    projectRisk?.status ?? 0
  )

  const pillData = usePillData(
    currentProject,
    projectRisk,
    setRiskPriority,
    setRiskImportance
  )

  const Icon = pillData.defaultPositionIcon

  const renderAnswerText = useCallback(
    (answerText: string, projectRiskSources: InTextCitation[]) => {
      if (!projectRiskSources || !answerText) {
        return null
      }
      const sortedCitations = projectRiskSources
        .filter(
          (citation) =>
            citation.start_index !== null && citation.end_index !== null
        )
        .sort((a, b) => (a?.start_index ?? 0) - (b?.start_index ?? 0))
      const answerTexts: JSX.Element[] = []
      let lastEndIndex = 0
      const groupedEndIndexes = sortedCitations.reduce<GroupedEndIndex>(
        (acc, citation) => {
          if (acc[citation.end_index ?? 0]) {
            acc[citation.end_index ?? 0].push(citation)
          } else {
            acc[citation.end_index ?? 0] = [citation]
          }
          return acc
        },
        {}
      )
      let total = 0
      for (const groupedIndex of Object.keys(groupedEndIndexes)) {
        const endIndex = parseInt(groupedIndex)
        const citations = groupedEndIndexes[endIndex]
        const textSlice = answerText?.slice(lastEndIndex, endIndex)
        answerTexts.push(
          <span key={textSlice}>
            <BoldText text={textSlice ?? ''} />
          </span>
        )
        for (const citation of citations) {
          answerTexts.push(
            <RiskReviewAnswerTextCitation
              key={`source_${citation.document_segment.quads?.[0].x1}${citation.document_segment.quads?.[0].y1}_${citation.start_index}_${citation.end_index}`}
              index={total}
              citation={citation}
              citations={citations}
            />
          )
          total += 1
        }
        lastEndIndex = endIndex
      }
      const finalTextSlice = answerText?.slice(lastEndIndex)
      answerTexts.push(
        <span key={finalTextSlice}>
          <BoldText text={finalTextSlice ?? ''} />
        </span>
      )
      return answerTexts
    },
    []
  )

  const onClickExpand = useCallback(() => {
    if (isProcessing) {
      return
    }
    setIsExpanded((state) => !state)
    posthog?.capture(POSTHOG.risk_review_risk_expanded, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
      has_default_position: projectRisk?.default_position_explanation
        ? true
        : false,
    })
  }, [projectRisk, currentProject, posthog, isProcessing])

  const onClickSources = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setSourcesExpanded((s) => !s)
  }, [])

  const onPositiveFeedbackDefaultPosition = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'DEFAULT_POSITION',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: true,
          project_risk: projectRisk.id,
          feedback_type: 'DEFAULT_POSITION',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onNegativeFeedbackDefaultPosition = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'DEFAULT_POSITION',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: false,
          project_risk: projectRisk.id,
          feedback_type: 'DEFAULT_POSITION',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onPositiveFeedbackRiskRanking = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'RISK_RANKING',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: true,
          project_risk: projectRisk.id,
          feedback_type: 'RISK_RANKING',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onNegativeFeedbackRiskRanking = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'RISK_RANKING',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: false,
          project_risk: projectRisk.id,
          feedback_type: 'RISK_RANKING',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onPositiveFeedbackRiskReviewConflicts = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'RISK_CONFLICTS',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: true,
          project_risk: projectRisk.id,
          feedback_type: 'RISK_CONFLICTS',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onNegativeFeedbackRiskReviewConflicts = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
      feedback_type: 'RISK_CONFLICTS',
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: {
          positive: false,
          project_risk: projectRisk.id,
          feedback_type: 'RISK_CONFLICTS',
        },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const hasStrikethroughText = useMemo(() => {
    return projectRisk?.sources?.some(
      (source) => source.document_segment?.metadata?.has_strikethrough_text
    )
  }, [projectRisk?.sources])

  const randomPercentage = useMemo(() => {
    return Math.floor(Math.random() * 100)
  }, [])

  const [rfiText, setRfiText] = useState<string | null>(null)
  const [isGeneratingRFI, setIsGeneratingRFI] = useState(false)
  const [isRFIVisible, setIsRFIVisible] = useState(false)
  const [generateRFI] = useGenerateRiskRFIMutation()

  const handleCopyClick = useCallback(
    (copy: () => void) => {
      copy()
      posthog?.capture(POSTHOG.copied_generated_rfi, {
        project_uuid: currentProject?.uuid,
        project_risk_id: projectRisk.id,
      })
    },
    [currentProject?.uuid, projectRisk.id, posthog]
  )

  const handleRFIToggle = useCallback(async () => {
    if (rfiText) {
      setIsRFIVisible(!isRFIVisible)
      return
    }

    if (!projectRisk?.id) return
    setIsGeneratingRFI(true)
    posthog?.capture(POSTHOG.clicked_generate_rfi, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
    })
    try {
      const response = await generateRFI(projectRisk.id).unwrap()
      setRfiText(response.rfi_text)
      setIsRFIVisible(true)
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    } finally {
      setIsGeneratingRFI(false)
    }
  }, [
    generateRFI,
    projectRisk?.id,
    rfiText,
    isRFIVisible,
    posthog,
    currentProject?.uuid,
  ])

  return (
    <div className={`rounded-md bg-white`}>
      <div
        tabIndex={0}
        role="button"
        className={`flex w-full items-center overflow-hidden px-6 py-4 ${isProcessing ? 'animate-pulse bg-white' : ''}`}
        onClick={onClickExpand}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClickExpand()
          }
        }}
      >
        <div className="flex min-w-0 flex-grow flex-col gap-2">
          <RiskTitleRow projectRisk={projectRisk} />
          <AttributeRow
            projectRisk={projectRisk}
            riskImportance={riskImportance}
            riskPriority={riskPriority}
            setRiskPriority={setRiskPriority}
            setRiskImportance={setRiskImportance}
          />
        </div>
        <CardExpandChevron
          isProcessing={isProcessing}
          isExpanded={isExpanded}
        />
      </div>
      {isExpanded && (
        <div className="text-md w-full cursor-default space-y-2 overflow-hidden bg-white px-4">
          <Divider color="#dadada" size="sm" w="100%" />
          <div className="space-y-4 px-2 py-4">
            <div className="flex justify-between gap-2">
              <div className="mt-0.5 flex flex-col justify-start">
                <PresentationChartLineIcon className="h-5 w-5 text-green-400" />
              </div>
              <div className="flex-1">
                <div className="mb-2 flex items-start justify-between">
                  <AnalysisTitleText
                    loading={!projectRisk?.answer_text}
                    label={{
                      errorLabel: 'No Analysis Found',
                      label: 'Analysis',
                    }}
                    onPositiveFeedback={onPositiveFeedbackRiskRanking}
                    onNegativeFeedback={onNegativeFeedbackRiskRanking}
                  />
                  <div className="flex items-center gap-2">
                    {user?.feature_flags?.editor_mode && (
                      <Tooltip
                        label={`${randomPercentage}% of customers accept this risk`}
                        position="top"
                        openDelay={400}
                      >
                        <div
                          className={clsx(
                            'flex items-center gap-1 px-2 py-1 text-sm font-semibold text-gray-500',
                            randomPercentage < 35
                              ? 'text-red-400'
                              : randomPercentage > 65
                                ? 'text-green-400'
                                : 'text-orange-400'
                          )}
                        >
                          <ChartPieIcon className="h-5 w-5" />
                          <div>{randomPercentage}%</div>
                        </div>
                      </Tooltip>
                    )}
                    <SourcesDropdown
                      projectRiskSources={projectRisk?.sources ?? []}
                      documents={documents}
                      sourcesExpanded={sourcesExpanded}
                      onClickSources={onClickSources}
                    />
                  </div>
                </div>
                <div className="mb-2 whitespace-pre-wrap">
                  {renderAnswerText(
                    projectRisk?.answer_text ?? '',
                    projectRisk?.sources ?? []
                  ) ||
                    "We couldn't find an analysis associated with this risk. This is likely an issue with Provision. Please contact support@getprovision.co"}
                </div>
                {hasStrikethroughText ? (
                  <div className="flex items-center justify-start gap-1 text-gray-400">
                    <ExclamationTriangleIcon className="mb-0.5 h-3 w-3" />
                    <Text size="xs">
                      Some sources in this answer contain struck-through text.
                      Please double-check risk review sources.
                    </Text>
                  </div>
                ) : null}
              </div>
            </div>

            {projectRisk?.default_position_explanation ? (
              <div className="flex gap-2">
                <div className="mt-0.5 flex flex-col justify-start">
                  <BriefcaseIcon className="text-brown-400 h-5 w-5" />
                </div>
                <div className="flex-1">
                  <div className="mb-3 flex items-center justify-between">
                    <AnalysisTitleText
                      loading={!projectRisk?.answer_text}
                      label={{
                        errorLabel: 'Error loading default position',
                        label: 'Default Position',
                      }}
                      onPositiveFeedback={onPositiveFeedbackDefaultPosition}
                      onNegativeFeedback={onNegativeFeedbackDefaultPosition}
                    />
                    <div>
                      <SourcesDropdown
                        projectRiskSources={
                          projectRisk?.default_position_explanation_sources ??
                          []
                        }
                        documents={documents}
                        sourcesExpanded={sourcesExpanded}
                        onClickSources={onClickSources}
                      />
                    </div>
                  </div>
                  <div className="text-md mb-1 flex items-center font-semibold text-gray-900">
                    {Icon ? <Icon className="h-6 w-6" /> : null}
                    <div>{pillData.defaultPositionExplanation}</div>
                  </div>
                  <div className="mb-4">
                    <div className="whitespace-pre-wrap">
                      {renderAnswerText(
                        projectRisk?.default_position_explanation ?? '',
                        projectRisk?.default_position_explanation_sources ?? []
                      ) ||
                        "We couldn't find a default position associated with this risk. This is likely an issue with Provision. Please contact support@getprovision.co"}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {user?.feature_flags?.risk_review_conflicts &&
            projectRisk?.has_conflicts &&
            projectRisk?.conflicts_text ? (
              <div className="flex gap-2">
                <div className="mt-0.5 flex flex-col justify-start">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" />
                </div>
                <div>
                  <div className="mb-3 flex items-center justify-between">
                    <AnalysisTitleText
                      loading={!projectRisk?.conflicts_text}
                      label={{
                        errorLabel: 'Error loading conflicts',
                        label: 'Potential Conflicts',
                      }}
                      onPositiveFeedback={onPositiveFeedbackRiskReviewConflicts}
                      onNegativeFeedback={onNegativeFeedbackRiskReviewConflicts}
                    />
                    <SourcesDropdown
                      projectRiskSources={projectRisk?.conflicts_sources ?? []}
                      documents={documents}
                      sourcesExpanded={sourcesExpanded}
                      onClickSources={onClickSources}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="whitespace-pre-wrap">
                      {renderAnswerText(
                        projectRisk?.conflicts_text ?? '',
                        projectRisk?.conflicts_sources ?? []
                      ) ||
                        "We couldn't find any conflicts associated with this risk. This is likely an issue with Provision. Please contact support@getprovision.co"}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {user?.feature_flags?.suggested_revisions &&
              projectRisk?.default_position_met === false &&
              (suggestedRevisionsResponse?.suggested_revisions_count ?? 0) >
                0 && (
                <div className="mx-8 mb-4 flex flex-col gap-2">
                  <div className="relative isolate flex justify-between gap-2 overflow-hidden rounded-lg border border-gray-200 bg-white p-3 py-4 shadow-sm transition-colors">
                    <div
                      aria-hidden="true"
                      className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    >
                      <div
                        style={{
                          clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                        }}
                        className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3b82f6] to-[#6366f1] opacity-20"
                      />
                    </div>
                    <div
                      aria-hidden="true"
                      className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    >
                      <div
                        style={{
                          clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                        }}
                        className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3b82f6] to-[#6366f1] opacity-30"
                      />
                    </div>
                    <div className="relative z-10 flex w-full flex-grow flex-col gap-1">
                      <div className="mb-0.5 flex items-center gap-1.5">
                        <SparklesIcon className="h-3.5 w-3.5 text-indigo-600" />
                        <Text size="sm" fw={500}>
                          Suggested Revisions
                        </Text>
                      </div>
                      <SuggestedRevisionsDropdown
                        projectRiskId={projectRisk.id}
                        onClose={() => {}}
                        suggestedRevisionsResponse={suggestedRevisionsResponse}
                      />
                    </div>
                  </div>
                </div>
              )}
            {hasStrikethroughText ? (
              <div className="flex items-center justify-start px-8 pb-6 pl-12">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <Text c="dimmed" size="sm" className="mb-2">
                  Some sources in this answer contain struck-through text.
                  Please double-check risk review sources.
                </Text>
              </div>
            ) : null}
            {user?.feature_flags?.generate_rfi &&
              projectRisk?.default_position_met === false && (
                <div className="mx-8 mb-4 flex flex-col">
                  <div className="relative isolate flex justify-between gap-2 overflow-hidden rounded-lg border border-gray-200 bg-white p-3 shadow-sm transition-colors">
                    <div
                      aria-hidden="true"
                      className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    >
                      <div
                        style={{
                          clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                        }}
                        className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3b82f6] to-[#6366f1] opacity-20"
                      />
                    </div>
                    <div
                      aria-hidden="true"
                      className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    >
                      <div
                        style={{
                          clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                        }}
                        className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#3b82f6] to-[#6366f1] opacity-30"
                      />
                    </div>
                    <div className="relative z-10 flex w-full flex-grow items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div className="flex items-center gap-1.5">
                          <SparklesIcon className="h-3.5 w-3.5 text-indigo-600" />
                          <Text size="sm" fw={500}>
                            Request for Information (RFI)
                          </Text>
                        </div>
                        {!rfiText && !isGeneratingRFI && (
                          <button
                            onClick={handleRFIToggle}
                            className="flex items-center gap-1.5 rounded-lg bg-indigo-600 px-3 py-1.5 text-sm font-medium text-white shadow-sm transition-colors hover:bg-indigo-600"
                          >
                            Generate
                          </button>
                        )}
                        {isGeneratingRFI && (
                          <div className="flex items-center gap-2 text-gray-500">
                            <ClockIcon className="h-4 w-4 animate-spin" />
                            <Text size="sm">Generating RFI...</Text>
                          </div>
                        )}
                      </div>
                      {rfiText && (
                        <CopyButton value={rfiText} timeout={2000}>
                          {({ copied, copy }) => (
                            <Tooltip
                              label={copied ? 'Copied' : 'Copy to clipboard'}
                            >
                              <ActionIcon
                                onClick={() => handleCopyClick(copy)}
                                className={clsx(
                                  'transition-colors',
                                  copied
                                    ? 'text-green-600'
                                    : 'text-white hover:text-white'
                                )}
                              >
                                <ClipboardDocumentIcon className="h-4 w-4" />
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </CopyButton>
                      )}
                    </div>
                  </div>
                  {rfiText && (
                    <div className="rounded-b-lg border-x border-b border-gray-200 bg-white p-3">
                      <div className="relative">
                        <Spoiler
                          maxHeight={100}
                          showLabel="Show more"
                          hideLabel="Hide"
                          styles={{
                            control: {
                              fontSize: '12px',
                            },
                          }}
                        >
                          <Text
                            size="sm"
                            className="whitespace-pre-wrap text-gray-900"
                          >
                            {rfiText}
                          </Text>
                        </Spoiler>
                      </div>
                    </div>
                  )}
                </div>
              )}
            <div className="flex items-center justify-start gap-1 pl-8">
              <Text c="dimmed" size="sm" className="mb-2">
                Provision can make mistakes. Consider checking important
                information.
              </Text>
            </div>
            <Divider color="#dadada" size="sm" w="100%" />
            <div className="w-full px-2 pt-0">
              <RiskReviewCardComment
                projectRisk={projectRisk}
                commentSelected={commentSelected}
                setCommentSelected={setCommentSelected}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(RiskReviewCard)
