import React, { ReactElement, useState } from 'react'
import clsx from 'clsx'

const BANNER_THEMES = {
  blue: {
    backgroundColor: 'bg-blue-50',
    textColor: 'text-blue-500',
    iconClassName: 'h-5 w-5 text-blue-400',
    borderColor: 'border-blue-300 transition-colors hover:border-blue-600',
  },
  green: {
    backgroundColor: 'bg-green-50',
    textColor: 'text-green-700',
    iconClassName: 'h-5 w-5 text-green-700',
    borderColor: 'border-green-200 transition-colors hover:border-green-600',
  },
  amber: {
    backgroundColor: 'bg-amber-50',
    textColor: 'text-amber-700',
    iconClassName: 'h-5 w-5 text-amber-600',
    borderColor: 'border-amber-300 transition-colors hover:border-amber-600',
  },
}

export interface BannerProps {
  icon?: React.ForwardRefExoticComponent<any>
  message: ReactElement | string
  action?: React.ReactNode
  disableInbuiltSpacing?: boolean
  colour?: keyof typeof BANNER_THEMES
}

export const Banner: React.FC<BannerProps> = ({
  icon: Icon,
  message,
  action,
  disableInbuiltSpacing = false,
  colour = 'blue',
}) => {
  const [hasBeenClosed, setHasBeenClosed] = useState(false)
  const theme = BANNER_THEMES[colour]

  if (hasBeenClosed) {
    return null
  }

  return (
    <div
      className={clsx(
        'my-1 ml-2 mr-4 flex max-w-full items-center border border-gray-300 px-2 py-1 text-sm shadow-sm',
        !disableInbuiltSpacing && 'm-2 mr-4',
        theme.backgroundColor,
        theme.borderColor && ['border', theme.borderColor]
      )}
    >
      {Icon && (
        <div className="mr-1 flex flex-shrink-0 items-center">
          <Icon className={theme.iconClassName} aria-hidden="true" />
        </div>
      )}
      <div className="flex flex-1 items-center md:justify-between">
        <p className={clsx('text-normal font-semibold', theme.textColor)}>
          {message}
        </p>
        <div className="ml-auto flex items-center gap-3 pl-3">
          {action}
          <button
            onClick={() => setHasBeenClosed(true)}
            className={clsx(
              'rounded-md p-1.5 hover:bg-white/20',
              theme.textColor
            )}
          >
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}
