import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import './App.css'
import { useGetUserProfileQuery } from './redux/api-slice'
import Router from './routes/router'

function App() {
  const posthog = usePostHog()
  const { data: profile } = useGetUserProfileQuery()

  useEffect(() => {
    if (profile) {
      if (
        profile.uuid &&
        profile.email &&
        profile.first_name &&
        import.meta.env.VITE_LOGROCKET_ID
      ) {
        LogRocket.identify(profile.uuid, {
          name: profile.first_name,
          email: profile.email,
        })
        LogRocket.getSessionURL((sessionURL) => {
          Sentry.setContext('logrocket', { sessionURL: sessionURL })
        })
      }
      posthog?.identify(profile.uuid, {
        email: profile.email,
        name: profile.first_name,
      })
      if (profile?.organisation) {
        posthog?.group('company', profile?.organisation?.id, {
          name: profile?.organisation?.name,
        })
      }
      Sentry.setUser({
        email: profile.email,
        id: profile.uuid,
      })
      window.$chatwoot?.setUser(profile.uuid, {
        name: profile?.first_name,
        email: profile.email,
        identifier_hash: profile.chatwoot_hmac || '',
      })
    }
  }, [profile, posthog])

  return <Router />
}

export default App
