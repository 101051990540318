import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateRiskEvaluationSourceMutation, useDeleteRiskEvaluationSourceMutation, useGetRiskEvaluationQuery, useGetRiskEvaluationSourcesQuery, useGetRiskEvaluationsQuery, useUpdateRiskEvaluationMutation } from '../../../redux/api/qa-api-slice';
import { selectCurrentDocument, selectCurrentProject } from '../../../redux/application-slice';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { ActionIcon, Button, Divider, LoadingOverlay, Select, Switch, Textarea } from '@mantine/core';
import { selectTextSelected } from '../../../redux/viewer-slice';
import { TrashIcon } from '@heroicons/react/24/outline';

export const QAWorkflowRiskEvaluations = () => {
    const currentProject = useSelector(selectCurrentProject)
    const currentDocument = useSelector(selectCurrentDocument)
    const textSelected = useSelector(selectTextSelected)

    const [currentRiskEvaluationId, setCurrentRiskEvaluationId] = useState<string | null>(null)

    const [isRisk, setIsRisk] = useState<boolean | null>(null)
    const [evaluationComment, setEvaluationComment] = useState<string>('')
    const [isFlaggedForReview, setIsFlaggedForReview] = useState<boolean>(false)

    const [updateRiskEvaluation, { isLoading: isUpdatingRiskEvaluation }] = useUpdateRiskEvaluationMutation()
    const [createRiskEvaluationSource, { isLoading: isCreatingRiskEvaluationSource }] = useCreateRiskEvaluationSourceMutation()
    const [deleteRiskEvaluationSource, { isLoading: isDeletingRiskEvaluationSource }] = useDeleteRiskEvaluationSourceMutation()

    const { data: riskEvaluations, isLoading } = useGetRiskEvaluationsQuery(currentProject?.uuid ?? skipToken);
    const { data: riskEvaluation, isLoading: isLoadingRiskEvaluation } = useGetRiskEvaluationQuery(currentRiskEvaluationId ?? skipToken)
    const { data: riskEvaluationSources, isLoading: isLoadingRiskEvaluationSources } = useGetRiskEvaluationSourcesQuery(currentRiskEvaluationId ?? skipToken)

    const onSave = useCallback(async () => {
        if (!riskEvaluation) {
            return
        }
        await updateRiskEvaluation({
            ...riskEvaluation,
            evaluation_comment: evaluationComment,
            is_risk_identified: isRisk,
            is_flagged: isFlaggedForReview
        })
    }, [riskEvaluation, evaluationComment, isRisk, isFlaggedForReview, updateRiskEvaluation])

    useEffect(() => {
        if (riskEvaluation) {
            setEvaluationComment(riskEvaluation.evaluation_comment ?? '')
            setIsRisk(riskEvaluation.is_risk_identified ?? null)
            setIsFlaggedForReview(riskEvaluation.is_flagged ?? false)
        }
    }, [riskEvaluation])

    const onAddSource = useCallback(async () => {
        if (!riskEvaluation || !textSelected || !currentDocument || !textSelected.text) {
            return
        }
        await createRiskEvaluationSource({
            id: 0, // You can't change the id, so this just satisfies the type checker
            risk_evaluation: riskEvaluation.id,
            document: currentDocument.id,
            quads: textSelected.quads,
            page_number: textSelected.pageNumber,
            text: textSelected.text
        })
    }, [riskEvaluation, currentDocument, textSelected, createRiskEvaluationSource])

    const onRemoveSource = (source_id: number) => {
        if (!source_id) {
            return
        }
        deleteRiskEvaluationSource(source_id.toString())
    }

    const currentRiskEvaluationIndex = useMemo(() => riskEvaluations?.findIndex(e => e.id.toString() === currentRiskEvaluationId) ?? -1, [riskEvaluations, currentRiskEvaluationId])

    const onNext = async () => {
        await onSave()
        setCurrentRiskEvaluationId(riskEvaluations?.[currentRiskEvaluationIndex + 1]?.id.toString() ?? null)
    }

    const onPrevious = async () => {
        await onSave()
        setCurrentRiskEvaluationId(riskEvaluations?.[currentRiskEvaluationIndex - 1]?.id.toString() ?? null)
    }

    const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEvaluationComment(event.target.value)
    }

    const onRiskButtonClick = () => {
        setIsRisk(true)
    }

    const onNotRiskButtonClick = () => {
        setIsRisk(false)
    }

    const onFlagForReviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFlaggedForReview(event.target.checked)
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className='w-full h-full flex flex-col'>
            <div className='p-4 space-y-2 flex-shrink-0'>
                <Select
                    data={riskEvaluations?.map((evaluation) => ({
                        value: evaluation.id.toString(),
                        label: evaluation.risk_name
                    }))}
                    value={currentRiskEvaluationId}
                    onChange={(value) => {
                        setCurrentRiskEvaluationId(value)
                    }}
                />
                <div className='flex justify-between'>
                    <Button variant='subtle' onClick={onPrevious} disabled={currentRiskEvaluationIndex === 0}>Previous</Button>
                    <div>{`${currentRiskEvaluationIndex + 1}/${riskEvaluations?.length || 0}`}</div>
                    <Button
                        variant='subtle'
                        onClick={currentRiskEvaluationIndex === (riskEvaluations?.length ?? 0) - 1 ? onSave : onNext}
                    >
                        {currentRiskEvaluationIndex === (riskEvaluations?.length ?? 0) - 1 ? 'Save' : 'Save & Next'}
                    </Button>
                </div>
            </div>
            <Divider className='flex-shrink-0' />
            {riskEvaluation ? (
                <div className='flex-1 overflow-y-auto'>
                    <LoadingOverlay visible={isUpdatingRiskEvaluation || isLoadingRiskEvaluation} />
                    <div className='p-4 flex flex-col gap-4'>
                        <h2>{riskEvaluation?.risk_name}</h2>
                        <p>{riskEvaluation?.risk_definition}</p>
                    </div>
                    <Divider />
                    <div className='p-4 flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <div>Risk Evaluation</div>
                            <Switch
                                label="Flag for review"
                                checked={isFlaggedForReview}
                                onChange={onFlagForReviewChange}
                            />
                        </div>
                        <div className='flex gap-2'>
                            <Button fullWidth variant={isRisk === true ? 'filled' : 'outline'} onClick={onRiskButtonClick}>Risk</Button>
                            <Button fullWidth variant={isRisk === false ? 'filled' : 'outline'} onClick={onNotRiskButtonClick}>Not a risk</Button>
                        </div>
                        <div>Reasoning</div>
                        <Textarea
                            value={evaluationComment}
                            onChange={onTextAreaChange}
                            autosize
                            placeholder='Write your evaluation answer here'
                        />
                        <div>Sources</div>
                        <Button onClick={onAddSource} loading={isCreatingRiskEvaluationSource}>Add Source</Button>
                        <div className='flex flex-col gap-4'>
                            {isLoadingRiskEvaluationSources || isDeletingRiskEvaluationSource ? <div>Loading...</div> : riskEvaluationSources?.map((source, index) => (
                                <div key={source.id} className='flex justify-between items-center'>
                                    <div className="bg-gray-100 p-2 rounded-md flex flex-grow" key={source.id}>
                                        <div className="flex-grow">{source.text}</div>
                                        <div>{source.page_number}</div>
                                    </div>
                                    <ActionIcon disabled={isDeletingRiskEvaluationSource} color='red' variant='subtle' onClick={() => onRemoveSource(source.id)}>
                                        <TrashIcon className='w-4 h-4' />
                                    </ActionIcon>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex-1 flex justify-center items-center'>No risk evaluation selected</div>
            )}
        </div>
    );
};
