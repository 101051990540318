import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
  setModal,
} from '../../redux/application-slice'
import { Button, TextInput, Tooltip } from '@mantine/core'
import {
  BoltIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline'
import {
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  useGetProjectRiskQuery,
} from '../../redux/api/project-risk-api-slice'
import {
  selectRiskListSearchQuery,
  setRiskListSearchQuery,
} from '../../redux/risks-search-slice'
import { MODAL_TYPES } from '../modals/modal-controller'

export const ActiveDot = ({ active }: { active: boolean }) => (
  <>
    {active ? (
      <Tooltip label="Active Filter Applied">
        <span className="relative mb-4 flex  h-1 w-1 transition-all duration-300">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
          <span className="relative inline-flex h-1 w-1 rounded-full bg-blue-500"></span>
        </span>
      </Tooltip>
    ) : (
      <span className="relative mb-4 flex  h-1 w-1 transition-all duration-300">
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-none opacity-0"></span>
        <span className="relative inline-flex h-1 w-1 rounded-full bg-none opacity-0"></span>
      </span>
    )}
  </>
)

export const RiskReviewHeader: React.FC = () => {
  const navigate = useNavigate()
  const currentDocument = useSelector(selectCurrentDocument)
  const currentProject = useSelector(selectCurrentProject)
  const { data: currentUser } = useGetUserProfileQuery(undefined)
  const canAccessCustomRiskLists = currentUser?.feature_flags?.custom_risk_lists

  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)
  const { currentData: projectRisks } = useGetProjectRiskQuery(
    currentProject?.uuid ? { projectUUID: currentProject.uuid } : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])

  const goToCustomRisks = () => {
    if (currentProject) {
      navigate(
        `/${currentProject.uuid}/riskreview/${currentDocument?.uuid ?? 'error'}/customrisklists`
      )
    }
  }

  const riskListSearchQuery = useSelector(selectRiskListSearchQuery)
  const dispatch = useDispatch()
  const handleExportClick = () => {
    dispatch(setModal({ modal: MODAL_TYPES.EXPORT_RISK_REVIEW }))
  }

  return (
    <div className="flex w-full justify-between">
      <div className="flex w-full items-center justify-start gap-x-2">
        <TextInput
          placeholder="Search risks"
          data-testid="risk-review-search-input"
          value={riskListSearchQuery}
          onChange={(e) => dispatch(setRiskListSearchQuery(e.target.value))}
        />
      </div>
      <div className="flex items-center justify-end mr-2">
        {canAccessCustomRiskLists ? (
          <Button p="4px" data-testid="custom-risk-lists-button" color="gray" variant='subtle' leftSection={<BoltIcon className="h-5 w-5" />} onClick={goToCustomRisks}>Custom Risks</Button>
        ) : null}
        <Button p="4px" color="gray" variant='subtle' leftSection={<DocumentArrowDownIcon className="h-5 w-5" />} onClick={handleExportClick}>Export</Button>
      </div>
    </div >
  )
}
