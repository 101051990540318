import { apiSlice } from '../api-slice'
import {
  CustomQARisk,
  EvaluationProjectRisk,
  RiskCategory,
  RiskPipelineDefinition,
} from '../../shared/interfaces/qa.interface'
import { RiskEvaluation, RiskEvaluationSource } from '../../shared/interfaces/qa/risk-evaluation.interface'

interface GetCustomRiskQAParams {
  projectUuid: string
  riskCategory: string
}

interface GetEvaluationProjectRiskQAParams {
  projectUuid: string
  riskPipelineDefinitionUuid: string
}

const qaApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomRiskQA: builder.query<CustomQARisk[], GetCustomRiskQAParams>({
      query: ({ projectUuid, riskCategory }) =>
        `/customqarisks/?project=${projectUuid}&risk_category=${riskCategory}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'CustomQARisk' as const,
              id,
            })),
            { type: 'CustomQARisk' as const, id: 'LIST' },
          ]
          : [{ type: 'CustomQARisk' as const, id: 'LIST' }],
    }),
    createCustomQARisk: builder.mutation<CustomQARisk, CustomQARisk>({
      query: (customRisk) => ({
        url: `/customqarisks/create_or_update/`,
        method: 'POST',
        body: {
          ...customRisk,
        },
      }),
      invalidatesTags: [{ type: 'CustomQARisk' as const, id: 'LIST' }],
    }),
    getRiskCategories: builder.query<RiskCategory[], string>({
      query: (projectUuid) => `/projectriskcategories/?project=${projectUuid}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'RiskCategory' as const,
              id,
            })),
            { type: 'RiskCategory' as const, id: 'LIST' },
          ]
          : [{ type: 'RiskCategory' as const, id: 'LIST' }],
    }),
    getRiskPipelineDefinitions: builder.query<RiskPipelineDefinition[], void>({
      query: () => `/riskpipelinedefinitions/`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'RiskPipelineDefinition' as const,
              id,
            })),
            { type: 'RiskPipelineDefinition' as const, id: 'LIST' },
          ]
          : [{ type: 'RiskPipelineDefinition' as const, id: 'LIST' }],
    }),
    getEvaluationProjectRisk: builder.query<
      EvaluationProjectRisk[],
      GetEvaluationProjectRiskQAParams
    >({
      query: ({ projectUuid, riskPipelineDefinitionUuid }) =>
        `/evaluationprojectrisks/?project=${projectUuid}&risk_pipeline_definition=${riskPipelineDefinitionUuid}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'EvaluationProjectRisk' as const,
              id,
            })),
            { type: 'EvaluationProjectRisk' as const, id: 'LIST' },
          ]
          : [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
    createEvaluationProjectRisk: builder.mutation<
      EvaluationProjectRisk,
      EvaluationProjectRisk
    >({
      query: (evaluationProjectRisk) => ({
        url: `/evaluationprojectrisks/`,
        method: 'POST',
        body: {
          ...evaluationProjectRisk,
        },
      }),
      invalidatesTags: [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
    updateEvaluationProjectRisk: builder.mutation<
      EvaluationProjectRisk,
      EvaluationProjectRisk
    >({
      query: (evaluationProjectRisk) => ({
        url: `/evaluationprojectrisks/${evaluationProjectRisk.id}/`,
        method: 'PUT',
        body: {
          ...evaluationProjectRisk,
        },
      }),
      invalidatesTags: [{ type: 'EvaluationProjectRisk' as const, id: 'LIST' }],
    }),
    getRiskEvaluations: builder.query<RiskEvaluation[], string>({
      query: (projectUuid) => `/riskevaluations/?project_uuid=${projectUuid}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'RiskEvaluation' as const,
              id,
            })),
            { type: 'RiskEvaluation' as const, id: 'LIST' },
          ]
          : [{ type: 'RiskEvaluation' as const, id: 'LIST' }],
    }),
    getRiskEvaluation: builder.query<RiskEvaluation, string>({
      query: (riskEvaluationId) => `/riskevaluations/${riskEvaluationId}/`,
      providesTags: (result) =>
        result
          ? [{ type: 'RiskEvaluation' as const, id: result.id }]
          : [{ type: 'RiskEvaluation' as const, id: 'LIST' }],
    }),
    updateRiskEvaluation: builder.mutation<RiskEvaluation, RiskEvaluation>({
      query: (riskEvaluation) => ({
        url: `/riskevaluations/${riskEvaluation.id}/`,
        method: 'PUT',
        body: { ...riskEvaluation },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RiskEvaluation' as const, id: arg.id },
      ],
    }),
    getRiskEvaluationSources: builder.query<RiskEvaluationSource[], string>({
      query: (riskEvaluationId) => `/riskevaluationsources/?risk_evaluation=${riskEvaluationId}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'RiskEvaluationSource' as const,
              id,
            })),
            { type: 'RiskEvaluationSource' as const, id: 'LIST' },
          ]
          : [{ type: 'RiskEvaluationSource' as const, id: 'LIST' }],
    }),
    createRiskEvaluationSource: builder.mutation<RiskEvaluationSource, RiskEvaluationSource>({
      query: (riskEvaluationSource) => ({
        url: `/riskevaluationsources/`,
        method: 'POST',
        body: { ...riskEvaluationSource },
      }),
      invalidatesTags: [{ type: 'RiskEvaluationSource' as const, id: 'LIST' }],
    }),
    deleteRiskEvaluationSource: builder.mutation<void, string>({
      query: (riskEvaluationSourceId) => ({
        url: `/riskevaluationsources/${riskEvaluationSourceId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'RiskEvaluationSource' as const, id: 'LIST' }],
    }),
  }),
})

export const {
  useGetCustomRiskQAQuery,
  useCreateCustomQARiskMutation,
  useGetRiskCategoriesQuery,
  useGetRiskPipelineDefinitionsQuery,
  useGetEvaluationProjectRiskQuery,
  useCreateEvaluationProjectRiskMutation,
  useUpdateEvaluationProjectRiskMutation,
  useGetRiskEvaluationsQuery,
  useUpdateRiskEvaluationMutation,
  useGetRiskEvaluationSourcesQuery,
  useCreateRiskEvaluationSourceMutation,
  useDeleteRiskEvaluationSourceMutation,
  useGetRiskEvaluationQuery,
} = qaApi
