import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetProjectsQuery,
  useGetUserProfileQuery,
  useGetNotificationsQuery,
  useGetDocumentsListByProjectQuery,
} from '../redux/api-slice'
import { selectCurrentProject } from '../redux/application-slice'
import { Project } from '../shared/interfaces/project/project.interface'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { setModal } from '../redux/application-slice'
import { MODAL_TYPES } from '../components/modals/modal-controller'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UseUrlNavigate from './use-url-navigate'
import {
  ArchiveBoxIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowUpTrayIcon,
  ClipboardIcon,
  Cog6ToothIcon,
  DocumentIcon,
  EnvelopeOpenIcon,
  FolderIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import ProjectOptionsMenu from '../components/file-manager/projects/project-options-menu'
import { setCurrentPage } from '../redux/viewer-slice'
import { POSTHOG } from '../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { NotificationRef } from '../components/layout/notification/notification-popover'
import { ProvisionNotification } from '../shared/interfaces/notification/notification.interface'
import { skipToken } from '@reduxjs/toolkit/query'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigateWorkflow } from './use-navigate-workflow'
import { workflows } from '../components/workflows/workflow-popover/workflow.data'
import { useWorkflow } from './use-workflow'

// Types
type CurrProjectSelector = {
  uuid: string
  label: string
}

type ProjectOption = {
  uuid?: string
  label?: string
  leftSectionIcon: React.ReactElement
  rightSectionText: string | React.ReactElement
  onClickMethod: () => void
}

type CreateProjectOption = {
  uuid?: string
  label?: string
  leftSectionIcon: React.ReactElement
  rightSectionText?: string | React.ReactElement
  onClickMethod: () => void
  color?: string
}

type BuiltMenuOptions = (ProjectOption | CreateProjectOption)[]

type DropdownOption = {
  label: string
  leftSectionIcon: ReactElement
  rightSectionText: string
  onClickMethod?: () => void
  linkLocation?: string
  color?: string
}

interface Document {
  uuid?: string
  title?: string
}

type NotificationOption = {
  label?: string
  leftSectionIcon: ReactElement
  rightSectionText: string
  onClickMethod?: () => void
  linkLocation?: string
  color?: string
  uuid?: string
  projectRoute?: boolean | string
  isRead?: boolean
}

// Hooks
export const useMenuOptions = () => {
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const posthog = usePostHog()
  const { logout } = useAuth0()
  const { data: userProfile } = useGetUserProfileQuery(undefined)
  const { navigateUrl } = UseUrlNavigate()
  const [currProject, setCurrProject] = useState<CurrProjectSelector>(
    projectId ? { uuid: projectId, label: '' } : { uuid: '', label: '' }
  )
  const { pathname } = useLocation()
  const { documentId } = useParams<{ documentId: string }>()
  const { data: projects } = useGetProjectsQuery(undefined)
  const currentProject = useSelector(selectCurrentProject)

  const { currentData: allDocuments } = useGetDocumentsListByProjectQuery(
    currentProject?.id
      ? {
        projectId: currentProject?.id,
      }
      : skipToken
  )

  const [selectedDocument, setSelectedDocument] = useState<
    ProjectDocumentMetadata | { uuid?: string; title: string }
  >(
    documentId !== ''
      ? { uuid: documentId, title: '' }
      : { uuid: '', title: '' }
  )
  const { currentData: notifications } = useGetNotificationsQuery(false, {
    pollingInterval: 10000,
  })

  //eslint-disable-next-line
  const notificationsIsReadRef = useRef<NotificationRef>({})
  dayjs.extend(relativeTime)

  // Callbacks
  const onCreateProjectClick = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.CREATE_PROJECT }))
  }, [dispatch])

  const navigate = useNavigate()
  const { navigateWorkflow } = useNavigateWorkflow()
  const { workflowMatch } = useWorkflow()

  const onNavigateDocuments = useCallback(() => {
    navigateWorkflow(workflows[0])
    posthog?.capture(POSTHOG.documents_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
    // Add a small delay to ensure the DOM is updated
    setTimeout(() => {
      const uploadButton = document.getElementById('upload-files-button')
      if (uploadButton) {
        uploadButton.click()
      }
    }, 100)
  }, [navigateWorkflow, currentProject, posthog])

  const onDocumentClick = useCallback(
    (uuid: string) => {
      if (!workflowMatch) {
        return
      }

      navigate(`/${currentProject?.uuid}/${workflowMatch.route}/${uuid}`)
      dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 })
      setSelectedDocument({ uuid, title: '' })
    },
    [currentProject?.uuid, dispatch, navigate, workflowMatch]
  )

  const onUploadClick = onNavigateDocuments

  const onNotificationClick = useCallback(
    (route: string) => {
      if (route !== currProject.uuid) {
        navigateUrl(`/${route}/documents/`)
        dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 })
      } else {
        toast.success(`Already Viewing ${currentProject?.title ?? 'Project'}`)
      }
    },
    [navigateUrl, dispatch, currentProject, currProject.uuid]
  )

  const handleLogout = useCallback(() => {
    posthog?.capture(POSTHOG.user_logged_out)
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [logout, posthog])

  const copyTextToClipboard = useCallback(
    async (textToCopy, successMessage) => {
      await navigator.clipboard.writeText(textToCopy)
      toast.success(successMessage)
    },
    []
  )
  const onSettingsClick = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.PROJECT_SETTINGS }))
  }, [dispatch])

  // Effects
  useEffect(() => {
    const newDocumentObject = selectedDocument
    newDocumentObject.title =
      getLabelForCurrentCollectionFromUrl(
        allDocuments ?? [],
        documentId ?? '',
        'Document'
      ) ?? 'TODO dont commit this without auditing'
    setSelectedDocument(newDocumentObject)
  }, [documentId, allDocuments, pathname, selectedDocument])

  // Helper Functions
  const getLabelForCurrentCollectionFromUrl = (
    collection: any[],
    uuid: string,
    defaultObjectForLabel: string
  ): string | undefined =>
    collection.find((item) => item.uuid === uuid)?.title ??
    `All ${defaultObjectForLabel}s`

  const buildProjectSelectionOptions = useCallback(
    (
      currentProject: Project,
      projects: Project[],
      setCurrProject: React.Dispatch<
        React.SetStateAction<{ uuid: string; label: string }>
      >,
      navigateUrl: (url: string) => void,
      dispatch: ReturnType<typeof useDispatch>,
      onCreateProjectClick: () => void
    ): BuiltMenuOptions => {
      const projectOptions: ProjectOption[] = projects.map((thisProject) => ({
        uuid: thisProject.uuid,
        label: thisProject.title,
        color: currentProject?.uuid === thisProject.uuid ? 'blue' : '',
        leftSectionIcon: (
          <FolderIcon
            className={clsx(
              'h-6 w-6 group-hover:text-black',
              currentProject?.uuid === thisProject?.uuid
                ? 'text-blue-500'
                : 'text-gray-500'
            )}
          />
        ),
        rightSectionText: userProfile?.feature_flags?.project_settings_page ? (
          <button
            onClick={onSettingsClick}
            className="rounded-lg p-2 hover:bg-blue-200"
            aria-label={`Settings for ${currentProject.title}`}
          >
            <Cog6ToothIcon className="h-5 w-5 text-gray-500 hover:text-blue-500" />
          </button>
        ) : (
          <ProjectOptionsMenu
            project={thisProject}
            onDelete={() =>
              dispatch(
                setModal({
                  modal: MODAL_TYPES.DELETE_PROJECT,
                  project: thisProject,
                })
              )
            }
          />
        ),
        onClickMethod: () => {
          setCurrProject({
            uuid: thisProject.uuid ?? '',
            label: thisProject.title ?? '',
          })
          navigateUrl(thisProject.uuid ?? '')
          dispatch(setCurrentPage(1))
        },
      }))

      const createProjectOption: CreateProjectOption = {
        label: 'Create a New Project',
        leftSectionIcon: (
          <PlusIcon className="text-black-500 h-5 w-5 group-hover:text-black" />
        ),
        onClickMethod: onCreateProjectClick,
        color: 'blue',
      }

      return [...projectOptions, createProjectOption]
    },
    //eslint-disable-next-line
    [userProfile?.feature_flags?.project_settings_page, onSettingsClick]
  )

  const buildNotificationsDropdownOptions = (
    notifications: ProvisionNotification[] | undefined,
    onNotificationClick: (uuid: string) => void
  ): NotificationOption[] => {
    const options: NotificationOption[] = (notifications || []).map(
      (notif) => ({
        label: notif.message,
        leftSectionIcon: <>•</>,
        rightSectionText: notif.date_created
          ? dayjs(notif.date_created).fromNow()
          : 'New',
        linkLocation: `/${notif.route}`,
        onClickMethod: () => onNotificationClick(notif.id),
        uuid: notif.id,
        projectRoute: notif.route,
        isRead: notif.is_read,
      })
    )

    options.push({
      label: 'View Resolved Notifications',
      leftSectionIcon: (
        <ArchiveBoxIcon className="h-5 w-5 text-blue-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      linkLocation: '/notifications',
      color: 'blue',
    })

    return options
  }

  // Memoized Values
  const PROJECTS_OPTIONS = useMemo(() => {
    return buildProjectSelectionOptions(
      currentProject ?? ({ uuid: '', label: '' } as Project),
      projects ?? [],
      setCurrProject,
      navigateUrl,
      dispatch,
      onCreateProjectClick
    )
  }, [
    projects,
    setCurrProject,
    navigateUrl,
    dispatch,
    onCreateProjectClick,
    currentProject,
    buildProjectSelectionOptions,
  ])

  const getDocumentStatus = useCallback(
    (document: Document, selectedDocument: Document): string => {
      // If either document is null or undefined, return an empty string
      if (!document || !selectedDocument) return ''

      // Check if both documents have UUIDs and they match
      if (
        document.uuid &&
        selectedDocument.uuid &&
        document.uuid === selectedDocument.uuid
      ) {
        return 'Selected'
      }

      // If neither UUID nor title matches, return an empty string
      return ''
    },
    // eslint-disable-next-line
    [document, selectedDocument.uuid]
  )

  const buildDocumentDropdownOptions = (
    allDocuments: Document[],
    onDocumentClick: (uuid: string) => void,
    onUploadClick: () => void
  ): DropdownOption[] => {
    const options: DropdownOption[] = (allDocuments ?? []).map((document) => ({
      label: document.title ?? 'Untitled Document',
      leftSectionIcon: (
        <DocumentIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: getDocumentStatus(document, selectedDocument),
      onClickMethod: () => onDocumentClick(document?.uuid ?? 'error'),
    }))

    options.push({
      label: 'Upload a New Document',
      leftSectionIcon: (
        <ArrowUpTrayIcon className="h-5 w-5 text-blue-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      onClickMethod: onUploadClick,
      color: 'blue',
    })

    return options
  }

  const DOCUMENT_DROPDOWN_OPTIONS = useMemo(() => {
    return buildDocumentDropdownOptions(
      allDocuments ?? [],
      onDocumentClick,
      onUploadClick
    )
    //eslint-disable-next-line
  }, [
    allDocuments,
    onDocumentClick,
    onUploadClick,
    selectedDocument,
    getDocumentStatus,
    buildDocumentDropdownOptions,
  ])

  const NOTIFICATION_DROPDOWN_OPTIONS = buildNotificationsDropdownOptions(
    notifications,
    onNotificationClick
  )

  const ACCOUNT_DROPDOWN_OPTIONS = [
    {
      label: 'Account Settings',
      leftSectionIcon: (
        <Cog6ToothIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      linkLocation: '/settings',
    },
    {
      label: 'Email Provision Support',
      leftSectionIcon: (
        <EnvelopeOpenIcon className="ml-0.5 h-5 w-5 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      onClickMethod: () => {
        copyTextToClipboard(
          'support@useprovision.com',
          `'support@useprovision.com' has been copied to your clipboard. Please paste this into your email app to reach out for support. We look forward to hearing from you.`
        )
      },
    },
    {
      label: 'Copy Organization ID',
      leftSectionIcon: (
        <ClipboardIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      onClickMethod: () => {
        copyTextToClipboard(
          userProfile?.organisation?.name,
          'Org ID was successfully copied. Please only share this with our support team.'
        )
      },
    },

    {
      label: 'Log Out',
      leftSectionIcon: (
        <ArrowRightEndOnRectangleIcon className="h-5 w-5 text-red-400 group-hover:text-black" />
      ),
      rightSectionText: '',
      onClickMethod: handleLogout,
      color: 'red',
    },
  ]

  return {
    PROJECTS_OPTIONS,
    DOCUMENT_DROPDOWN_OPTIONS,
    NOTIFICATION_DROPDOWN_OPTIONS,
    ACCOUNT_DROPDOWN_OPTIONS,
  }
}
