import {
  RevisionCustomLabel,
  SuggestedRevisionResponse,
} from '../../shared/interfaces/project/document/revision/revision.interface'
import { RevisionHistory } from '../../shared/interfaces/revision/revision-history'
import { buildQueryParams } from '../../utils/build-query-params'
import { apiSlice } from '../api-slice'
import {
  Revision,
  RevisionComment,
  RevisionStats,
} from '../../shared/interfaces/project/document/revision/revision.interface'
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks'

type RevisionStatus =
  | 'IN_REVIEW'
  | 'APPROVED'
  | 'NOT_APPROVED'
  | 'NEEDS_RECON'
  | 'NO_STATUS'

export interface GetRevision {
  projectId?: number
  documentIds?: number[]
  customLabelIds?: number[]
  simple_comments_format?: boolean
  show_diff?: boolean
  timezone?: string
  status?: RevisionStatus[]
  start_date?: string
  end_date?: string
}

const revisionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRevisionHistory: builder.query<RevisionHistory[], string>({
      query: (uuid) => `/revisionhistory/?revision=${uuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'RevisionHistory' as const,
                id,
              })),
              { type: 'RevisionHistory' as const, id: 'LIST' },
            ]
          : [{ type: 'RevisionHistory' as const, id: 'LIST' }],
    }),
    getRevisions: builder.query<Revision[], GetRevision>({
      query: ({
        documentIds,
        projectId,
        status,
        customLabelIds,
        start_date,
        end_date,
      }) => {
        const queryParams = buildQueryParams({
          documents: documentIds,
          project: projectId,
          custom_labels: customLabelIds,
          status,
          start_date: start_date
            ? new Date(start_date).toISOString()
            : undefined,
          end_date: end_date ? new Date(end_date).toISOString() : undefined,
        })
        return `/revisions/?${queryParams.toString()}`
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Revision' as const, id })),
              { type: 'Revision' as const, id: 'LIST' },
            ]
          : [{ type: 'Revision' as const, id: 'LIST' }],
    }),
    createRevision: builder.mutation<Revision, Revision>({
      query: (revision) => ({
        url: '/revisions/',
        method: 'POST',
        body: revision,
      }),
      onQueryStarted: async (revision, { dispatch, queryFulfilled }) => {
        try {
          const { data: createdRevision } = await queryFulfilled
          dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              { documentIds: [revision.document ?? -1], projectId: undefined },
              (draft) => {
                draft.push({ ...createdRevision })
              }
            )
          )

          // If this revision was created from a suggested revision, invalidate the suggestions cache
          if (revision.suggested_revision_id) {
            dispatch(
              revisionsApi.util.invalidateTags([
                {
                  type: 'SuggestedRevision',
                  id: 'LIST',
                },
              ])
            )
          }
        } catch {
          // Empty catch block
        }
      },
      invalidatesTags: [{ type: 'Revision', id: 'LIST' }],
    }),
    createRevisionComment: builder.mutation<RevisionComment, RevisionComment>({
      query: (revisionComment) => ({
        url: '/revisioncomments/',
        method: 'POST',
        body: revisionComment,
      }),
      async onQueryStarted(revisionComment, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        if (revisionComment.documents?.length) {
          dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              {
                documentIds: revisionComment.documents,
              },
              (draft) => {
                const revisionIndex = draft.findIndex(
                  (revision) => revision.id === revisionComment.revision_id
                )
                if (revisionIndex !== -1) {
                  draft[revisionIndex].comments?.push({ ...data })
                }
              }
            )
          )
        }
        if (revisionComment.revisionQueryParams) {
          dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              { ...revisionComment.revisionQueryParams } as GetRevision,
              (draft) => {
                const revisionIndex = draft.findIndex(
                  (revision) => revision.id === revisionComment.revision_id
                )
                if (revisionIndex !== -1) {
                  draft[revisionIndex].comments?.push({ ...data })
                }
              }
            )
          )
        }
      },
      invalidatesTags: [{ type: 'Revision', id: 'LIST' }],
    }),
    updateRevisionComment: builder.mutation<RevisionComment, RevisionComment>({
      query: (revisionComment) => ({
        url: `/revisioncomments/${revisionComment.id}/`,
        method: 'PATCH',
        body: revisionComment,
      }),
      async onQueryStarted(revisionComment, { dispatch, queryFulfilled }) {
        let patchResultDocument: PatchCollection | null = null
        let patchResultProject: PatchCollection | null = null
        if (revisionComment.documents?.length) {
          patchResultDocument = dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              {
                documentIds: revisionComment.documents?.length
                  ? revisionComment.documents
                  : undefined,
              },
              (draft) => {
                const revision = revisionComment.parent
                if (!revision) {
                  return
                }
                const index = draft.findIndex(
                  (revisionItr) => revisionItr.id === revision.id
                )
                if (index === -1) {
                  return
                }
                const commentsIndex = draft[index]?.comments?.findIndex(
                  (c) => c.id === revisionComment.id
                )
                const cleanIndex =
                  commentsIndex === undefined ? -1 : commentsIndex
                if (cleanIndex === -1) {
                  return
                }
                const comments = draft[index]?.comments
                if (!comments) {
                  return
                }
                if (!comments[cleanIndex]) {
                  return
                }
                comments[cleanIndex] = revisionComment
              }
            )
          )
        }

        if (revisionComment.revisionQueryParams) {
          patchResultProject = dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              {
                ...revisionComment.revisionQueryParams,
              },
              (draft) => {
                const revision = revisionComment.parent
                if (!revision) {
                  return
                }
                const index = draft.findIndex(
                  (revisionItr) => revisionItr.id === revision.id
                )
                if (index === -1) {
                  return
                }
                const commentsIndex = draft[index]?.comments?.findIndex(
                  (c) => c.id === revisionComment.id
                )
                const cleanIndex =
                  commentsIndex === undefined ? -1 : commentsIndex
                if (cleanIndex === -1) {
                  return
                }
                const comments = draft[index]?.comments
                if (!comments) {
                  return
                }
                if (!comments[cleanIndex]) {
                  return
                }
                comments[cleanIndex] = revisionComment
              }
            )
          )
        }
        try {
          await queryFulfilled
        } catch {
          if (patchResultDocument) {
            patchResultDocument.undo()
          }
          if (patchResultProject) {
            patchResultProject.undo()
          }
        }
      },
      invalidatesTags: [{ type: 'Revision', id: 'LIST' }],
    }),
    getRevisionStats: builder.query<RevisionStats[], GetRevision>({
      query: ({ projectId, documentIds, status }) =>
        `/revisionstats/?${projectId ? `project=${projectId}` : ''}${
          documentIds?.length
            ? documentIds
                .map((d, i) => `${i === 0 ? '' : '&'}documents=${d}`)
                .join('')
            : ''
        }${status ? `&status=${status}` : ''}`,
    }),
    updateRevision: builder.mutation({
      query: ({ id, content }) => ({
        url: `/revisions/${id}/`,
        method: 'PATCH',
        body: content,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Revision', id: 'LIST' },
      ],
      async onQueryStarted(revision, { dispatch, queryFulfilled }) {
        if (revision.content.documents) {
          const patchResult = dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              {
                documentIds: revision.content.documents?.length
                  ? revision.content.documents
                  : undefined,
              },
              (draft) => {
                const index = draft.findIndex(
                  (revisionItr) => revisionItr.id === revision.id
                )
                if (index !== -1) {
                  draft[index] = { ...draft[index], ...revision.content }
                }
              }
            )
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
        if (revision.content.project) {
          const patchResult = dispatch(
            revisionsApi.util.updateQueryData(
              'getRevisions',
              {
                projectId: revision.content.project,
                documentIds: [],
              },
              (draft) => {
                const index = draft.findIndex(
                  (revisionItr) => revisionItr.id === revision.id
                )
                if (index !== -1) {
                  draft[index] = { ...draft[index], ...revision.content }
                }
              }
            )
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
      },
    }),
    updateRevisionPermissions: builder.mutation({
      query: ({ id, content }) => ({
        url: `/revisions/${id}/permissions/`,
        method: 'PATCH',
        body: content,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Revision', id: 'LIST' },
      ],
    }),
    getRevisionPermissions: builder.query<
      { access_control_visibility: string; users: string[] },
      string
    >({
      query: (uuid) => `/revisions/${uuid}/permissions/`,
    }),
    updateRevisionV2Text: builder.mutation({
      query: ({ id, content }) => ({
        url: `/revisions/${id}/update_revision_v2_text/`,
        method: 'POST',
        body: content,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Revision', id }],
      async onQueryStarted(revision, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            {
              documentIds: revision.content.documents?.length
                ? revision.content.documents
                : undefined,
              projectId: revision.content.project,
            },
            (draft) => {
              const index = draft.findIndex(
                (revisionItr) => revisionItr.id === revision.id
              )
              if (index !== -1) {
                draft[index] = { ...draft[index], ...revision.content }
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateRevisionStatus: builder.mutation({
      query: ({ id, content }) => ({
        url: `/revisions/${id}/update_revision_status/`,
        method: 'POST',
        body: content,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Revision', id }],
      async onQueryStarted(revision, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            {
              documentIds: revision.content.documents?.length
                ? revision.content.documents
                : undefined,
              projectId: revision.content.project,
              status: revision.content.status_filter,
            },
            (draft) => {
              const index = draft.findIndex(
                (revisionItr) => revisionItr.id === revision.id
              )
              if (index !== -1) {
                if (revision.content.status_filter) {
                  draft.splice(index, 1)
                } else {
                  draft[index] = { ...draft[index], ...revision.content }
                }
              }
            }
          )
        )
        dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            {
              documentIds: revision.content.documents?.length
                ? revision.content.documents
                : undefined,
              projectId: revision.content.project,
              status: revision.content.revision_status,
            },
            (draft) => {
              const index = draft.findIndex(
                (revisionItr) => revisionItr.id === revision.id
              )
              if (index !== -1) {
                draft[index] = { ...draft[index], ...revision.content }
              }
            }
          )
        )
        const patchStatsResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisionStats',
            {
              documentIds: revision.content.documents?.length
                ? revision.content.documents
                : undefined,
              projectId: revision.content.project,
            },
            (draft) => {
              const revision_stats = draft.find(
                (d) => d.revision_status === revision.content.revision_status
              )
              if (revision_stats) {
                revision_stats.count++
              }
              const previous_status_stats = draft.find(
                (d) => d.revision_status === revision.content.previous_status
              )
              if (previous_status_stats) {
                previous_status_stats.count--
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
          patchStatsResult.undo()
        }
      },
    }),
    deleteRevision: builder.mutation<Revision, Revision>({
      query: ({ id }) => ({
        url: `/revisions/${id}/`,
        method: 'DELETE',
      }),
      // optimistic update
      async onQueryStarted(
        { id, documents, project },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            {
              documentIds: documents,
            },
            (draft) => {
              const index = draft.findIndex((revision) => revision.id === id)
              if (index !== -1) {
                draft.splice(index, 1)
              }
            }
          )
        )
        const deleteProjectResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            {
              projectId: project,
              documentIds: [],
            },
            (draft) => {
              const index = draft.findIndex((revision) => revision.id === id)
              if (index !== -1) {
                draft.splice(index, 1)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          deleteResult.undo()
          deleteProjectResult.undo()
        }
      },
      invalidatesTags: (result, error, revision) => [
        { type: 'Revision', id: revision.id },
        { type: 'SuggestedRevision', id: 'LIST' },
      ],
    }),
    deleteRevisionComment: builder.mutation<
      RevisionComment,
      {
        revisionComment: RevisionComment
        queryParams: GetRevision
        currentDocumentId?: number
      }
    >({
      query: ({ revisionComment }) => ({
        url: `/revisioncomments/${revisionComment.id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { revisionComment, queryParams, currentDocumentId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            queryParams,
            (draft) => {
              const revisionIndex = draft.findIndex(
                (revision) => revision.id === revisionComment.revision_id
              )
              const commentsIndex = draft[revisionIndex]?.comments?.findIndex(
                (rc) => rc?.id === revisionComment?.id
              )
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex
              if (cleanIndex === -1) {
                return
              }
              if (cleanIndex !== -1 && draft[revisionIndex]?.comments) {
                draft[revisionIndex]?.comments?.splice(cleanIndex, 1)
              }
            }
          )
        )
        const patchDocumentResult = currentDocumentId
          ? dispatch(
              revisionsApi.util.updateQueryData(
                'getRevisions',
                { documentIds: [currentDocumentId] },
                (draft) => {
                  const revisionIndex = draft.findIndex(
                    (revision) => revision.id === revisionComment.revision_id
                  )
                  const commentsIndex = draft[
                    revisionIndex
                  ]?.comments?.findIndex((rc) => rc?.id === revisionComment?.id)
                  const cleanIndex =
                    commentsIndex === undefined ? -1 : commentsIndex
                  if (cleanIndex === -1) {
                    return
                  }
                  if (cleanIndex !== -1 && draft[revisionIndex]?.comments) {
                    draft[revisionIndex]?.comments?.splice(cleanIndex, 1)
                  }
                }
              )
            )
          : null
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
          if (patchDocumentResult) {
            patchDocumentResult.undo()
          }
        }
      },
      invalidatesTags: (result, error, { revisionComment, queryParams }) => [
        { type: 'RevisionComment', id: revisionComment.id },
        { type: 'Revision', id: revisionComment.revision_id },
      ],
    }),

    updateRevisionCustomLabels: builder.mutation<
      { id: number; name: string }[],
      {
        revisionId: number
        labels: RevisionCustomLabel[]
        queryParams: GetRevision
        currentDocumentId?: number
      }
    >({
      query: ({ revisionId, labels }) => ({
        url: `/revisions/${revisionId}/labels/`,
        method: 'PUT',
        body: labels,
      }),
      async onQueryStarted(
        { revisionId, labels, queryParams, currentDocumentId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            queryParams,
            (draft) => {
              const revisionIndex = draft.findIndex(
                (revision) => revision.id === revisionId
              )
              if (revisionIndex === -1) return
              draft[revisionIndex].custom_labels = labels
            }
          )
        )
        const patchResultOther = dispatch(
          revisionsApi.util.updateQueryData(
            'getRevisions',
            { documentIds: currentDocumentId ? [currentDocumentId] : [] },
            (draft) => {
              const revisionIndex = draft.findIndex(
                (revision) => revision.id === revisionId
              )
              if (revisionIndex === -1) return
              draft[revisionIndex].custom_labels = labels
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
          patchResultOther.undo()
        }
      },
      // Decided to not invalidate this cache, instead we will leverage the optimistic update above to keep our local cache inline with what the user sees.
      // If the request fails for some reason then the local cache will be reverted to the previous state.
      // invalidatesTags: (result, error, { revisionId }) => [
      //   { type: "Revision" as const, id: revisionId },
      // ],
    }),
    createRevisionExport: builder.mutation<{ export_url: string }, GetRevision>(
      {
        query: ({
          documentIds,
          status,
          projectId,
          simple_comments_format,
          timezone,
          show_diff,
        }) => {
          const queryParams = buildQueryParams({
            documents: documentIds,
            status,
            project: projectId,
            simple_comments_format,
            timezone,
            show_diff,
          })
          return {
            url: `/revisions/export/?${queryParams.toString()}`,
            method: 'POST',
          }
        },
      }
    ),
    getSuggestedRevisions: builder.query<
      SuggestedRevisionResponse,
      string | undefined
    >({
      query: (projectRiskId) =>
        `/suggested_revisions/?project_risk_id=${projectRiskId}`,
      providesTags: (result) =>
        result?.results
          ? [
              ...result.results.map(({ id }) => ({
                type: 'SuggestedRevision' as const,
                id,
              })),
              { type: 'SuggestedRevision' as const, id: 'LIST' },
            ]
          : [{ type: 'SuggestedRevision' as const, id: 'LIST' }],
    }),
  }),
})

export const {
  useGetRevisionHistoryQuery,
  useUpdateRevisionCustomLabelsMutation,
  useUpdateRevisionV2TextMutation,
  useUpdateRevisionStatusMutation,
  useGetRevisionStatsQuery,
  useCreateRevisionCommentMutation,
  useDeleteRevisionCommentMutation,
  useUpdateRevisionCommentMutation,
  useGetRevisionsQuery,
  useGetRevisionPermissionsQuery,
  useUpdateRevisionMutation,
  useUpdateRevisionPermissionsMutation,
  useCreateRevisionExportMutation,
  useDeleteRevisionMutation,
  useCreateRevisionMutation,
  useGetSuggestedRevisionsQuery,
} = revisionsApi
