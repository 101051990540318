import React, { useCallback, useState } from 'react'
import SupplementaryConditionsQAPage from '../../supplementary-conditions-qa/supplementary-conditions-qa-page'
import { QAWorkflowRiskEvaluations } from '../../../features/app-shell/qa-workflow/qa-workflow-risk-evaluations'

interface QualityAssuranceWorkflowProps { }

const QualityAssuranceWorkflow: React.FC<
  QualityAssuranceWorkflowProps
> = () => {
  const [currentTab, setCurrentTab] = useState<string>('')

  const onSCReviewTab = useCallback(() => {
    setCurrentTab('sc-review')
  }, [])

  const onRiskReviewTab = useCallback(() => {
    setCurrentTab('risk-review')
  }, [])

  return (
    <div className={'flex flex-col overflow-hidden'}>
      <div className={'flex border-b'}>
        <div
          className={`cursor-pointer rounded-t border  px-3 py-2 hover:bg-gray-200 ${currentTab === 'sc-review' && 'bg-gray-200'
            }`}
          onClick={onSCReviewTab}
        >
          SC Review
        </div>
        <div
          className={`cursor-pointer rounded-t border px-3 py-2 hover:bg-gray-200 ${currentTab === 'risk-review' && 'bg-gray-200'
            }`}
          onClick={onRiskReviewTab}
        >
          Risk Review
        </div>
      </div>
      <div className={'flex overflow-hidden'}>
        {currentTab === 'sc-review' && <SupplementaryConditionsQAPage />}
        {currentTab === 'risk-review' && <QAWorkflowRiskEvaluations />}
      </div>
    </div>
  )
}

export default QualityAssuranceWorkflow
