import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { TextInput } from '@mantine/core'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useDebouncedValue } from '@mantine/hooks'
import {
  selectDocumentSearchQuery,
  setDocumentSearchQuery,
} from '../../redux/application-slice'

const DocumentSearchInput: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const documentSearch = useSelector(selectDocumentSearchQuery)
  const [debouncedSearch] = useDebouncedValue(documentSearch, 500)
  const dispatch = useDispatch()

  useEffect(() => {
    if (debouncedSearch === undefined) { return }
    if (!debouncedSearch) {
      searchParams.delete('search')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('search', debouncedSearch)
    setSearchParams(searchParams)
  }, [debouncedSearch, searchParams, setSearchParams])

  return (
    <div className="flex items-center">
      <TextInput
        placeholder="Search documents..."
        value={documentSearch ?? ''}
        onChange={(e) => dispatch(setDocumentSearchQuery(e.currentTarget.value || null))}
        leftSection={<MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />}
        styles={{ input: { width: '100%' } }}
      />
    </div>
  )
}

export default DocumentSearchInput
