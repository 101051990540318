import React, { useCallback, useContext, useMemo } from 'react'
import { ChatSessionMessage } from '../../../shared/interfaces/chat/chat-history.interface'
import AIChatSourceMessageButtonV2 from './ai-chat-message-sources-v2'
import AiChatSource from './ai-chat-source'
import AIChatFeedbackButton from './ai-chat-feedback-button'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import AIChatConflict from './ai-chat-conflict'
import { useGetUserProfileQuery } from '../../../redux/api-slice'

import AiChatQuestionPopover from './ai-chat-question-popover'
import { WebsocketContext } from '../../../contexts/websocket-context'

interface AIChatMessageProps {
  chatHistoryMessage: ChatSessionMessage
  index: number
  documents: ProjectDocumentMetadata[]
  sessionUuid: string
  query: string
  writing: boolean
  isLast: boolean
}

const AIChatMessage: React.FC<AIChatMessageProps> = ({
  chatHistoryMessage,
  index,
  documents,
  sessionUuid,
  query,
  writing,
  isLast,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const { reanalysis } = useContext(WebsocketContext)

  const source = useMemo(() => {
    return chatHistoryMessage.origin === 'USER' ? 'user' : 'system'
  }, [chatHistoryMessage])

  const isMessageComplete = useCallback(() => {
    if (!isLast) {
      return true
    }
    return !writing
  }, [writing, isLast])
  return (
    <div
      key={`${chatHistoryMessage.message}${index}`}
      className={`relative flex items-center ${
        source === 'user' ? 'justify-start' : 'justify-end'
      } pb-3`}
    >
      <div className="relative w-10/12 rounded bg-white">
        <div
          className={`whitespace-pre-line p-3 text-sm ${
            source === 'user' ? 'rounded bg-white' : 'rounded-t bg-blue-200'
          }`}
        >
          <AIChatSourceMessageButtonV2
            chatHistoryMessage={chatHistoryMessage}
          />
        </div>
        {reanalysis.state && isLast && source === 'system' && (
          <div className="flex justify-between p-2">
            <div className="animate-pulse text-left text-sm">
              Reanalyzing...
            </div>
          </div>
        )}
        {source !== 'user' &&
          (chatHistoryMessage.message_sources ?? [])?.length > 0 && (
            <AiChatSource
              chatMessage={chatHistoryMessage}
              documents={documents ?? []}
              message={chatHistoryMessage.message}
            />
          )}
        {source !== 'user' && user?.feature_flags?.conflicts ? (
          <AIChatConflict
            query={query}
            chatSessionMessage={chatHistoryMessage}
          />
        ) : null}
        {source !== 'user' && isMessageComplete() && (
          <div className="flex justify-between p-2 text-sm">
            <div>Rate this answer</div>
            <div className="flex w-1/2 space-x-1">
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Good"
                sessionUuid={sessionUuid}
                query={query}
              />
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Bad"
                sessionUuid={sessionUuid}
                query={query}
              />
            </div>
          </div>
        )}
        <AiChatQuestionPopover
          source={source}
          chatHistoryMessage={chatHistoryMessage}
        />
      </div>
    </div>
  )
}

export default AIChatMessage
