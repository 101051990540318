import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typesense, { Client } from 'typesense'
import { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { selectCurrentProject } from '../redux/application-slice'
import {
  selectFilterDocumentUuids,
  selectSearchPage,
  selectSearchQuery,
  selectSearchResults,
  setFilterDocumentUuids,
  setInputText,
  setIsSearchAvailable,
  setSearchPage,
  setSearchQuery,
  setSearchResults,
} from '../redux/search-slice'
import { setSelectedRisk } from '../redux/viewer-slice'
import { Project } from '../shared/interfaces/project/project.interface'
import {
  Hit,
  HitDocument,
  SearchResults,
} from '../shared/interfaces/search-results.interface'

export const TypesenseClientContext = React.createContext({})

interface TypesenseClientProviderProps {
  children: React.ReactNode
}

export function TypsenseClientProvider(props: TypesenseClientProviderProps) {
  const [typesenseClient, setTypesenseClient] = useState<Client | null>(null)
  const [currentSearchQuery, setCurrentSearchQuery] = useState<string>('')

  const currentProject = useSelector(selectCurrentProject) as Project
  const searchQuery = useSelector(selectSearchQuery)
  const searchPage = useSelector(selectSearchPage)
  const filterDocumentUuids = useSelector(selectFilterDocumentUuids)
  const searchResults = useSelector(selectSearchResults)

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentProject && currentProject?.search_key) {
      if (!currentProject?.uuid) {
        return
      }
      const port = parseInt(import.meta.env.VITE_TYPESENSE_PORT ?? '') ?? 443
      const client = new Typesense.Client({
        nodes: [
          {
            host: import.meta.env.VITE_TYPESENSE_HOST ?? '',
            port,
            protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL ?? '',
          },
        ],
        apiKey: currentProject?.search_key,
        connectionTimeoutSeconds: 2,
      })
      setTypesenseClient(client)
      client
        .collections(currentProject?.uuid)
        .retrieve()
        .then(() => {
          dispatch(setIsSearchAvailable(true))
        })
        .catch((resp) => {
          if (resp.httpStatus === 404) {
            dispatch(setIsSearchAvailable(false))
          }
        })
    }
  }, [currentProject, dispatch])

  const onReset = useCallback(() => {
    dispatch(setSearchQuery(''))
    dispatch(setInputText(''))
    dispatch(setSelectedRisk(null))
    dispatch(setSearchResults(null))
    dispatch(setFilterDocumentUuids([]))
  }, [dispatch])

  useEffect(() => {
    onReset()
  }, [currentProject?.search_key, onReset])

  useEffect(() => {
    if (searchQuery !== currentSearchQuery) {
      dispatch(setSearchPage(1))
    }
  }, [searchQuery, currentSearchQuery, dispatch])

  const concatPage = useCallback(
    (
      resp: SearchResponse<{}>,
      page: number,
      searchResults: SearchResults | null
    ) => {
      const hits: Hit[] = resp?.hits as unknown as Hit[]
      if (page !== 1 && resp?.hits && searchResults?.hits) {
        resp.hits = searchResults?.hits?.concat(hits) as any
      }
      const sortedHits = {
        ...resp,
        hits: resp.hits?.sort((a, b) => {
          const aDocument = a.document as HitDocument
          const bDocument = b.document as HitDocument
          const aQuadsY1 = aDocument.quads?.[0]?.y1 ?? 0
          const bQuadsY2 = bDocument.quads?.[0]?.y1 ?? 0
          let quadsSort = 0
          if (aQuadsY1 < bQuadsY2) {
            quadsSort = -1
          }
          if (aQuadsY1 > bQuadsY2) {
            quadsSort = 1
          }
          const documentSort = aDocument.document_uuid.localeCompare(
            bDocument.document_uuid
          )
          const pageSort = aDocument.page - bDocument.page
          return documentSort || pageSort || quadsSort
        }),
      }
      dispatch(setSearchResults(sortedHits as unknown as SearchResults))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const onCatchTypesense = useCallback(() => {
    //overriding catch
  }, [])

  useEffect(() => {
    if (typesenseClient && searchQuery) {
      const filters: string[] = []
      if (filterDocumentUuids.length > 0) {
        filters.push(`document_uuid:=[${filterDocumentUuids.join(',')}]`)
      }
      setCurrentSearchQuery(searchQuery)
        ; (async (searchResults: SearchResults | null) => {
          if (!currentProject || !currentProject.uuid) {
            return
          }
          try {
            const resp = await typesenseClient
              .collections(currentProject?.uuid)
              .documents()
              .search({
                page: searchPage,
                q: searchQuery,
                query_by: 'text',
                per_page: 250,
                filter_by: `${filters.join(' && ')}`,
                sort_by: 'document_uuid:asc,page:asc',
                facet_by: 'labels',
              })
            concatPage(resp, searchPage, searchResults)
          } catch (e) {
            onCatchTypesense()
          }
        })(searchResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    filterDocumentUuids,
    typesenseClient,
    searchPage,
    currentProject,
    dispatch,
    concatPage,
    onCatchTypesense,
  ])

  return (
    <TypesenseClientContext.Provider
      value={{
        typesenseClient,
      }}
    >
      {props.children}
    </TypesenseClientContext.Provider>
  )
}
