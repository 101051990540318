import React, { useCallback, useMemo } from 'react'
import { Banner } from './common-banner'
import {
  CheckCircleIcon,
  ClockIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid'
import { Tooltip, Button, Text, Skeleton, Loader } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject, setModal } from '../../redux/application-slice'
import { MODAL_TYPES } from '../modals/modal-controller'
import { useGetProjectRiskListTemplateSettingsQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  ArrowRightIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline'
import { useGetProjectRisksProcessingStatusQuery } from '../../redux/api/project-risk-api-slice'

const SupplementaryConditionsText = ({
  plural = true,
}: {
  plural?: boolean
}) => (
  <Tooltip
    label={
      'Supplementary Conditions are modifications to the original contract document which requires additional processing.'
    }
    position="bottom"
    multiline
    w={290}
    offset={44}
    className="!text-[16px]"
  >
    <span className="border-b border-dashed border-amber-700">
      {plural ? 'Supplementary Conditions' : 'Supplementary Condition'}
    </span>
  </Tooltip>
)

const SC_IN_PROCESSING_MESSAGE = (
  <div className="inline">
    <SupplementaryConditionsText /> are being processed. Some information will
    be inaccurate until processing is complete. Please check back tomorrow.
  </div>
)

const UNARCHIVING_MESSAGE =
  'Your documents are being unarchived, this will take a few minutes.'
const ANALYSING_DOCUMENTS_MESSAGE = 'Provision is analysing your documents'
const CALCULATING_MESSAGE = 'Calculating...'
const ESTIMATED_TIME_MESSAGE = 'Estimated time:'
const GO_TO_RISK_REVIEW = 'View Risks'
const RISKS_BEING_ANALYSED = (count: number) =>
  `${count} Risk(s) are currently being analysed`

const SC_RISK_PROCESSING_MESSAGE = (
  <div className="inline">
    <SupplementaryConditionsText /> are being processed, and Risk Review will
    update once complete. Please check back tomorrow.
  </div>
)

const LOADING_MESSAGE = 'Loading...'

const UPLOAD_DOCUMENT = 'Upload a Document'
const COMPLETED_MESSAGE = (
  getHighestRiskCount?: number,
  getRiskRank?: string
) =>
  `Risk Review complete: ${getHighestRiskCount ?? null} ${
    getRiskRank ?? null
  } risk(s)`

interface RiskReviewActionProps {
  navigateRisks: () => void
  getHighestRiskCount?: number
  getRiskRank?: string
}

export const UnarchivingBanner: React.FC = () => (
  <Banner
    icon={InformationCircleIcon}
    colour="blue"
    message={UNARCHIVING_MESSAGE}
  />
)

export const ProcessingBanner: React.FC<{
  timeEstimate: number | null
  fancyTimeFormat: (duration: number) => string
}> = ({ timeEstimate, fancyTimeFormat }) => (
  <Banner
    icon={ClockIcon}
    colour="blue"
    message={ANALYSING_DOCUMENTS_MESSAGE}
    action={
      <p className="text-normal flex font-semibold text-blue-500">
        {timeEstimate !== null ? (
          <div className="block text-left tabular-nums">
            {ESTIMATED_TIME_MESSAGE} {fancyTimeFormat(timeEstimate ?? 0)}
          </div>
        ) : (
          <span>{CALCULATING_MESSAGE}</span>
        )}
      </p>
    }
  />
)

export const CompletedBanner: React.FC<RiskReviewActionProps> = ({
  navigateRisks,
  getHighestRiskCount,
  getRiskRank,
}) => (
  <div className=" my-1 ml-2 mr-4 flex border border-gray-300 bg-green-50 p-2 shadow-sm">
    <div className="flex flex-shrink-0 items-center">
      <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
    </div>
    <div className="ml-2 flex flex-1 items-center md:justify-between">
      <Text size="sm" className="font-semibold">
        {COMPLETED_MESSAGE(getHighestRiskCount, getRiskRank)}
      </Text>
      <div className="ml-auto flex items-center gap-3 pl-3">
        <div className="-mx-1.5 -my-1.5 mr-0">
          <Button
            onClick={navigateRisks}
            variant="subtle"
            color="black"
            rightSection={<span aria-hidden="true">&rarr;</span>}
          >
            {GO_TO_RISK_REVIEW}
          </Button>
        </div>
      </div>
    </div>
  </div>
)

export const RiskAnalysingBanner: React.FC<{ count?: number }> = ({
  count,
}) => (
  <Banner
    icon={ClockIcon}
    colour="blue"
    message={RISKS_BEING_ANALYSED(count ?? 0)}
  />
)

export const RiskAnalysingBecauseSCsBanner: React.FC = () => (
  <Banner
    icon={ClockIcon}
    colour="amber"
    message={SC_RISK_PROCESSING_MESSAGE}
    disableInbuiltSpacing
  />
)

export const SupplementaryConditionsBanner: React.FC = () => (
  <Banner icon={ClockIcon} colour="amber" message={SC_IN_PROCESSING_MESSAGE} />
)

export const DocumentChangeBanner: React.FC<{
  isLoading: boolean
  isInReview: boolean
  hasChanges: boolean
  onUploadClick: () => void
}> = ({ isLoading, isInReview, hasChanges, onUploadClick }) => {
  const dispatch = useDispatch()
  const openTutorial = () =>
    dispatch(setModal({ modal: MODAL_TYPES.SC_TUTORIAL }))

  const uploadSCMessage = (
    <span className="inline">
      Upload a <SupplementaryConditionsText plural={false} /> in the Documents
      workflow to get started.{' '}
      <span
        className="cursor-pointer text-blue-400 underline"
        onClick={openTutorial}
      >
        Watch this tutorial on how to use Supplementary Conditions.
      </span>
    </span>
  )

  return isLoading ? null : (
    <Banner
      icon={ClockIcon}
      colour="amber"
      message={
        isLoading
          ? LOADING_MESSAGE
          : isInReview
            ? SC_IN_PROCESSING_MESSAGE
            : uploadSCMessage
      }
      action={
        hasChanges || !isInReview ? (
          <button
            onClick={onUploadClick}
            className="whitespace-nowrap font-medium text-amber-700 hover:text-yellow-600"
          >
            {UPLOAD_DOCUMENT}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        ) : null
      }
    />
  )
}

export const RiskListTemplateBannerButton = ({
  onClickRiskListTemplateBanner,
}: {
  onClickRiskListTemplateBanner: () => void
}) => {
  return (
    <Button
      onClick={onClickRiskListTemplateBanner}
      variant="subtle"
      className="flex items-center gap-2 font-medium text-black hover:text-gray-700"
    >
      Select checklists <ArrowRightIcon className="h-4 w-4" />
    </Button>
  )
}

export const RiskListTemplateBanner = () => {
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const {
    data: riskListTemplateSettings,
    isFetching: riskListTemplateSettingsFetching,
  } = useGetProjectRiskListTemplateSettingsQuery(
    currentProject?.id ? currentProject.id : skipToken
  )
  const { currentData: riskPipelineStats } =
    useGetProjectRisksProcessingStatusQuery(currentProject?.uuid ?? skipToken, {
      pollingInterval: 2000,
    })

  const enabledRiskListTemplates = useMemo(() => {
    return riskListTemplateSettings?.filter((setting) => setting.enabled)
  }, [riskListTemplateSettings])

  const onClickRiskListTemplateBanner = useCallback(() => {
    dispatch(setModal({ modal: MODAL_TYPES.EDIT_PROJECT_RISK_LIST_TEMPLATES }))
  }, [dispatch])

  const risksAreAnalysing = useMemo(() => {
    return (riskPipelineStats?.count ?? 0) > 0
  }, [riskPipelineStats?.count])

  const statusBanner = useMemo(() => {
    if (riskListTemplateSettingsFetching || !riskListTemplateSettings) {
      return <Skeleton height={32} />
    }

    if (risksAreAnalysing) {
      return (
        <>
          <div className="flex items-center gap-2">
            <Loader size="xs" />
            <Text size="sm" truncate>
              Risks are being analysed on {enabledRiskListTemplates?.length}{' '}
              checklist(s)
            </Text>
          </div>
          <RiskListTemplateBannerButton
            onClickRiskListTemplateBanner={onClickRiskListTemplateBanner}
          />
        </>
      )
    }

    return (
      <>
        <div className="flex items-center gap-2">
          <ClipboardDocumentCheckIcon className="h-5 w-5 text-gray-400" />
          <Text size="sm">
            {enabledRiskListTemplates?.length} /{' '}
            {riskListTemplateSettings?.length} checklist(s) enabled
          </Text>
        </div>
        <RiskListTemplateBannerButton
          onClickRiskListTemplateBanner={onClickRiskListTemplateBanner}
        />
      </>
    )
  }, [
    enabledRiskListTemplates,
    riskListTemplateSettingsFetching,
    onClickRiskListTemplateBanner,
    riskListTemplateSettings,
    risksAreAnalysing,
  ])

  return (
    <div className="mr-4 flex w-full items-center justify-between gap-2 border-b px-2 text-sm shadow-sm">
      {statusBanner}
    </div>
  )
}
