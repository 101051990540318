import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import ReactDOM from 'react-dom'
import {
  useDeleteHighlightMutation,
  useGetDocumentChangesByProjectQuery,
  useGetHighlightsQuery,
  useGetInTextLinksQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import {
  selectClickCoords,
  selectCurrentPage,
  selectDocumentViewerSidebarOpen,
  selectHighlightSelected,
  selectShowCustomLabels,
  selectDocumentLoaded,
  selectShowHighlights,
  selectShowRevisionHighlights,
  selectTemporaryHighlight,
  selectTextSelected,
  setDocumentViewerSidebarOpen,
  setHighlightSelected,
  setIconMenuOpen,
  setOpenRevision,
  setSelectedCustomLabel,
  setSelectedRevision,
  setSelectedSources,
  TemporaryHighlight,
} from '../../redux/viewer-slice'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { useHotkeys } from 'react-hotkeys-hook'
import { skipToken } from '@reduxjs/toolkit/query'
import { convertQuads, isRatio } from '../../utils/convert-quads'
import { useNavigate, useParams } from 'react-router-dom'
import { LabelSegment } from '../../shared/interfaces/project/document/custom-label/custom-label.interface'
import { useGetCustomLabelsQuery } from '../../redux/api/custom-label-api-slice'
import PageOverlayDefinition from './page-overlay-definition'
import PageOverlayAmendmentHighlight from './page-overlay-amendment-highlight'
import PageOverlayHighlight from './page-overlay-highlight'
import { useCoords } from '../../hooks/use-coords'
import TemporaryHighlightContainer from './temporary-highlight'
import PageOverlayRevision from './page-overlay-revision'
import PageOverlayLabel from './page-overlay-label'
import { DocumentBlacklineSegment } from '../../shared/interfaces/project/document/blackline/document-blackline.interface'
import {
  selectSelectedResult,
  setDefinitionQuery,
} from '../../redux/search-slice'
import { InTextLink } from '../../shared/interfaces/project/document/in-text-link/in-text-link.interface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { Highlight } from '../../shared/interfaces/project/document/highlight/highlight.interface'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import PageOverlaySearchHighlight from './page-overlay-search-highlight'
import { Hit } from '../../shared/interfaces/search-results.interface'
import PageOverlayAddenda from './page-overlay-addenda'
import { useGetRevisionsQuery } from '../../redux/api/api-revisions-slice'
import { DocumentChangeSegment } from '../../shared/interfaces/project/document/changes/document-change.interface'

interface PageOverlayProps {
  page: number
  onSelectBlackline: (
    blacklineSegments: DocumentBlacklineSegment[],
    ref: HTMLButtonElement | null
  ) => void
  setOverlaysClicked: React.Dispatch<
    React.SetStateAction<OverlayRefClicked | null>
  >
  overlaysClicked: OverlayRefClicked | null
}

export interface OverlayRef {
  element: HTMLElement
  type:
  | 'definition'
  | 'revision'
  | 'label'
  | 'blackline'
  | 'amendment'
  | 'highlight'
  action?
  label: string
}

interface OverlayRefElements {
  [key: string]: OverlayRef
}

export interface OverlayAction {
  id: string
  type:
  | 'definition'
  | 'revision'
  | 'label'
  | 'blackline'
  | 'amendment'
  | 'highlight'
  action?
  label: string
}

export interface OverlayRefClicked {
  overlayRefs: OverlayAction[]
}

const PageOverlay: React.FC<PageOverlayProps> = ({
  page,
  onSelectBlackline,
  setOverlaysClicked,
  overlaysClicked,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const clickCoords = useSelector(selectClickCoords)
  const textSelected = useSelector(selectTextSelected)
  const currentDocument = useSelector(selectCurrentDocument)
  const showHighlights = useSelector(selectShowHighlights)
  const documentLoaded = useSelector(selectDocumentLoaded)
  const selectedHighlight = useSelector(selectHighlightSelected)
  const temporaryHighlight = useSelector(selectTemporaryHighlight)
  const showRevisionHighlights = useSelector(selectShowRevisionHighlights)
  const showCustomLabels = useSelector(selectShowCustomLabels)
  const { getCoords } = useCoords()
  const currentPage = useSelector(selectCurrentPage)
  const navigate = useNavigate()
  const posthog = usePostHog()
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const currentProject = useSelector(selectCurrentProject)
  const dispatch = useDispatch()
  const pageSection = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageSection${page}`) as HTMLDivElement

  const pageWidgetContainer = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageWidgetContainer${page}`) as HTMLDivElement
  const overlayRefs = useRef<OverlayRefElements>({})
  const selectedSearchResult = useSelector(selectSelectedResult)

  const { documentId } = useParams()
  const { data: linksData } = useGetInTextLinksQuery(
    currentDocument
      ? {
        documentUuid: currentDocument?.uuid,
        pageStart: page,
      }
      : skipToken
  )
  const { currentData: highlightsData } = useGetHighlightsQuery(
    currentDocument ? currentDocument?.id : skipToken
  )

  const { currentData: amendments } = useGetDocumentChangesByProjectQuery(
    currentProject?.uuid ?? skipToken
  )

  const { data: labels } = useGetCustomLabelsQuery(
    currentProject?.uuid ? { projectUUID: currentProject?.uuid } : skipToken
  )

  const { currentData: revisionsData } = useGetRevisionsQuery(
    currentDocument ? { documentIds: [currentDocument.id] } : skipToken
  )

  const pageInfo = useMemo(() => {
    if (documentViewer && documentViewer?.getDocument()) {
      return documentViewer?.getDocument().getPageInfo(page)
    } else {
      return { width: 0, height: 0 }
    }
  }, [page, documentViewer])

  useEffect(() => {
    return () => {
      dispatch(setSelectedSources(null))
    }
  }, [dispatch])

  const [deleteHighlight] = useDeleteHighlightMutation()

  useHotkeys(
    ['backspace', 'del'],
    () => {
      if (selectedHighlight) {
        deleteHighlight(selectedHighlight)
        dispatch(setHighlightSelected(null))
      }
    },
    [selectedHighlight]
  )

  const onSelectCustomLabel = useCallback(
    (labelSegment?: LabelSegment) => {
      if (!labelSegment || !labelSegment.id) {
        return
      }
      const parentLabel = labels?.find((l) => l.id === labelSegment.label)
      navigate(
        `/${currentProject?.uuid}/clause-filters/${currentDocument?.uuid}?label=${parentLabel?.name}&page=${labelSegment.page}`
      )
      dispatch(setSelectedCustomLabel(labelSegment.id))
      dispatch(setIconMenuOpen(null))
    },
    [currentDocument?.uuid, currentProject?.uuid, dispatch, labels, navigate]
  )

  const onLinkClick = useCallback(
    (link: InTextLink) => {
      dispatch(setDocumentViewerSidebarOpen('definitions'))

      dispatch(setDefinitionQuery(link.source.text))
      posthog?.capture(POSTHOG.definition_clicked, {
        document_uuid: currentDocument?.uuid,
        // defined_term: definitionSource.defined_term,
      })
    },
    [posthog, dispatch, currentDocument]
  )

  const linksToDisplay = useMemo(() => {
    if (!linksData || !currentPage || !currentDocument || !pageSection) {
      return []
    }
    const links = linksData.filter((s) => s.page === currentPage)
    const linkDict: { [key: string]: InTextLink } = {}
    for (const link of links) {
      if (linkDict[link.source.text]) {
        continue
      }
      linkDict[link.source.text] = link
    }
    const linkArr = Object.values(linkDict)
    return linkArr.map((l) => (
      <PageOverlayDefinition
        ref={(el) => {
          if (!el) {
            return
          }
          overlayRefs.current[l.id] = {
            element: el,
            type: 'definition',
            action: () => {
              onLinkClick(l)
            },
            label: `Definition: ${l.source.text}`,
          }
        }}
        page={page}
        key={`definition_${l.id}`}
        link={l}
        shouldElevate={!textSelected && !overlaysClicked}
      />
    ))
  }, [
    linksData,
    currentPage,
    page,
    textSelected,
    overlaysClicked,
    onLinkClick,
    currentDocument,
    pageSection,
  ])

  const onOverlayClick = useCallback(() => {
    if (page !== clickCoords?.page) {
      return
    }
    const virtualListContainer = document.getElementById('virtualListContainer')
    if (textSelected || !clickCoords || !virtualListContainer) {
      return
    }
    const { x, y } = clickCoords
    const overlayElementsClicked: OverlayAction[] = []
    for (const key in overlayRefs.current) {
      const el = overlayRefs.current[key].element
      if (
        el.getBoundingClientRect().left <= x &&
        el.getBoundingClientRect().right >= x &&
        el.getBoundingClientRect().top <= y &&
        el.getBoundingClientRect().bottom >= y
      ) {
        const { type, action, label } = overlayRefs.current[key]
        overlayElementsClicked.push({
          type,
          action,
          id: key,
          label,
        })
      }
    }
    if (overlaysClicked || overlayElementsClicked.length === 0) {
      setOverlaysClicked(null)
    } else if (overlayElementsClicked.length === 1) {
      overlayElementsClicked[0]?.action()
    } else {
      setOverlaysClicked({
        overlayRefs: overlayElementsClicked,
      })
    }
  }, [textSelected, clickCoords, overlaysClicked, setOverlaysClicked, page])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onOverlayClick, [clickCoords])

  const onSelectHighlight = useCallback(
    (highlight: Highlight) => {
      dispatch(setHighlightSelected(highlight))
    },
    [dispatch]
  )

  const onSelectRevision = useCallback(
    (revision: Revision) => {
      dispatch(setSelectedRevision(revision))
      dispatch(setOpenRevision(revision?.id))
    },
    [dispatch]
  )

  const searchResultCoords = useMemo(() => {
    if (!selectedSearchResult) {
      return null
    }
    const searchResult: Hit = selectedSearchResult
    const quads = searchResult?.document?.quads?.[0]
    if (!quads) {
      return null
    }
    if ((searchResult?.document?.bounds?.length ?? 0) > 0) {
      const quadsFromBounds = convertQuads(
        searchResult?.document?.bounds,
        searchResult?.document?.page
      )
      return getCoords(
        quadsFromBounds,
        searchResult?.document?.page,
        'document-viewer',
        isRatio([quadsFromBounds])
      )
    }
    return getCoords(
      quads,
      searchResult?.document?.page,
      'document-viewer',
      isRatio([quads])
    )
  }, [getCoords, selectedSearchResult])
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const isSearchSidebarOpen = documentViewerSidebarOpen === 'search'

  const addendaSegments = useMemo(() => {
    if (!amendments) {
      return []
    }
    const segments = amendments.reduce<DocumentChangeSegment[]>((acc, curr) => {
      for (const segment of curr.destinations?.filter(
        (d) => d.document === currentDocument?.id
      ) ?? []) {
        if (segment.page === page) {
          acc.push(segment)
        }
      }
      return acc
    }, [])
    return segments
  }, [amendments, page, currentDocument?.id])

  const pageWidgetContainerSection = useMemo(() => {
    if (pageWidgetContainer) {
      return ReactDOM.createPortal(
        <React.Fragment key={page}>
          {currentDocument?.uuid !== documentId && (
            <div
              className={
                'relative flex h-full w-full items-center justify-center backdrop-blur'
              }
              style={{ zIndex: 101 }}
            >
              Loading...
            </div>
          )}
          <div className="opacity-50">
            {showRevisionHighlights &&
              revisionsData?.map((revision) => {
                return revision?.segments
                  ?.filter((segment) => segment?.page === page)
                  .map((segment) => {
                    return segment.quads?.map((quads) => {
                      const coords = getCoords(
                        quads,
                        segment.page,
                        'document-viewer',
                        quads.y3 > quads.y2
                      )
                      return (
                        <PageOverlayRevision
                          ref={(el) => {
                            if (!el) {
                              return
                            }
                            overlayRefs.current[
                              `revision_highlight_${revision.id}_${quads.y3}_${quads.x3}_${quads.y2}_${quads.x2}`
                            ] = {
                              element: el,
                              type: 'revision',
                              label: `Comment: ${revision.original_text?.slice(
                                0,
                                35
                              )} (${new Date(revision?.date_created ?? '').toLocaleDateString()})`,
                              action: () => {
                                onSelectRevision(revision)
                              },
                            }
                          }}
                          key={`revision_highlight_${revision.id}_${quads.y3}`}
                          revision={revision}
                          coords={coords}
                          shouldElevate={!textSelected && !overlaysClicked}
                        />
                      )
                    })
                  })
              })}
          </div>
          {showCustomLabels &&
            labels?.map((label) => {
              return label.segments
                ?.filter((s) => s?.page === page)
                ?.filter((s) => s?.document === currentDocument?.id)
                .map((segment) => {
                  return segment?.document_segment?.quads?.map((quads) => {
                    const coords = getCoords(
                      quads,
                      page,
                      'document-viewer',
                      quads.y3 > quads.y2
                    )
                    return (
                      <PageOverlayLabel
                        ref={(el) => {
                          if (!el) {
                            return
                          }
                          overlayRefs.current[
                            `label_highlight_${segment.id}_${quads.x3}_${quads.y3}_${quads.x2}_${quads.y2}`
                          ] = {
                            element: el,
                            type: 'label',
                            action: () => {
                              onSelectCustomLabel(segment)
                            },
                            label: `Label: ${label.name}`,
                          }
                        }}
                        // onSelectCustomLabel={onSelectCustomLabel}
                        key={`label_highlight_${segment.id}_${quads.x3}`}
                        labelSegment={segment}
                        coords={coords}
                        shouldElevate={!textSelected && !overlaysClicked}
                      />
                    )
                  })
                })
            })}
          {/* HIGHLIGHT DATA */}
          <div className="opacity-50">
            {showHighlights &&
              pageInfo &&
              highlightsData &&
              highlightsData.map((highlight) => {
                if (highlight.page === page) {
                  return highlight?.quads?.map((quad) => {
                    const coords = getCoords(
                      quad,
                      page,
                      'document-viewer',
                      isRatio([quad])
                    )
                    return (
                      <PageOverlayHighlight
                        selectedHighlight={selectedHighlight}
                        highlight={highlight}
                        coords={coords}
                        key={`quad_${highlight.id}_${quad.x3}_${quad.y3}`}
                        shouldElevate={!textSelected && !overlaysClicked}
                        ref={(el) => {
                          if (!el) {
                            return
                          }
                          overlayRefs.current[
                            `highlight_${highlight.id}_${quad.x3}_${quad.y3}_${quad.x2}_${quad.y2}`
                          ] = {
                            element: el,
                            type: 'highlight',
                            action: () => {
                              onSelectHighlight(highlight)
                            },
                            label: `Highlight`,
                          }
                        }}
                      />
                    )
                  })
                } else {
                  return null
                }
              })}
          </div>
          {amendments
            ?.filter((a) => a.document === currentDocument?.id)
            ?.map((amendment) => {
              if (amendment.source.page === page) {
                const quads = amendment?.source?.quads?.[0]
                if (!quads) {
                  return null
                }
                const coords = getCoords(
                  quads,
                  page,
                  'document-viewer',
                  isRatio([quads])
                )
                return (
                  <PageOverlayAmendmentHighlight
                    amendment={amendment}
                    coords={coords}
                    key={`amendment_highlight_${amendment.id}`}
                  />
                )
              } else {
                return null
              }
            })}

          {isSearchSidebarOpen &&
            selectedSearchResult &&
            searchResultCoords &&
            selectedSearchResult?.document?.page === page ? (
            <PageOverlaySearchHighlight
              searchResult={selectedSearchResult}
              coords={searchResultCoords}
            />
          ) : null}

          {user?.feature_flags?.addenda
            ? addendaSegments
              ?.filter((a) => a?.page === page)
              .map((segment) => (
                <PageOverlayAddenda
                  key={`addenda_segment_${segment.id}`}
                  addendaSegment={segment}
                  shouldElevate={!textSelected && !overlaysClicked}
                />
              ))
            : null}

          {/* TEMPORARY HIGHLIGHT DATA */}
          {pageInfo &&
            temporaryHighlight &&
            temporaryHighlight
              .slice()
              .filter((h) => h.page === page)
              .map((highlight: TemporaryHighlight) => (
                <TemporaryHighlightContainer
                  key={`highlight_${highlight.id}_${highlight.quads[0].x3}_${highlight.quads[0].y3}_${highlight.quads[0].x2}_${highlight.quads[0].y2}`}
                  highlight={highlight}
                  page={page}
                  documentViewerID="document-viewer"
                />
              ))}
        </React.Fragment>,
        pageWidgetContainer
      )
    } else {
      return null
    }
  }, [
    pageWidgetContainer,
    page,
    currentDocument?.uuid,
    currentDocument?.id,
    documentId,
    showRevisionHighlights,
    revisionsData,
    showCustomLabels,
    labels,
    showHighlights,
    pageInfo,
    highlightsData,
    amendments,
    selectedSearchResult,
    searchResultCoords,
    addendaSegments,
    temporaryHighlight,
    getCoords,
    textSelected,
    overlaysClicked,
    onSelectRevision,
    onSelectCustomLabel,
    selectedHighlight,
    onSelectHighlight,
    isSearchSidebarOpen,
    user?.feature_flags?.addenda,
  ])

  const pageSectionContainer = useMemo(() => {
    if (pageSection) {
      return ReactDOM.createPortal(
        <React.Fragment key={page}>
          {currentDocument?.uuid !== documentId && (
            <div
              className={
                'relative flex h-full w-full items-center justify-center backdrop-blur'
              }
              style={{ zIndex: 101 }}
            >
              Loading...
            </div>
          )}
          {documentLoaded ? linksToDisplay : null}
        </React.Fragment>,
        pageSection
      )
    } else {
      return null
    }
  }, [
    currentDocument?.uuid,
    documentId,
    linksToDisplay,
    pageSection,
    page,
    documentLoaded,
  ])

  return (
    <div>
      {pageSectionContainer}
      {pageWidgetContainerSection}
    </div>
  )
}

export default PageOverlay
