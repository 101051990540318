import React, { useMemo } from 'react'
import { useGetDocumentsListByProjectQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useSelector } from 'react-redux'
import {
  selectCurrentProject,
  selectSupplementaryConditionsAreProcessing,
} from '../../redux/application-slice'
import { Loader, Text } from '@mantine/core'
import {
  ClipboardDocumentCheckIcon,
  DocumentCheckIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'

const ProjectUploadStatus: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: 5000,
    }
  )

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  const documentErrorCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter((document) => document.job_status === 'ERROR')
      .length
  }, [documents])

  const getStatusDescription = useMemo(() => {
    if (documentProcessingCount > 0) {
      return (
        <Text size="sm" className="flex items-center gap-2">
          <div className="flex h-5 w-5 items-center justify-center">
            <Loader size="xs" />
          </div>
          {documentProcessingCount} document
          {documentProcessingCount > 1 ? 's' : ''} processing
        </Text>
      )
    }

    if (supplementaryConditionsAreProcessing) {
      return (
        <Text size="sm" className="flex items-center gap-2">
          <ClipboardDocumentCheckIcon className="h-5 w-5 text-gray-400" />
          Supplementary conditions are being reviewed
        </Text>
      )
    }

    if (documentErrorCount > 0) {
      return (
        <Text size="sm" className="flex items-center gap-2">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          {documentErrorCount} document
          {documentErrorCount > 1 ? 's have an error' : ' has an error'}
        </Text>
      )
    }

    return (
      <Text size="sm" className="flex items-center gap-2">
        <DocumentCheckIcon className="h-5 w-5 text-gray-400" />
        Documents complete
      </Text>
    )
  }, [
    documentProcessingCount,
    supplementaryConditionsAreProcessing,
    documentErrorCount,
  ])

  return <div className="border-b p-2">{getStatusDescription}</div>
}

export default ProjectUploadStatus
