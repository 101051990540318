import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'

interface RiskListState {
  riskListSearchQuery: string
  selectedRiskList: null | any
  selectedDocuments: null | ProjectDocumentMetadata[]
  selectedCategoryFilter: null | string
  selectedRankingFilter: null | string
  selectedDefaultPositionFilter: null | string
  selectedStatusFilter: null | string
  selectedSort: "AZ" | "ZA" | "HIGH_TO_LOW" | "LOW_TO_HIGH"
  selectedConflictsFilter: null | string
}

const initialState: RiskListState = {
  riskListSearchQuery: '',
  selectedRiskList: null,
  selectedDocuments: null,
  selectedCategoryFilter: null,
  selectedRankingFilter: null,
  selectedDefaultPositionFilter: null,
  selectedStatusFilter: null,
  selectedSort: "AZ",
  selectedConflictsFilter: null,
}

const searchSlice = createSlice({
  name: 'riskListState',
  initialState,
  reducers: {
    setRiskListSearchQuery(state, action: PayloadAction<string>) {
      state.riskListSearchQuery = action.payload
    },
    setSelectedRiskList(state, action: PayloadAction<null | any>) {
      state.selectedRiskList = action.payload
    },
    setSelectedDocuments(
      state,
      action: PayloadAction<null | ProjectDocumentMetadata[]>
    ) {
      state.selectedDocuments = action.payload
    },
    setSelectedCategoryFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedCategoryFilter = action.payload
    },
    setSelectedRankingFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedRankingFilter = action.payload
    },
    setSelectedDefaultPositionFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedDefaultPositionFilter = action.payload
    },
    setSelectedStatusFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedStatusFilter = action.payload
    },
    setSelectedSort: (
      state,
      action: PayloadAction<"AZ" | "ZA" | "HIGH_TO_LOW" | "LOW_TO_HIGH">
    ) => {
      state.selectedSort = action.payload
    },
    setSelectedConflictsFilter: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedConflictsFilter = action.payload
    },
  },
})

export const {
  setRiskListSearchQuery,
  setSelectedRiskList,
  setSelectedDocuments,
  setSelectedCategoryFilter,
  setSelectedRankingFilter,
  setSelectedDefaultPositionFilter,
  setSelectedStatusFilter,
  setSelectedSort,
  setSelectedConflictsFilter,
} = searchSlice.actions

export const selectRiskListSearchQuery = (state: {
  riskListState: RiskListState
}) => state.riskListState.riskListSearchQuery
export const selectSelectedDocuments = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedDocuments
export const selectSelectedCategoryForRiskList = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedCategoryFilter
export const selectSelectedRiskList = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedRiskList
export const riskListSearchSliceReducer = searchSlice.reducer
export const selectSelectedRankingForRiskList = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedRankingFilter
export const selectSelectedDefaultPositionForRiskList = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedDefaultPositionFilter
export const selectSelectedStatusFilter = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedStatusFilter
export const selectSelectedSort = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedSort
export const selectSelectedConflictsFilter = (state: {
  riskListState: RiskListState
}) => state.riskListState.selectedConflictsFilter
