import React, { useEffect, useMemo, useState } from 'react'
import {
  ActionIcon,
  Popover,
  Text,
  MultiSelect,
  Tooltip,
  Button,
  Loader,
} from '@mantine/core'
import {
  BuildingOffice2Icon,
  LockClosedIcon,
  LockOpenIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/16/solid'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import {
  useGetProjectPermissionsByIdQuery,
  useGetUserProfileQuery,
} from '../../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import {
  useGetRevisionPermissionsQuery,
  useUpdateRevisionPermissionsMutation,
} from '../../../redux/api/api-revisions-slice'
import { toast } from 'react-toastify'

interface RevisionCardAccessControlProps {
  revision: Revision
}

const RevisionCardAccessControl: React.FC<RevisionCardAccessControlProps> = ({
  revision,
}) => {
  const [opened, setOpened] = useState(false)
  enum Visibility {
    Creator = 'creator',
    Organisation = 'organisation',
    Specific = 'specific',
    Project = 'project',
  }
  const [visibility, setVisibility] = useState<Visibility>(
    (revision.access_control_visibility as Visibility) ?? Visibility.Project
  )
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedUsersChanged, setSelectedUsersChanged] = useState(false)

  const currentProject = useSelector(selectCurrentProject)
  const { data: currentUser } = useGetUserProfileQuery()

  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject ? currentProject?.id ?? skipToken : skipToken
  )

  const { data: revisionPermissions, isLoading: isLoadingRevisionPermissions } =
    useGetRevisionPermissionsQuery(revision.id?.toString() ?? skipToken)

  const [updateRevisionPermissions] = useUpdateRevisionPermissionsMutation()

  useEffect(() => {
    if (revisionPermissions && currentUser?.uuid) {
      setSelectedUsers(
        revisionPermissions.users.filter(
          (id) => id !== currentUser?.uuid?.toString()
        )
      )
    }
  }, [revisionPermissions, currentUser])

  const isLoading = useMemo(() => {
    return isLoadingRevisionPermissions
  }, [isLoadingRevisionPermissions])

  const users = useMemo(() => {
    return (permissions?.users ?? []).filter(
      (user) => user.uuid.toString() !== currentUser?.uuid?.toString()
    )
  }, [permissions, currentUser])

  const handleUserSelect = (value: string[] | null) => {
    if (!value) return
    setSelectedUsers(value)
    setSelectedUsersChanged(true)
  }

  const handleSaveChanges = async () => {
    if (visibility === Visibility.Specific && selectedUsers.length === 0) {
      toast.error('Please select at least one user', {
        hideProgressBar: true,
      })
      return
    }

    try {
      await updateRevisionPermissions({
        id: revision.id,
        content: {
          access_control_visibility: visibility,
          users: selectedUsers,
        },
      }).unwrap()
      toast.success('Revision permissions updated', {
        hideProgressBar: true,
      })
      setSelectedUsersChanged(false)
      setOpened(false)
    } catch (error) {
      toast.error('Failed to update revision permissions', {
        hideProgressBar: true,
      })
      setSelectedUsersChanged(true)
    }
  }

  const isPermissionsChanged = useMemo(() => {
    return (
      revision.access_control_visibility !== visibility || selectedUsersChanged
    )
  }, [revision.access_control_visibility, visibility, selectedUsersChanged])

  const isCreator = useMemo(() => {
    return currentUser?.uuid === revision.user_created?.uuid
  }, [currentUser, revision])

  return revision.access_control_visibility ? (
    <Popover
      position="bottom"
      withArrow
      shadow="md"
      closeOnClickOutside={false}
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Tooltip
          openDelay={1000}
          label={'View permissions for this revision/comment'}
        >
          <ActionIcon
            variant="subtle"
            size="sm"
            color="gray"
            onClick={() => setOpened((o) => !o)}
          >
            {visibility === Visibility.Project && (
              <LockOpenIcon className="h-4 w-4" />
            )}
            {visibility === Visibility.Creator && (
              <LockClosedIcon className="h-4 w-4" />
            )}
            {visibility === Visibility.Organisation && (
              <BuildingOffice2Icon className="h-4 w-4" />
            )}
            {visibility === Visibility.Specific && (
              <UsersIcon className="h-4 w-4" />
            )}
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown className="flex flex-col gap-1">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div
              className={`rounded p-2 ${!isCreator ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${
                visibility === Visibility.Project
                  ? 'bg-blue-50 hover:bg-blue-100'
                  : 'hover:bg-gray-50'
              }`}
              onClick={() => isCreator && setVisibility(Visibility.Project)}
            >
              <div className="flex items-center justify-between">
                <Text size="sm">Project</Text>
                <Tooltip label="Everyone in the project can see this revision">
                  <QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </div>
            </div>
            <div
              className={`rounded p-2 ${!isCreator ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${
                visibility === Visibility.Creator
                  ? 'bg-blue-50 hover:bg-blue-100'
                  : 'hover:bg-gray-50'
              }`}
              onClick={() => isCreator && setVisibility(Visibility.Creator)}
            >
              <div className="flex items-center justify-between">
                <Text size="sm">Private</Text>
                <Tooltip label="Only you can see and edit this revision">
                  <QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </div>
            </div>
            <div
              className={`rounded p-2 ${!isCreator ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${
                visibility === Visibility.Organisation
                  ? 'bg-blue-50 hover:bg-blue-100'
                  : 'hover:bg-gray-50'
              }`}
              onClick={() =>
                isCreator && setVisibility(Visibility.Organisation)
              }
            >
              <div className="flex items-center justify-between">
                <Text size="sm">Organisation</Text>
                <Tooltip label="Everyone in your organisation can see this revision">
                  <QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </div>
            </div>
            <div
              className={`rounded p-2 ${!isCreator || users.length === 0 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} ${
                visibility === Visibility.Specific
                  ? 'bg-blue-50 hover:bg-blue-100'
                  : 'hover:bg-gray-50'
              }`}
              onClick={() =>
                isCreator &&
                users.length > 0 &&
                setVisibility(Visibility.Specific)
              }
            >
              <div>
                <div className="flex items-center justify-between">
                  <Text size="sm">Specific Users</Text>
                  {users.length === 0 ? (
                    <Tooltip label="You are the only user on this project">
                      <QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-gray-400" />
                    </Tooltip>
                  ) : (
                    <Tooltip label="Only specific users you select can see this revision">
                      <QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-gray-400" />
                    </Tooltip>
                  )}
                </div>
                <div className="mt-2">
                  <MultiSelect
                    disabled={
                      !isCreator ||
                      visibility !== Visibility.Specific ||
                      users.length === 0
                    }
                    data={users.map((user) => ({
                      value: user.uuid.toString(),
                      label: user.email,
                    }))}
                    value={selectedUsers}
                    onChange={handleUserSelect}
                    placeholder={
                      users.length === 0
                        ? 'No other users available'
                        : selectedUsers.length > 0
                          ? `${selectedUsers.length} user${selectedUsers.length !== 1 ? 's' : ''} granted access`
                          : undefined
                    }
                    searchable
                    className="w-[300px]"
                    clearable
                    styles={{
                      pill: { display: 'none' },
                    }}
                    nothingFoundMessage="No users"
                  />
                </div>
              </div>
            </div>

            {isCreator ? (
              <div className="mt-2 flex justify-end border-t pt-2">
                <span>
                  <Button
                    role="button"
                    size="xs"
                    variant="filled"
                    onClick={handleSaveChanges}
                    disabled={!isPermissionsChanged}
                  >
                    Save Changes
                  </Button>
                </span>
              </div>
            ) : (
              <div className="mt-2 flex justify-center border-t pt-2">
                <Text size="xs" color="gray">
                  Only comment creators can change the visibility
                </Text>
              </div>
            )}
          </>
        )}
      </Popover.Dropdown>
    </Popover>
  ) : null
}

export default RevisionCardAccessControl
